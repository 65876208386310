import React from "react";
import { useEffect } from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import api from "../../../api/api";
import { useState } from "react";

const DeleteAccount = ({ onCancel = () => {} }) => {
    const [getAccounts,setGetAccounts] = useState([])
    const [accounts,setAccounts] = useState(null)
    const [customerID, setCustomerID] = useState(Number());
    

    useEffect(() => {
        const storedToken = window.localStorage.getItem("userData");
        const parsed = JSON.parse(storedToken);
        function parseClaimsObject(parsed) {
          const result = {};
    
          for (const key in parsed) {
            if (key.endsWith("/country")) {
              result.country = parsed[key];
            } else if (key.endsWith("/emailaddress")) {
              result.emailaddress = parsed[key];
            } else if (key.endsWith("/name")) {
              result.name = parsed[key];
            } else if (key.endsWith("/nameidentifier")) {
              result.nameidentifier = parsed[key];
            }
          }
    
          return result;
        }
        const parsedObject = parseClaimsObject(parsed);
        setCustomerID(parsedObject.country)
        api
          .get(`/api/Account/GetAccounts?customerID=${parsedObject.country}`)
          .then((res) => {
            setGetAccounts(res.data);
          })
          .catch((err) => {
            console.log(err.data);
          });
      }, []);

      const accountsOptions = getAccounts?.map((item)=>({
        label: item.name,
        value: item.accountID
      }))

      const handleClick = (e)=>{
        e.preventDefault()
         api.delete(`/api/Account/DeleteAccount/${accounts.value}?customerID=${customerID}`)
        .then((res)=>{
            Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Müşteri silindi !",
                showConfirmButton: false,
                timer: 1500,
              });
              onCancel();
        })
        .catch((err)=>{
            console.log(err.data);
            Swal.fire("Müşteri silinemedi !");
        })
      }
  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.3)",
        zIndex: "1000",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "60%",
          padding: "30px 20px",
          backgroundColor: "#fff",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "50px",
        }}
      >
        <div style={{ width: "100%" }}>
          <label
            htmlFor="title"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            Silinecek müşteriyi seçiniz...
          </label>
          <Select value={accounts} onChange={(e)=>setAccounts(e)} options={accountsOptions} id="getStockCard" />
        </div>

        <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
          <button onClick={onCancel} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full m-5">
            Vazgeç
          </button>
          <button
          onClick={handleClick}
            type="button"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5"
          >
            Onayla
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
