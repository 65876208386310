import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import "../demands/demands.css";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api/api";
import Swal from "sweetalert2";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegEye } from "react-icons/fa";
import MoonLoader from "react-spinners/MoonLoader";
import { FaCheck } from "react-icons/fa";
import { LuSend } from "react-icons/lu";

const Orders = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);

    api
      .get(`/api/Order/GetOrders?customerID=${parsedObject.country}`)
      .then((res) => {
        setData(res.data);
        res.data.orderDate = formatDate(res.data.orderDate);
        res.data.expiryDate = formatDate(res.data.expiryDate);
      })
      .catch((err) => console.log(err.data))
      .finally(() => setLoading(false));
  }, []);

  const handleDelete = async (orderID, customerID) => {
    try {
      Swal.fire({
        title: "Silmek istediğinize emin misiniz?",
        text: "Bunu geri döndüremezsiniz!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, eminim !",
        cancelButtonText: "Kapat",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(
              `/api/Order/DeleteOrder/${orderID}?customerID=${customerID}`
            )
            .then(() => {
              setData((prevData) =>
                prevData.filter((item) => item.orderID !== orderID)
              );
              Swal.fire({
                title: "Başarıyla silindi!",
                text: "Sipariş silindi.",
                icon: "success",
              });
            })
            .catch((error) => {
              console.error("Error occurred while deleting invoice:", error);
              Swal.fire({
                title: "Silme hatası!",
                text: "Sipariş silinemedi. Lütfen tekrar deneyin.",
                icon: "error",
              });
            });
        }
      });
    } catch (error) {
      console.error("Error occurred while handling delete operation:", error);
    }
  };

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();
    return `${gun}/${ay}/${yil}`;
  };

  const handleOrderConfirmStatus = async (orderID, customerID) => {
    setLoading(true);
    await api
      .put(
        `/api/Order/PutOrderStatus/PutOrderStatus?customerID=${customerID}&orderID=${orderID}&status=1546`
      )
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Sipariş Onaylandı!",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `${error}`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleOrderSendStatus = async (orderID, customerID) => {
    setLoading(true);
    await api
      .put(
        `/api/Order/PutOrderStatus/PutOrderStatus?customerID=${customerID}&orderID=${orderID}&status=1547`
      )
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Sipariş Üretime Gönderildi !",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `${error}`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {token ? (
        <>
          <Header />
          <div className="flex">
            <Sidebars />
            {loading ? (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "100vh",
                  width: "100vw",
                }}
              >
                <MoonLoader
                  color={"blue"}
                  loading={loading}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={0.5}
                />
              </div>
            ) : (
              <div className="w-full m-12">
                <div className="add-button">
                  <Link to={"/orderAdd"}>
                    <button
                      type="button"
                      className="butonCl text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-12 py-2.5 text-center mr-2 mb-2"
                    >
                      Sipariş Oluştur
                      <i className="fa-solid fa-plus ml-2"></i>
                    </button>
                  </Link>
                  <div className="mb-5">
                    <h1 className="text-3xl font-semibold text-blue-700">
                      Siparişler
                    </h1>
                  </div>
                  <div className="flex">
                    <input
                      className="w-96 block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                      type="text"
                      placeholder="Sipariş ara.."
                      onChange={(e) => setSearchTerm(e.target.value)}
                      value={searchTerm}
                    />
                  </div>
                </div>

                <div
                  style={{ height: "85vh" }}
                  className="relative overflow-x-auto shadow-md sm:rounded-lg"
                >
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 sticky top-0">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Ad Soyad
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Başlık
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Adres
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Vergi Dairesi
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Vergi Numarası
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Sipariş Numarası
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Tarih
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Son Tarih
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Toplam Fiyat
                        </th>
                        <th scope="col" className="px-6 py-3">
                          İşlemler
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data
                        ?.filter(
                          (item) =>
                            item.name
                              ?.toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                            item.title
                              ?.toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                            item.address
                              ?.toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                            item.taxOffice
                              ?.toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                            item.taxNumber
                              ?.toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                            item.orderNo
                              ?.toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                            item.orderDate
                              ?.toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                            item.expiryDate
                              ?.toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                            item.totalPrice
                              ?.toString()
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                        )
                        .map((item, index) => (
                          <tr
                            key={index}
                            className="bg-white border-b hover:bg-gray-50"
                          >
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                            >
                              {item.name}
                            </th>
                            <td className="px-6 py-4">{item.title}</td>
                            <td className="px-6 py-4">{item.address}</td>
                            <td className="px-6 py-4">{item.taxOffice}</td>
                            <td className="px-6 py-4">{item.taxNumber}</td>
                            <td className="px-6 py-4">{item.orderNo}</td>
                            <td className="px-6 py-4">
                              {formatDate(item.orderDate)}
                            </td>
                            <td className="px-6 py-4">
                              {formatDate(item.expiryDate)}
                            </td>
                            <td className="px-6 py-4">{item.totalPrice}</td>
                            <td className="flex items-center px-6 py-4 space-x-3">
                              {item.status === 1545 && (
                                <button
                                  onClick={() =>
                                    handleOrderConfirmStatus(
                                      item.orderID,
                                      item.customerID
                                    )
                                  }
                                >
                                  <a
                                    className="font-medium text-green-700 hover:underline"
                                    title="Siparişi Onayla"
                                  >
                                    <FaCheck size={20} />
                                  </a>
                                </button>
                              )}
                              {item.status === 1546 && (
                                <button
                                  onClick={() =>
                                    handleOrderSendStatus(
                                      item.orderID,
                                      item.customerID
                                    )
                                  }
                                >
                                  <a
                                    className="font-medium text-green-700 hover:underline"
                                    title="Üretime Gönder"
                                  >
                                    <LuSend size={20} />
                                  </a>
                                </button>
                              )}
                              <Link to={`/orderDetail/${item.orderID}`}>
                                <a className="font-medium text-green-600 hover:underline">
                                  <FaRegEye size={20} />
                                </a>
                              </Link>
                              <Link to={`/orderEdit/${item.orderID}`}>
                                <a className="font-medium text-blue-600  hover:underline">
                                  <TbEdit size={20} />
                                </a>
                              </Link>
                              <button
                                onClick={() => {
                                  handleDelete(item.orderID, item.customerID);
                                }}
                              >
                                <a className="font-medium text-red-600 hover:underline">
                                  <RiDeleteBin6Line size={20} />
                                </a>
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default Orders;
