import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import api from '../../api/api';

const DashboardChart = () => {
  const [state, setState] = useState({
    options: {
      chart: {
        id: 'basic-bar',
      },
      xaxis: {
        categories: [],
      },
      tooltip: {
        y: {
          formatter: function (val, { seriesIndex, dataPointIndex, w }) {
            // seriesIndex 0 ise "Fatura Sayısı", 1 ise "Fatura Fiyatı"
            const seriesName = w.config.series[seriesIndex].name;
            
            let unit = '';
            if (seriesName === 'Fatura Sayısı') {
              unit = ' adet';
            } else if (seriesName === 'Fatura Fiyatı') {
              unit = '&#8378;';
            }

            return val + unit;
          },
        },
      },
    },
    
    series: [
      {
        name: 'Fatura Sayısı',
        data: [], // invoiceCount değerleri burada olacak
      },
      {
        name: 'Fatura Fiyatı',
        data: [], // invoicePrice değerleri burada olacak
      },
    ],
  });

  useEffect(() => {
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);

    api
      .get(`/api/Report/GetDailySales?customerID=${parsedObject.country}&startDate=2024-1-1&endDate=2024-1-31`)
      .then((res) => {
        const categories = res.data.map((item) => new Date(item.invoiceTime).getDate());
        const invoiceCountData = res.data.map((item) => item.invoiceCount);
        const invoicePriceData = res.data.map((item) => item.invoicePrice);

        // State'i güncelle
        setState((prevState) => ({
          options: {
            ...prevState.options,
            xaxis: {
              categories,
            },
          },
          series: [
            {
              ...prevState.series[0],
              data: invoiceCountData,
            },
            {
              ...prevState.series[1],
              data: invoicePriceData,
            },
          ],
        }));
      })
      .catch((err) => {
        console.log(err.data);
      });
  }, []);

  return (
    <div className="w-full bg-white border border-gray-200 rounded-lg shadow ">

  <Chart options={state.options} series={state.series} type='area' height={350} />
    </div>
  
  )
};

export default DashboardChart;