import React, { useEffect, useState } from "react";
import {
  RiHome4Line,
  RiTeamLine,
  RiCalendar2Line,
  RiFolder2Line,
  RiUserFollowLine,
  RiPlantLine,
  RiStackLine,
  RiUserUnfollowLine
} from "react-icons/ri";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi/";
import logo from "../../assets/images/logo.png"

import {
  Sidebar,
  SubMenu,
  Menu,
  MenuItem
  //useProSidebar
} from "react-pro-sidebar";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getModules } from "../../redux/userDataSlice";

function Sidebars() { 
  const dispatch = useDispatch()
  const state = useSelector(state => state)
  const navigate = useNavigate()

  useEffect(()=>{
    dispatch(getModules())
  },[])

  const [collapsed, setCollapsed] = useState(false);

  const [toggled, setToggled] = useState(false);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };


  return (
    <div>


      <Sidebar
        className={`app ${toggled ? "toggled" : ""}`}
        style={{ height: "100%",  backgroundColor: "white" }}
        backgroundColor="white"
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
        
      >
        <main>
          <Menu>
            {collapsed ? (
              <MenuItem
                icon={<FiChevronsRight />}
                onClick={handleCollapsedChange}
              ></MenuItem>
            ) : (
              <MenuItem
                suffix={<FiChevronsLeft />}
                onClick={handleCollapsedChange}
              >
                {/* <img style={{ width: "50%" }} src={logo} alt="logo" /> */}
                <h1 style={{marginLeft:"15px", fontWeight:"500",color:"gray"}}> <i style={{color:"#0766AD"}} className="fa-solid fa-bars mr-3"></i>Menü</h1>
              </MenuItem>
            )}
            <hr />
          </Menu>

         {!state.isLoading && (
           <Menu>
           <Link style={{color:"#6DB9EF"}} to={"/dashboards"}><MenuItem style={{fontWeight:"500"}} icon={<RiHome4Line color="#6DB9EF" size={20}/>}>Anasayfa</MenuItem></Link>
             {
               state.userModules?.map((item, index) => (
                 <Link to={item.moduleLink} key={index}><MenuItem style={{ color: "gray", fontWeight:"500" }} icon={<i style={{color:"#0766AD"}} className={`${item.icon}`}></i>}>{item.moduleName}</MenuItem></Link>

               ))

             }
          
         </Menu>
         )}
        </main>
      </Sidebar>
    </div>
  );
}
export default Sidebars;
