import React, { useEffect, useState } from "react";
import "../home/home.css";
import DashboardChart from "../../components/views/DashboardChart";
import StackedBar from "../../components/views/StackedBar";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import ColumnChart from "../../components/views/ColumnChart";
import StockAnalysis from "../../components/views/StockAnalysis";
import SimpleDonut from "../../components/views/SimpleDonut";
import AngleCircle from "../../components/views/AngelCircle";
import WelcomeCard from "../../components/views/WelcomeCard";
import AssignmentCard from "../../components/views/AssignmentCard";
import api from "../../api/api";
import { useDispatch, useSelector } from "react-redux";
import { getNotification, getUser } from "../../redux/userDataSlice";
import { MoonLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import PersonalChart from "../../components/views/PersonalChart";
import TaskGraph from "../../components/views/TaskGraph";

const Home = () => {
  const dispatch = useDispatch()
  const data = useSelector(state=> state)

  useEffect(()=>{
    dispatch(getUser())
    dispatch(getNotification())
  },[])

  return (
    <>
    
      <Header />
    <div className="flex">
      <Sidebars/>
    {data.isLoading ? (
          <div style={{ 
            display: "grid",
            placeItems: "center",
            height: "100vh",
            width:"100vw"
          }}>
          <MoonLoader
            color={"blue"}
            loading={data.isLoading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
            speedMultiplier={0.5}
          />
        </div>
    ):(
      <div style={{width:"80%"}} className="grid grid-cols-4 gap-7 items-center mt-10 mx-auto">
      <div className="col-span-2">
        <WelcomeCard data={data} />
      </div>
      <div className="col-span-2">
        <AssignmentCard/>
      </div>
      <div className="col-span-2">
        <TaskGraph/>
      </div>
      <div className="col-span-2">
        <DashboardChart/>
      </div>
      <div className="col-span-2">
        <PersonalChart/>
      </div>
      <div className="col-span-2">
        <ColumnChart/>
      </div>
      
    </div>
    )}
    </div>
     
    </>
  );
};

export default Home;
