import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import "../demands/demands.css";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api/api";
import Swal from "sweetalert2";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegEye } from "react-icons/fa";
import MoonLoader from "react-spinners/MoonLoader";
import { IoWalletOutline } from "react-icons/io5";

const PersonelGroups = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setToken] = useState(null);
  const [customerID, setCustomerID] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);
    setCustomerID(parsedObject.country);

    api
      .get(`/api/Personel/GetPersonGroups?customerID=${parsedObject.country}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.error(err.data))
      .finally(() => setLoading(false));
  }, []);

  const handleDelete = async (personGroupID) => {
    try {
      const token = localStorage.getItem("token");
      Swal.fire({
        title: "Personel grubunu silmek istediğinize emin misiniz?",
        text: "Bunu geri döndüremezsiniz!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, eminim !",
        cancelButtonText: "Kapat",
      }).then((result) => {
        if (result.isConfirmed) {
          api
            .delete(
              `/api/Personel/DeletePersonGroup/${personGroupID}?customerID=${customerID}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then(() => {
              setData((prevData) =>
                prevData.filter((item) => item.personGroupID !== personGroupID)
              );
              Swal.fire({
                title: "Başarıyla silindi!",
                text: "Personel grubu silindi.",
                icon: "success",
              });
            })
            .catch((error) => {
              console.error("Error occurred while deleting project:", error);
              Swal.fire({
                title: "Silme hatası!",
                text: "Personel grubu silinemedi. Lütfen tekrar deneyin.",
                icon: "error",
              });
            });
        }
      });
    } catch (error) {
      console.error("Error occurred while handling delete operation:", error);
    }
  };
  console.log("data",data);

  return (
    <>
      {token ? (
        <>
          <Header />
          <div className="flex">
            <Sidebars />
            {loading ? (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "100vh",
                  width:"100vw"
                }}
              >
                <MoonLoader
                  color={"blue"}
                  loading={loading}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={0.5}
                />
              </div>
            ) : (
              <div className="m-12 w-full">
                <div className="add-button">
               
                  <Link to={"/personelGroupAdd"}>
                    <button
                      type="button"
                      className="butonCl text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-12 py-2.5 text-center mr-2 mb-2"
                    >
                      Personel Grubu Ekle
                      <i className="fa-solid fa-plus ml-2"></i>
                    </button>
                  </Link>
                  <div className="mb-5">
                    <h1 className="text-3xl font-semibold text-blue-700">
                      Personel Grupları
                    </h1>
                  </div>
                  <div className="flex">
                    <input
                      className="w-96 block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                      type="text"
                      placeholder="Personel Grubu ara.."
                      onChange={(e) => setSearchTerm(e.target.value)}
                      value={searchTerm}
                    />
                  </div>
                </div>

                <div
                  style={{ height: "85vh" }}
                  className="relative overflow-x-auto shadow-md sm:rounded-lg"
                >
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 sticky top-0">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Grup Adı
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Başlık
                        </th>
                        <th scope="col" className="px-6 py-3">
                          İşlemler
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data
                        ?.filter(
                          (item) =>
                            item.name
                              ?.toLowerCase()
                              .includes(searchTerm.toLowerCase()) ||
                            item.title
                              ?.toLowerCase()
                              .includes(searchTerm.toLowerCase())
                        )
                        .map((item, index) => (
                          <tr
                            key={index}
                            className="bg-white border-b hover:bg-gray-50"
                          >
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                            >
                              {item?.name}
                            </th>
                            <td className="px-6 py-4">{item?.title}</td>
                            <td className="flex items-center px-6 py-4 space-x-3">
                              <Link to={`/personelGroupDetail/${item.personGroupID}`}>
                                <a className="font-medium text-green-600 hover:underline">
                                  <FaRegEye size={20} />
                                </a>
                              </Link>
                              <Link to={`/personelGroupEdit/${item.personGroupID}`}>
                                {" "}
                                <a className="font-medium text-blue-600  hover:underline">
                                  <TbEdit size={20} />
                                </a>
                              </Link>
                              <button
                                onClick={() => {
                                  handleDelete(item.personGroupID);
                                }}
                              >
                                <a className="font-medium text-red-600 hover:underline">
                                  <RiDeleteBin6Line size={20} />
                                </a>
                              </button>

                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default PersonelGroups;
