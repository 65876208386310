import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import { Link } from "react-router-dom";

const Settings = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);

    api
      .get(`/api/Customer/Get?id=${parsedObject.country}`)
      .then((res) => setData(res.data))
      .catch((err) => console.error(err.data));
  }, []);
  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        <div
          className="mt-12 w-full m-12"
        >
          <div className="mb-5">
            <h1 className="text-3xl font-semibold text-blue-700">Ayarlar</h1>
          </div>
          <div className="grid grid-cols-2 gap-7 items-center">
            <div>
              <label
                htmlFor="title"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Başlığı :
              </label>
              <input
                type="text"
                id="title"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                disabled
                value={data?.title}
              />
            </div>
            <div>
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Adı :
              </label>
              <input
                type="text"
                id="name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                disabled
                value={data?.name}
              />
            </div>
            <div>
              <label
                htmlFor="sector"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Sektör :
              </label>
              <input
                type="text"
                id="sector"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                disabled
                value={data?.sectorName}
              />
            </div>
            <div>
              <label
                htmlFor="telephone"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Tel. NO :
              </label>
              <input
                type="text"
                id="telephone"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                disabled
                value={data?.telephone}
              />
            </div>
            <div>
              <label
                htmlFor="cellphone"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Cep NO :
              </label>
              <input
                type="text"
                id="cellphone"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                disabled
                value={data?.cellphone}
              />
            </div>
            <div>
              <label
                htmlFor="adress"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Adres :
              </label>
              <input
                type="text"
                id="adress"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                disabled
                value={data?.address}
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                E-mail :
              </label>
              <input
                type="text"
                id="email"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                disabled
                value={data?.email}
              />
            </div>
            <div>
              <label
                htmlFor="weburl"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Web :
              </label>
              <input
                type="text"
                id="weburl"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                disabled
                value={data?.webUrl}
              />
            </div>
            <div
              style={{ display: "flex", justifyContent: "start", gap: "20px" }}
              className="col-span-2"
            >
              <Link to={"/organizationSchema"}>
                <button
                  type="button"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full mt-2"
                >
                  Organizasyon Şemasını Gör
                  <i className="fa-regular fa-eye ml-2"></i>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
