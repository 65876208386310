import React, { useState } from 'react'
import { AiOutlineCloseCircle } from "react-icons/ai/";
import AddProduct from './AddProduct';
import PutProduct from './PutProduct';
import DeleteProduct from './DeleteProduct';
import AddAccount from './accounts/AddAccount';
import PutAccount from './accounts/PutAccount';
import DeleteAccount from './accounts/DeleteAccount';


const SideBarRight = ({ onCancel = () => { } }) => {
    const [openAddModal, setOpenAddModal] = useState(false)
    const [openPutModal, setOpenPutModal] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [openAccountAddModal, setOpenAccountAddModal] = useState(false)
    const [openAccountPutModal, setOpenAccountPutModal] = useState(false)
    const [openAccountDeleteModal, setOpenAccountDeleteModal] = useState(false)
    return (
        <>
            <div style={{ position: "absolute", top: "0", right: "0", width: "220px", height: "100%", backgroundColor: "white", zIndex: "1000", display: "flex", flexDirection: "column", gap: "30px", borderLeft: "1px solid gray", alignItems: "end" }}>
                <div>
                    <div style={{ fontSize: "30px", float: "right" }}>
                        <AiOutlineCloseCircle color='black' cursor="pointer" onClick={onCancel} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", marginTop: "70px", gap: "20px", marginRight: "20px" }}>
                        <h1 style={{borderBottom:"1px solid gray", fontSize:"17px", fontWeight:"500", color:"black"}}>Ürünler</h1>
                        <button onClick={() => setOpenAddModal(true)} type="button" className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2">Ürün Ekle</button>
                        <button onClick={() => setOpenPutModal(true)} type="button" className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2">Ürün Güncelle</button>
                        <button onClick={() => setOpenDeleteModal(true)} type="button" className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2">Ürün Sil</button>
                        <h1 style={{borderBottom:"1px solid gray", fontSize:"17px", fontWeight:"500", color:"black"}}>Müşteriler</h1>
                        <button onClick={() => setOpenAccountAddModal(true)} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2">Müşteri Ekle</button>
                        <button onClick={() => setOpenAccountPutModal(true)} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2">Müşteri Güncelle</button>
                        <button onClick={() => setOpenAccountDeleteModal(true)} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2">Müşteri Sil</button>


                    </div>
                </div>

            </div>
            {
                openAddModal === true && (<AddProduct
                    onCancel={() => setOpenAddModal(false)}

                />)
            }
            {
                 openPutModal === true && (<PutProduct
                    onCancel={() => setOpenPutModal(false)}

                />)
            }
            {
                 openDeleteModal === true && (<DeleteProduct
                    onCancel={() => setOpenDeleteModal(false)}

                />)
            }
            {
                 openAccountAddModal === true && (<AddAccount
                    onCancel={() => setOpenAccountAddModal(false)}

                />)
            }
            {
                 openAccountPutModal === true && (<PutAccount
                    onCancel={() => setOpenAccountPutModal(false)}

                />)
            }
            {
                 openAccountDeleteModal === true && (<DeleteAccount
                    onCancel={() => setOpenAccountDeleteModal(false)}

                />)
            }
           
        </>
    )
}

export default SideBarRight