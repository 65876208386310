import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import Select from "react-select";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import MoonLoader from "react-spinners/MoonLoader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import { setHours, setMinutes } from "date-fns";
import Timeline, {
  TimelineMarkers,
  TodayMarker,
} from "react-calendar-timeline";
import moment from "moment";
const groups = [{ id: 1, title: "Group 1" }];

const items = [
  {
    id: 1,
    group: 1,
    title: "Item 1",
    start_time: moment().add(-6, "hour"),
    end_time: moment().add(6, "hour"),
  },
  {
    id: 2,
    group: 2,
    title: "Item 2",
    start_time: moment().add(-5, "hour"),
    end_time: moment().add(7, "hour"),
  },
];
console.log(items);

const WorkOrderAdd = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(Number());
  const [token, setToken] = useState(null);
  const [openModule, setOpenModule] = useState(false);
  const [orders, setOrders] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [accountName, setAccountName] = useState("");
  const [ordersOption, setOrdersOption] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [operations, setOperations] = useState([]);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [notes, setNotes] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [keywordsData, setKeywordsData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [productionStockCard, setProductionStockCard] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);
    setCustomerID(parsedObject.country);

    api
      .get(
        `/api/Order/GetOrdersByStatus?customerID=${parsedObject.country}&statusID=1546`
      )
      .then((res) => {
        setOrders(res.data);
        const order = res.data?.map((item) => ({
          value: item.orderID,
          label: item.name,
          value2: item?.accountID,
        }));
        setOrdersOption(order);
      })
      .catch((err) => console.log(err));

    api
      .get(`/api/Account/GetAccounts?customerID=${parsedObject.country}`)
      .then((res) => setAccounts(res.data))
      .catch((err) => console.log(err.data));

    api
      .get(`/api/Common/GetKeywords?customerID=${parsedObject.country}`)
      .then((res) => {
        const newDataKey = res.data?.map((item) => ({
          value: item,
          label: item,
        }));
        setKeywordsData(newDataKey);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  const getStockCardProduction = async (e) => {
    setLoading(true);
    setSelectedOrder(e);
    setOpenModule(true);

    // accounts state'inizden e.value ile eşleşen account'ı bulun
    const selectedAccount = accounts.find(
      (account) => account.accountID === e.value2
    );

    // Eğer bir eşleşme bulunduysa setAccountName fonksiyonunu çağır
    if (selectedAccount) {
      setAccountName(selectedAccount.name); // account objesindeki name propertysini set ediyoruz
    } else {
      console.error("No matching account found for the provided value");
    }

    await api
      .get(
        `/api/StockCardBOM/GetStockCardBOMProductionStockCard?customerID=${customerID}&orderID=${e.value}`
      )
      .then((res) => setProductionStockCard(res.data))
      .catch((error) => {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `${error}`,
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  console.log(productionStockCard);

  const handleServiceQuantitySelectChange = (e, index) => {
    const list = [...productionStockCard];
    list[index].quantity = e;
    setProductionStockCard(list);
  };

  const handleMachineChange = async (selectedOption, index) => {
    setLoading(true);
    const list = [...productionStockCard];
    list[index].selectedMachine = selectedOption;
    setProductionStockCard(list);

    await api
    .get(
      `/api/WorkOrder/GetShiftPlaningByMachineID?customerID=${customerID}&machineID=${selectedOption.value}&startDate=2024-01-01&endDate=2024-12-31`
    )
    .then((res) =>{
       console.log("newr",res.data)
       const list2 = [...productionStockCard];
       list2[index].shiftPlanning = res.data;
       setProductionStockCard(list2);
      })
    .catch((error) => {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${error}`,
        showConfirmButton: false,
        timer: 1500,
      });
    })
    .finally(() => {
      setLoading(false);
    });
  };

  const handleOperationChange = async (selectedOption, index) => {
    setLoading(true);
    const list = [...productionStockCard];
    list[index].selectedOperation = selectedOption;
    setProductionStockCard(list);

    await api
    .get(
      `/api/WorkOrder/GetShiftPlaningByOperationID?customerID=${customerID}&operationID=${selectedOption.value}&startDate=2024-01-01&endDate=2024-12-31`
    )
    .then((res) => {
      console.log("newopr",res.data)
       const list3 = [...productionStockCard];
       list3[index].shiftPlanning = res.data;
       setProductionStockCard(list3);
    })
    .catch((error) => {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${error}`,
        showConfirmButton: false,
        timer: 1500,
      });
    })
    .finally(() => {
      setLoading(false);
    });
  };
  

  return (
    <>
      {token ? (
        <>
          <Header />
          <div className="flex">
            <Sidebars />
            {loading ? (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "100vh",
                  width: "100vw",
                }}
              >
                <MoonLoader
                  color={"blue"}
                  loading={loading}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={0.5}
                />
              </div>
            ) : (
              <div className="m-12 w-full">
                <div className="mb-5">
                  <h1 className="text-3xl font-semibold text-blue-700">
                    İş Emri Oluştur
                  </h1>
                </div>
                <form className="grid grid-cols-4 gap-7 items-center w-full">
                  <div className="col-span-4">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      İş Emri Adı :
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col-span-2">
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Sipariş Seç :
                    </label>
                    <Select
                      maxMenuHeight={200}
                      options={ordersOption}
                      value={selectedOrder}
                      onChange={(e) => getStockCardProduction(e)}
                    />
                  </div>
                  <div className="col-span-2">
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Müşteri :
                    </label>
                    <input
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      disabled
                      value={accountName}
                    />
                  </div>
                  <div className="col-span-2">
                    <label
                      htmlFor="startDate"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Başlangıç Tarih :
                    </label>
                    <DatePicker
                      id="startDate"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      showTimeSelect
                      locale={tr}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>
                  <div className="col-span-2">
                    <label
                      htmlFor="endDate"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Bitiş Tarih :
                    </label>
                    <DatePicker
                      id="endDate"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      showTimeSelect
                      locale={tr}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>

                  <div className="col-span-2">
                    <label
                      htmlFor="keywords"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Anahtar Kelimeler :
                    </label>
                    <CreatableSelect
                      isMulti
                      maxMenuHeight={200}
                      id="keywords"
                      onChange={(e) => setKeywords(e)}
                      options={keywordsData}
                    />
                  </div>

                  <div className="col-span-3">
                    <label
                      htmlFor="description"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Açıklama :
                    </label>
                    <textarea
                      id="description"
                      type="text"
                      className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      rows={4}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                  <div className="col-span-1">
                    <label
                      htmlFor="note"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Not :
                    </label>
                    <textarea
                      id="note"
                      type="text"
                      className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      rows={4}
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </div>

                  {openModule && (
                    <div
                      style={{ height: "100%" }}
                      className=" overflow-x-auto shadow-md sm:rounded-lg mb-5 w-full overflow-y-auto col-span-4"
                    >
                      <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                          <tr>
                            <th scope="col" className="px-6 py-3">
                              İş Emri Kalemleri
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Makine / Operasyon
                            </th>
                            <th scope="col" className="px-6 py-3">
                              Adet
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {productionStockCard?.map((singleService, index) => (
                            <React.Fragment key={index}>
                              <tr className="bg-white border-b">
                                <td className="px-6 py-4">
                                  <input
                                    name="name"
                                    type="text"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    disabled
                                    value={singleService.name}
                                  />
                                </td>
                                {singleService.isMachineProduct && (
                                  <td className="px-6 py-4">
                                    <Select
                                      options={singleService.machines.map(
                                        (item) => ({
                                          label: item.name,
                                          value: item.machineID,
                                        })
                                      )}
                                      maxMenuHeight={200}
                                      onChange={(e) => handleMachineChange(e, index)}
                                      value={singleService?.selectedMachine}
                                    />
                                  </td>
                                )}
                                {singleService.isOperationProduct && (
                                  <td className="px-6 py-4">
                                    <Select
                                      options={singleService.operations.map(
                                        (item) => ({
                                          label: item.name,
                                          value: item.operationID,
                                        })
                                      )}
                                      maxMenuHeight={200}
                                      onChange={(e) => handleOperationChange(e, index)}
                                      value={singleService?.selectedOperation}
                                    />
                                  </td>
                                )}
                                <td className="px-6 py-4">
                                  <input
                                    name="quantity"
                                    type="number"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    required
                                    value={singleService.quantity}
                                    onChange={(e) => {
                                      handleServiceQuantitySelectChange(
                                        e.target.value,
                                        index
                                      );
                                    }}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="3">
                                  <div style={{ maxWidth: "80vw",marginBottom:"70px",margin:"0 auto 70px auto" }}>
                                  <Timeline
                                    groups={groups}
                                    items={items}
                                    defaultTimeStart={moment().add(-12, "hour")}
                                    defaultTimeEnd={moment().add(12, "hour")}
                                    lineHeight={50}
                                    itemHeightRatio={0.9}
                                  />
                                  </div>
                                </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      gap: "20px",
                    }}
                    className="col-span-4"
                  >
                    <button
                      type="submit"
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5"
                    >
                      Kaydet
                      <i className="fa-regular fa-floppy-disk ml-2"></i>
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default WorkOrderAdd;
