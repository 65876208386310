import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import Select from "react-select";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";

const UserEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [userID, setUserID] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [allJobs, setAllJobs] = useState([]);
  const [data, setData] = useState([]);
  //FORM
  const [customerID, setCustomerID] = useState(Number());
  const [roleID, setRoleID] = useState(Number());
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState(null);
  const [name, setName] = useState("");
  const [surname, setSurName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [google, setGoogle] = useState("");
  const [description, setDescription] = useState("");
  const [imageFile, setImageFile] = useState("");
  const [email, setEmail] = useState("");
  const [token, setToken] = useState(null);
  const [file, setFile] = useState(null);
  const [selectedJob, setSelectedJob] = useState({});

  const [userRole, setUserRole] = useState({
    value: "",
    label: "",
  });

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);
    setCustomerID(parsedObject.country);

    api
      .get(`/api/User/Get?id=${params.id}&customerID=${parsedObject.country}`)
      .then((res) => {
        setData(res.data);
        setCustomerID(res.data.customerID);
        setUserName(res.data.userName);
        setName(res.data.name);
        setSurName(res.data.surname);
        setTelephone(res.data.telephone);
        setFacebook(res.data.facebook);
        setInstagram(res.data.instagram);
        setGoogle(res.data.google);
        setDescription(res.data.description);
        setEmail(res.data.email);
        setUserID(res.data.userID);
        setRoleID(res.data.roleID);
        setUserRole({ label: res.data.roleName, value: res.data.roleID });
      })
      .catch((err) => {
        console.log(err.data);
      });

    api
      .get(`/api/User/GetRoles?customerID=${parsedObject.country}`)
      .then((res) => {
        setRoles(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });

    api
      .get(`/api/User/GetJobs?customerID=${parsedObject.country}`)
      .then((res) => {
        setAllJobs(res.data);
        const dataJob = res.data;
        const newData = dataJob?.map((item) => ({
          value: item.jobID,
          label: item.name,
        }));
        setJobs(newData);
      })
      .catch((err) => console.log(err.data));
  }, []);

  //SELECT OPTIONS
  const rolesOptions = roles?.map((item) => ({
    label: item.name,
    value: item.roleID,
  }));

  const handleChange = (event) => {
    setFile(event.target.files);
  };

  const postData = {
    customerID,
    userID,
    roleID: userRole.value,
    jobID: selectedJob.value,
    userName,
    password,
    name,
    surname,
    telephone,
    facebook,
    instagram,
    google,
    description,
    imageFile,
    email,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Dosya yüklenmişse formData'ya ekle
    if (file && file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        formData.append(`file`, file[i]);
      }
    }

    api
      .put("/api/User/Put/Put", postData)
      .then((res) => {
        // Dosya yüklenmişse dosyaları yükle
        if (file && file.length > 0) {
          api
            .post(
              `/api/FileProcess/UploadFiles/UploadFiles?customerID=${customerID}&type=13&relationID=${userID}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(() => {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Kullanıcı güncellendi !",
                showConfirmButton: false,
                timer: 1500,
              });
              navigate("/users");
            })
            .catch((error) => {
              console.error(error);
              Swal.fire("Kullanıcı güncellenemedi !");
            });
        } else {
          // Dosya yüklenmemişse direkt olarak bildirim göster
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Kullanıcı güncellendi !",
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/users");
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire("Kullanıcı güncellenemedi !");
      });
  };

  useEffect(() => {
    if (allJobs && data) {
      const matchedID = allJobs.find((item) => item.jobID === data.jobID);
      setSelectedJob({
        value: matchedID?.jobID,
        label: matchedID?.name,
      });
    }
  }, [data, allJobs]);

  return (
    <>
      {token ? (
        <>
          <Header />
          <div className="flex">
            <Sidebars />
            <div style={{width:"80%"}} className="mt-12 mx-auto">
              <div className="mb-5">
                <h1 className="text-3xl font-semibold text-blue-700 pb-12">
                  Kullanıcı Güncelle
                </h1>
              </div>
              <form
                onSubmit={handleSubmit}
                className="grid grid-cols-3 gap-7 items-center w-full"
              >
                <div className="col-span-3">
                  <input
                    className="block w-54 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                    type="file"
                    onChange={handleChange}
                    multiple
                  />
                </div>
                <div>
                  <label
                    htmlFor="userName"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Kullanıcı Adı :
                  </label>
                  <input
                    type="text"
                    id="userName"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Adı :
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="surname"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Soyadı :
                  </label>
                  <input
                    type="text"
                    id="surname"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={surname}
                    onChange={(e) => setSurName(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    E-mail :
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="telephone"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Tel NO :
                  </label>
                  <input
                    type="number"
                    id="telephone"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={telephone}
                    onChange={(e) => setTelephone(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Şifre :
                  </label>
                  <input
                    type="password"
                    id="password"
                    placeholder="********"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="facebook"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Facebook :
                  </label>
                  <input
                    type="text"
                    id="facebook"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={facebook}
                    onChange={(e) => setFacebook(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="instagram"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Instagram :
                  </label>
                  <input
                    type="text"
                    id="instagram"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={instagram}
                    onChange={(e) => setInstagram(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="google"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Google :
                  </label>
                  <input
                    type="text"
                    id="google"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={google}
                    onChange={(e) => setGoogle(e.target.value)}
                  />
                </div>

                <div className="col-span-1">
                  <label
                    htmlFor="description"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Açıklama :
                  </label>
                  <input
                    type="text"
                    id="description"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                <div className="col-span-1">
                  <label className="block mb-2 text-sm font-medium text-gray-900">
                    Pozisyon :
                  </label>
                  <Select
                    value={selectedJob}
                    onChange={(e) => setSelectedJob(e)}
                    options={jobs}
                    maxMenuHeight={200}
                  />
                </div>
                <div>
                  <label
                    htmlFor="roles"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Rolü :
                  </label>
                  <Select
                    maxMenuHeight={200}
                    onChange={(e) => setUserRole(e)}
                    value={userRole}
                    id="roles"
                    options={rolesOptions}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "20px",
                  }}
                  className="col-span-3"
                >
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5"
                  >
                    Kaydet
                    <i className="fa-regular fa-floppy-disk ml-2"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default UserEdit;
