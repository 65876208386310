import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../api/api";

const FormBuilderSchema = () => {
  const [customerID, setCustomerID] = useState("");
  const [elements, setElements] = useState([]);
  const [viewIndex, setViewIndex] = useState(1);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);

    setCustomerID(parsedObject.country);
  }, []);

  const location = useLocation();
  const formData =
    location.state && location.state.name
      ? location.state
      : { name: "", description: "" };

  const handleClick = (type) => {
    // Tıklanan butona göre yeni bir element ekleyin
    setElements((prevElements) => [
      ...prevElements,
      {
        customerID,
        type,
        viewIndex,
        file: "",
        value: "",
        status: 0,
        description: "",
      },
    ]);
    setViewIndex((prevIndex) => prevIndex + 1);
  };
  const handleInputChange = (viewIndex, newValue) => {
    // Input değerini güncelle
    setElements((prevElements) =>
      prevElements.map((element) =>
        element.viewIndex === viewIndex
          ? { ...element, name: newValue }
          : element
      )
    );
  };
  const handleSelectChange = (viewIndex, selectedOptions) => {
    // Select değerini güncelle
    const value = selectedOptions.map((item) => item.label);
    setElements((prevElements) =>
      prevElements.map((element) =>
        element.viewIndex === viewIndex
          ? { ...element, value: value.join(", ") }
          : element
      )
    );
  };

  const handleClear = () => {
    setElements([]);
  };


  const postData = {
    customerID,
    imageFile: "",
    name: formData.name,
    type: 0,
    status: 0,
    viewBox: 0,
    description: formData.description,
    keywords: "",
    formFields: elements.map((item) => item),
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    api
      .post("/api/FormBuilder/Post/Post", postData)

      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Form oluşturuldu !",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/formList");
      })

      .catch((err) => {
        console.log(err);
        Swal.fire("Form Oluşturalamadı !");
      });
  };

  return (
    <>
      {token ? (
        <>
          <Header />
          <div className="flex">
            <Sidebars />
            <aside
              id="default-sidebar"
              className="fixed  right-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0"
              aria-label="Sidebar"
              style={{ position: "absolute" }}
            >
              <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50">
                <ul className="space-y-2 font-medium">
                  <li>
                    <a
                      onClick={() => handleClick("93")}
                      className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                    >
                      <i className="fa-solid fa-font"></i>

                      <span className="ms-3">Text Box</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => handleClick("94")}
                      className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                    >
                      <i className="fa-solid fa-paragraph"></i>

                      <span className="ms-3">Text Area</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => handleClick("95")}
                      className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                    >
                      <i className="fa-regular fa-circle-dot"></i>

                      <span className="ms-3">Radio</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => handleClick("96")}
                      className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                    >
                      <i className="fa-regular fa-circle-check"></i>

                      <span className="ms-3">Check Box</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => handleClick("97")}
                      className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                    >
                      <i className="fa-solid fa-list-ol"></i>

                      <span className="ms-3">List Box</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => handleClick("98")}
                      className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                    >
                      <i className="fa-regular fa-star"></i>

                      <span className="ms-3">Score Box</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => handleClick("99")}
                      className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                    >
                      <i className="fa-regular fa-calendar-days"></i>

                      <span className="ms-3">Date Picker</span>
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => handleClick("100")}
                      className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                    >
                      <i className="fa-solid fa-upload"></i>

                      <span className="ms-3">File Upload</span>
                    </a>
                  </li>
                </ul>
              </div>
            </aside>

            <form
              className="m-12"
              style={{
                width: "67vw",
                height: "90vh",
                padding: "30px 20px",
                backgroundColor: "rgb(246, 246, 246)",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                overflowY: "scroll",
              }}
              onSubmit={handleSubmit}
            >
              <h1
                style={{
                  textAlign: "center",
                  fontWeight: "500",
                  fontSize: "25px",
                }}
              >
                {formData.name}
              </h1>
              <p className="my-12">
                <span className="font-semibold mr-2">Açıklama:</span>
                {formData.description}
              </p>
              {elements.map((element) => (
                <div key={element.viewIndex}>
                  {(() => {
                    switch (element.type) {
                      case "93":
                        return (
                          <div className="flex">
                            <input
                              type="text"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-36 p-2.5"
                              placeholder="Başlık giriniz..."
                              required
                              value={element.name}
                              onChange={(e) =>
                                handleInputChange(
                                  element.viewIndex,
                                  e.target.value
                                )
                              }
                            />
                            <input
                              type="text"
                              className="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              placeholder="Açıklama..."
                              disabled
                            />
                          </div>
                        );
                      case "94":
                        return (
                          <div className="flex">
                            <input
                              type="text"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-36 p-2.5"
                              placeholder="Başlık giriniz..."
                              required
                              value={element.name}
                              onChange={(e) =>
                                handleInputChange(
                                  element.viewIndex,
                                  e.target.value
                                )
                              }
                            />
                            <textarea
                              type="text"
                              className="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              placeholder="Açıklama..."
                              disabled
                            ></textarea>
                          </div>
                        );
                      case "95":
                        return (
                          <div>
                            <ul className="items-center w-full text-sm font-medium text-gray-900 sm:flex">
                              <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r">
                                <div className="flex items-center">
                                  <input
                                    id="horizontal-list-radio-license"
                                    type="radio"
                                    value=""
                                    disabled
                                    name="list-radio"
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
                                  />
                                  <label
                                    for="horizontal-list-radio-license"
                                    className="w-full py-3 ms-2 text-sm font-medium text-gray-900"
                                  >
                                    <input
                                      type="text"
                                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-36 p-2.5"
                                      placeholder="Başlık giriniz..."
                                      required
                                      value={element.name}
                                      onChange={(e) =>
                                        handleInputChange(
                                          element.viewIndex,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        );
                      case "96":
                        return (
                          <div>
                            <div className="flex items-center mb-4">
                              <input
                                id="default-checkbox"
                                type="checkbox"
                                value=""
                                disabled
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                              />
                              <label
                                for="default-checkbox"
                                className="ms-2 text-sm font-medium text-gray-900"
                              >
                                <input
                                  type="text"
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-36 p-2.5"
                                  placeholder="Başlık giriniz..."
                                  required
                                  value={element.name}
                                  onChange={(e) =>
                                    handleInputChange(
                                      element.viewIndex,
                                      e.target.value
                                    )
                                  }
                                />
                              </label>
                            </div>
                          </div>
                        );
                      case "99":
                        return (
                          <div className="flex">
                            <input
                              type="text"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-36 p-2.5"
                              placeholder="Başlık giriniz..."
                              required
                              value={element.name}
                              onChange={(e) =>
                                handleInputChange(
                                  element.viewIndex,
                                  e.target.value
                                )
                              }
                            />
                            <div className="relative max-w-sm">
                              <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                <svg
                                  className="w-4 h-4 text-gray-500"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                                </svg>
                              </div>
                              <input
                                datepicker
                                type="date"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                                placeholder="Select date"
                                disabled
                              />
                            </div>
                          </div>
                        );
                      case "100":
                        return (
                          <div className="mt-5 flex items-center gap-2">
                            <input
                              type="text"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-5 p-1"
                              placeholder="Başlık giriniz..."
                              required
                              value={element.name}
                              onChange={(e) =>
                                handleInputChange(
                                  element.viewIndex,
                                  e.target.value
                                )
                              }
                            />
                            <input id="file_input" type="file" disabled />
                          </div>
                        );
                      case "97":
                        return (
                          <div className="mt-5 flex">
                            <input
                              type="text"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-5"
                              placeholder="Başlık giriniz..."
                              required
                              value={element.name}
                              onChange={(e) =>
                                handleInputChange(
                                  element.viewIndex,
                                  e.target.value
                                )
                              }
                            />
                            <CreatableSelect
                              isMulti
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  width: "500px",
                                  minHeight: "30px",
                                }),
                              }}
                              onChange={(selectedOptions) =>
                                handleSelectChange(
                                  element.viewIndex,
                                  selectedOptions
                                )
                              }
                              value={element.selectedOptions}
                            />
                          </div>
                        );
                      case "98":
                        return (
                          <div className="flex">
                            <input
                              type="text"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-36 p-2.5"
                              placeholder="Başlık giriniz..."
                              required
                              value={element.name}
                              onChange={(e) =>
                                handleInputChange(
                                  element.viewIndex,
                                  e.target.value
                                )
                              }
                            />
                            <div className="flex items-center">
                              <svg
                                className="w-8 h-8 ms-3 text-gray-300"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 22 20"
                              >
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                              <svg
                                className="w-8 h-8 ms-3 text-gray-300"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 22 20"
                              >
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                              <svg
                                className="w-8 h-8 ms-3 text-gray-300"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 22 20"
                              >
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                              <svg
                                className="w-8 h-8 ms-3 text-gray-300"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 22 20"
                              >
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                              <svg
                                className="w-8 h-8 ms-3 text-gray-300"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="currentColor"
                                viewBox="0 0 22 20"
                              >
                                <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                              </svg>
                            </div>
                          </div>
                        );
                      // Diğer case'leri ekleyebilirsiniz
                      default:
                        return null;
                    }
                  })()}
                </div>
              ))}

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={handleClear}
                  className="bg-red-500 w-36 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full m-5"
                >
                  Temizle
                  <i className="fa-regular fa-trash-can ml-2"></i>
                </button>
                <button
                  style={{ float: "right" }}
                  type="submit"
                  className="bg-blue-500 w-36 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5"
                >
                  Kaydet
                  <i className="fa-regular fa-floppy-disk ml-2"></i>
                </button>
              </div>
            </form>
          </div>
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default FormBuilderSchema;
