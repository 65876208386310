import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./Timeline.css";
import Timeline, {
  TimelineMarkers,
  TodayMarker,
} from "react-calendar-timeline";
import api from "../../api/api";
import MoonLoader from "react-spinners/MoonLoader";
import Select from "react-select";
import moment from "moment";
import Swal from "sweetalert2";

const ShiftDetails = () => {
  const params = useParams();
  const [machines, setMachines] = useState([]);
  const [operations, setOperations] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [customerID, setCustomerID] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);
    setCustomerID(parsedObject.country);

    api
      .get(`/api/Machine/GetMachines?customerID=${parsedObject.country}`)
      .then((res) => {
        const newFormattedMachineShifts = res.data.map((machine) => ({
          id: `machine_${machine.machineID}`,
          title: (
            <>
              <i className="fa-solid fa-gears text-red-600"></i> {machine.name}
            </>
          ),
          group: `machine_${machine.machineID}`, // Makine grupları için farklı bir ID oluştur
        }));
        setMachines(newFormattedMachineShifts);
      })
      .catch((err) => console.log(err.data));

    api
      .get(`/api/Operation/Getoperations?customerID=${parsedObject.country}`)
      .then((res) => {
        const newFormattedOperationShifts = res.data.map((operation) => ({
          id: `operation_${operation.operationID}`,
          title: (
            <>
              <i className="fa-solid fa-person-digging text-green-600"></i>{" "}
              {operation.name}
            </>
          ),
          group: `operation_${operation.operationID}`, // Operasyon grupları için farklı bir ID oluştur
        }));
        setOperations(newFormattedOperationShifts);
      })
      .catch((err) => console.log(err.data));

    api
      .get(
        `/api/Shift/GetShiftPlaningDetail?customerID=${parsedObject.country}&shiftID=${params.id}`
      )
      .then((res) => {
        const newFormattedShifts = res.data.map((item) => {
          let groupID;

          if (item.machineID) {
            groupID = `machine_${item.machineID}`;
          } else if (item.operationID) {
            groupID = `operation_${item.operationID}`;
          }

          return {
            id: item.shiftDetailID,
            group: groupID,
            title: item.workOrderDetails?.name, // Dize olarak işlenmemiş
            start_time: moment(item.partStartTime).toDate(),
            end_time: moment(item.partEndTime).toDate(),
            workOrderDetails: item.workOrderDetails,
            canMove: false,
            canResize: false,
            canChangeGroup: false,
          };
        });
        setShifts(newFormattedShifts);
      })
      .catch((err) => console.log(err.message))
      .finally(() => setLoading(false));
  }, []);

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();

    // Saate 3 ekleyerek hesaplama yapalım
    let saat = tarihObjesi.getHours();

    // Eğer saat 24'ten büyükse 24'e göre modunu alalım
    saat = saat % 24;

    // Saati string formatına çevirirken padding işlemi yapalım
    const saatString = saat.toString().padStart(2, "0");
    const dakika = tarihObjesi.getMinutes().toString().padStart(2, "0");

    return `${gun}/${ay}/${yil} - ${saatString}.${dakika} `;
  };

  const handleItemClick = (itemId) => {
    const selectedItem = shifts.find((item) => item.id === itemId);
    console.log(selectedItem);
    if (selectedItem) {
      Swal.fire({
        title: `${selectedItem.title}`,
        html: `Baş.Tarihi:<span class="font-bold text-green-600"> ${formatDate(
          selectedItem.workOrderDetails.startDate
        )}</span><br>Bit.Tarih: <span class="font-bold text-green-600">${formatDate(
          selectedItem.workOrderDetails.endDate
        )}</span><br> Planlanan Adet: <span class="font-bold text-green-600">${
          selectedItem.workOrderDetails.plannedProductionQuantity
        }</span><br> Gerçekleşen Adet: <span class="font-bold text-green-600">${
          selectedItem.workOrderDetails.actualProductionQuantity
        }</span>`,
      });
    }
  };

  const firstShiftStartTime = shifts.length > 0 ? shifts[0].start_time : moment().add(-12, "hour").toDate();

  return (
    <>
      {token ? (
        <>
          <Header />
          <div className="flex">
            <Sidebars />
            {loading ? (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "100vh",
                  width: "100vw",
                }}
              >
                <MoonLoader
                  color={"blue"}
                  loading={loading}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={0.5}
                />
              </div>
            ) : (
              <div style={{ width: "82vw" }} className="mt-12 mx-auto">
                <div
                  style={{ maxHeight: "75vh" }}
                  className="w-full overflow-y-scroll"
                >
                  <Timeline
                    groups={[...machines, ...operations]}
                    items={shifts}
                    defaultTimeStart={firstShiftStartTime}
                    defaultTimeEnd={moment().add(12, "hour")}
                    lineHeight={50}
                    itemHeightRatio={0.9}
                    onItemClick={(id) => handleItemClick(id)}
                  >
                    <TimelineMarkers>
                      <TodayMarker />
                    </TimelineMarkers>
                  </Timeline>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default ShiftDetails;
