import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import Tree from "react-d3-tree";
import api from "../../api/api";
import MoonLoader from "react-spinners/MoonLoader";
import "./custom-tree.css";
import JobAdd from "./JobAdd";

const OrganazationSchema = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openMdule, setOpenModule] = useState(false);

  useEffect(() => {
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);

    api
      .get(`/api/User/GetOrganizastionChart?customerID=${parsedObject.country}`)
      .then((res) => {
        const modifiedData = modifyData(res.data);
        setData(modifiedData);
      })
      .catch((err) => console.log(err.data))
      .finally(() => setLoading(false));
  }, []);

  // Veri yapısındaki "person" başlığını "personel" olarak değiştiren fonksiyon
  const modifyData = (data) => {
    const modifiedData = JSON.parse(JSON.stringify(data)); // Derin kopya

    const traverse = (node) => {
      if (node.attributes && node.attributes.person) {
        node.attributes.P = node.attributes.person; // "person"ı "personel" olarak değiştir
        delete node.attributes.person; // "person"ı kaldır
      }
      if (node.children) {
        node.children.forEach((child) => traverse(child));
      }
    };

    traverse(modifiedData);
    return modifiedData;
  };

  const containerStyles = {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const treeContainerStyles = {
    transform: "",
    width: "100vw", // Flipping width and height
    height: "100%", // Flipping width and height
  };



  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <div
            style={{ 
              display: "grid",
              placeItems: "center",
              height: "100vh",
              width:"100vw"
            }}
          >
            <MoonLoader
              color={"blue"}
              loading={loading}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
              speedMultiplier={0.5}
            />
          </div>
        ) : (
          <div style={containerStyles}>
            <div style={treeContainerStyles}>
              <Tree
                data={data}
                orientation="vertical"
                separation={{ siblings: 3, nonSiblings: 3 }}
                translate={{ x: 1300, y: 100 }} // Daha iyi konumlandırma için bu değerleri ayarlayın
                pathFunc="step"
                rootNodeClassName="node__root"
                branchNodeClassName="node__branch"
                leafNodeClassName="node__leaf"
                zoomable={true}
                zoom={0.5}
              />
            </div>
            <button
              onClick={() => {
                setOpenModule(true);
              }}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-2"
              style={{ position: "absolute", top: 100, right: 10 }}
            >
              Ekle
            </button>
          </div>
        )}
        {openMdule === true && (
          <JobAdd
            onCancel={() => setOpenModule(false)}
            onSubmitCancel={() => {
              setOpenModule(false);
              window.location.reload();
            }}
          />
        )}
      </div>
    </>
  );
};

export default OrganazationSchema;
