import React, { useEffect, useState } from "react";
import Select from "react-select";
import api from "../../api/api";
import MoonLoader from "react-spinners/MoonLoader";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const JobAdd = ({ onCancel = () => {}, onSubmitCancel = () => {} }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [customerID, setCustomerID] = useState(null);
  const [userID, setUserID] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedJob, setSelectedJob] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);
    setUserID(parsedObject.nameidentifier);
    setCustomerID(parsedObject.country);

    api
      .get(`/api/User/GetJobs?customerID=${parsedObject.country}`)
      .then((res) => {
        const dataJob = res.data;
        const newData = dataJob?.map((item) => ({
          value: item.jobID,
          label: item.name,
        }));
        setData(newData);
      })
      .catch((err) => console.log(err.data))
      .finally(() => setLoading(false));
  }, []);

  const postData = {
    customerID,
    name,
    parent: selectedJob.value,
    status: 0,
    description,
    leaderUsersID: 0,
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Axios isteği yapılandırma
      await api.post("/api/User/PostJob/PostJob", postData);

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Başarıyla eklendi !",
        showConfirmButton: false,
        timer: 1500,
      });
      onSubmitCancel();
    } catch (error) {
      console.error("Hata alındı: ", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${error}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div style={{ 
          display: "grid",
          placeItems: "center",
          height: "100vh",
          width:"100vw"
        }}>
          <MoonLoader
            color={"blue"}
            loading={loading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
            speedMultiplier={0.5}
          />
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.3)",
            zIndex: "1000",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
        >
          <div
            style={{
              width: "60%",
              padding: "30px 20px",
              backgroundColor: "#fff",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <form
              onSubmit={handleSubmit}
              className="grid grid-cols-4 gap-7 items-center"
            >
              <div className="col-span-4">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Birim Adı :
                </label>
                <input
                  type="text"
                  id="name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="col-span-4">
                <label
                  htmlFor="job"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Bağlı Olduğu Birim :
                </label>
                <Select
                  id="job"
                  options={data}
                  maxMenuHeight={200}
                  value={selectedJob}
                  onChange={(e) => setSelectedJob(e)}
                />
              </div>
              <div className="col-span-4">
                <label
                  htmlFor="description"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Açıklama :
                </label>
                <textarea
                  id="description"
                  type="text"
                  className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div
                className="col-span-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                }}
              >
                <button
                  onClick={onCancel}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full m-5"
                >
                  Vazgeç
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5"
                >
                  Onayla
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default JobAdd;
