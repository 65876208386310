import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import Select from "react-select";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import left from "../../assets/images/left-click.png";
import right from "../../assets/images/right-click.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import { setHours, setMinutes, setSeconds } from "date-fns";
import CreatableSelect from "react-select/creatable";
import MoonLoader from "react-spinners/MoonLoader";

const GeneralShiftAdd = () => {
  const navigate = useNavigate();
  const [customerID, setCustomerID] = useState(Number());
  const [userID, setUserID] = useState(Number());
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);

  //FORM
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());



  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);
    setCustomerID(parsedObject.country);
    setUserID(parsedObject.nameidentifier);

  
  }, []);


  const startDateTime = new Date(startDate);
  startDateTime.setHours(0, 0, 0, 0); // Saati 00:00:00 yapar
  
  const endDateTime = new Date(endDate);
  endDateTime.setHours(23, 59, 59, 99); // Saati 23:59:59.999 yapar

  const postData = {
    customerID,
    startDate: startDateTime,
    endDate: endDateTime,
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
   

      // Axios isteği yapılandırma
      await api.post("/api/Shift/PostShift/PostShift", postData);

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Genel Vardiya oluşturuldu !",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/personelShifts");
    } catch (error) {
      console.error("Hata alındı: ", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${error}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  console.log(postData);
  return (
    <>
      {token ? (
        <>
          <Header />
          <div className="flex">
            <Sidebars />
            {loading ? (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "100vh",
                  width: "100vw",
                }}
              >
                <MoonLoader
                  color={"blue"}
                  loading={loading}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={0.5}
                />
              </div>
            ) : (
              <div
                className="w-full mx-auto px-12"
                style={{
                  maxHeight: "100vh",
                  overflowY: "scroll",
                  width: "100%",
                }}
              >
                <div className="pb-5 pt-12">
                  <h1 className="text-3xl font-semibold text-blue-700">
                    Genel Vardiya Oluştur
                  </h1>
                </div>
                <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-12 items-center w-full mt-5">
                  <div className="col-span-1">
                    <label
                      htmlFor="startDate"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Başlangıç Tarih :
                    </label>
                    <DatePicker
                      id="startDate"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      locale={tr}
                      dateFormat="d MMMM, yyyy "
                      
                    />
                  </div>

                  <div className="col-span-1">
                    <label
                      htmlFor="endDate"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Bitiş Tarih :
                    </label>
                    <DatePicker
                      id="endDate"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      locale={tr}
                      dateFormat="d MMMM, yyyy"
                    />
                  </div>
             
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      gap: "20px",
                    }}
                    className="col-span-3"
                  >
                    <button
                      type="submit"
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                    >
                      Kaydet
                      <i className="fa-regular fa-floppy-disk ml-2"></i>
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default GeneralShiftAdd;
