import React, { useEffect, useState } from "react";
import assignment from "../../assets/images/assignment.png";
import api from "../../api/api";
import { Link } from "react-router-dom";

const AssignmentCard = () => {
  const [data,setData] = useState(null)
  useEffect(() => {
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);

    api.get(`/api/Task/GetTasksByAssignedUser?customerID=${parsedObject.country}&userID=${parsedObject.nameidentifier}`)
    .then((res)=>setData(res.data))
    .catch((err)=>console.log(err.data))

  }, []);

  return (
    <div className="p-6 bg-white border border-gray-200 rounded-lg shadow flex justify-between items-center h-56 mb-16">
      <div>
        <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-500">
          Bekleyen <span className="text-green-600">{data?.filter((item) => item.status !== 73).length} göreviniz</span> var !
        </h5>

       <Link to={"/tasks"}>
       <button
          type="button"
          className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-16 py-2.5 me-2 mb-2 my-12"
        >
          İncele
        </button>
       </Link>
      </div>
      <div>
        <img className="w-56" src={assignment} alt="" />
      </div>
    </div>
  );
};

export default AssignmentCard;
