import React, { useEffect } from "react";
import man from "../../assets/images/business-man.png";
import { Link, useNavigate } from "react-router-dom";


const WelcomeCard = ({ data }) => {
  return (
    <>
      <div className="p-6 bg-white border border-gray-200 rounded-lg shadow flex justify-between items-center h-56 mb-16 ">
        <div>
          <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-700">
            Hoşgeldiniz{" "}
            <span className="text-gray-500 ">{data.userData.name}</span>{" "}
            <span className="text-gray-500 ">{data.userData.surname}</span>!
          </h5>

          <p className="mb-3 text-lg font-normal text-gray-500">
            Okunmamış <span className="text-blue-500">{data.notifications.filter((item) => item.status === 0).length} bildiriminiz</span>{" "}
            var...
          </p>
        <Link to={"/notifications"}>
        <button
            type="button"
            className="focus:outline-none text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-16 py-2.5 me-2 mb-2 my-3"
          >
            İncele
          </button>
        </Link>
        </div>
        <div>
          <img className="w-44" src={man} alt="" />
        </div>
      </div>
    </>
  );
};

export default WelcomeCard;
