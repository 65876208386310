import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import logo from "../../assets/images/logo.png";
import api from "../../api/api";
import MoonLoader from "react-spinners/MoonLoader";

const OrderDetail = () => {
  const params = useParams();
  const [data, setData] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);

    api
      .get(`/api/Order/Get?id=${params.id}&customerID=${parsedObject.country}`)
      .then((res) => {
        setData(res.data);
        res.data.orderDate = formatDate(res.data.orderDate);
        res.data.expiryDate = formatDate(res.data.expiryDate);
      })
      .catch((err) => console.log(err.data))
      .finally(() => setLoading(false));
  }, []);

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();
    return `${gun}/${ay}/${yil}`;
  };

  return (
    <>
      {token ? (
        <>
          <Header />

          <div className="flex">
            <Sidebars />
            {loading ? (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "100vh",
                  width: "100vw",
                }}
              >
                <MoonLoader
                  color={"blue"}
                  loading={loading}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={0.5}
                />
              </div>
            ) : (
              <div className="demandDetailCont mx-auto">
                <div className="demandPage">
                  <div className="page-top">
                    <div className="page-top-logo">
                      <img
                        style={{ width: "200px", marginBottom: "10px" }}
                        src={logo}
                        alt=""
                      />
                      <p>Office 149, 450 South Brand Brooklyn</p>
                      <p>San Diego County, CA 91905, USA</p>
                      <p>+1 (123) 456 7891, +44 (876) 543 2198</p>
                    </div>
                    <div className="page-top-info">
                      <h1>Sipariş {data?.orderNo}</h1>
                      <p>
                        <span>Tarih :</span> {data?.orderDate}
                      </p>
                      <p>
                        <span>Bitiş Tarihi :</span> {data?.expiryDate}
                      </p>
                    </div>
                  </div>
                  <div className="page-mid-info">
                    <h1>Talep :</h1>
                    <p style={{ fontWeight: "600", color: "rgb(63, 63, 63)" }}>
                      {data?.name}
                    </p>
                    <p>{data?.taxOffice}</p>
                    <p>{data?.address}</p>
                    <p>{data?.account?.telephone}</p>
                    <p>{data?.account?.email}</p>
                  </div>

                  <div className="page-mid-items relative overflow-x-auto my-8">
                    <table className="w-full text-sm text-left text-gray-500">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            Ürün Adı
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Miktar
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Birim fiyatı
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Vergi Fiyatı
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Vergi Oranı
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Toplam Fiyat
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data?.orderItems.map((item, index) => (
                          <tr key={index} className="bg-white border-b">
                            <th
                              scope="row"
                              className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                            >
                              {item.name}
                            </th>
                            <td className="px-6 py-4">{item.quantity}</td>
                            <td className="px-6 py-4">{item.unitPrice}</td>
                            <td className="px-6 py-4">{item.taxPrice}</td>
                            <td className="px-6 py-4">{item.taxRate}</td>
                            <td className="px-6 py-4">{item.totalPrice}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="page-bottom">
                    <div style={{ width: "60%" }}>
                      <p>
                        <span className="font-semibold">Açıklama :</span>{" "}
                        {data?.description}
                      </p>
                    </div>
                    <div className="total-detail">
                      <h1>
                        Ara Toplam :{" "}
                        <span className="float-right ml-12">
                          {data?.price} ₺
                        </span>
                      </h1>
                      <h1>
                        İndirim :{" "}
                        <span className="float-right ml-12">
                          {data?.lineDiscounts} ₺
                        </span>
                      </h1>
                      <h1>
                        Vergi :{" "}
                        <span className="float-right ml-12">
                          {data?.taxPrice} ₺
                        </span>
                      </h1>
                      <h1>
                        Toplam :{" "}
                        <span className="float-right ml-12">
                          {data?.totalPrice} ₺
                        </span>
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default OrderDetail;
