import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../api/api";
import CreatableSelect from "react-select/creatable";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import Select from "react-select";

const DepartmentEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);

  const [departmentData, setDepartmentData] = useState([]);
  const [departmentUser, setDepartmentUser] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [users, setUsers] = useState([]);
  const [customerID, setCustomerID] = useState(Number());
  const [editUser, setEditUser] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [name, setName] = useState("");
  const [customerKey, setCustomerKey] = useState(null);
  const [keywords, setKeywords] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);

    api
      .get(`/api/Common/GetKeywords?customerID=${parsedObject.country}`)
      .then((res) => {
        const newData = res.data?.map((item) => ({
          value: item,
          label: item,
        }));
        setCustomerKey(newData);
      })
      .catch((err) => console.log(err.data));

    api
      .get(`/api/User/GetUsers?customerID=${parsedObject.country}`)
      .then((res) => {
        setUsers(res.data);
        const data = res.data;
        const newData = data.map((item) => ({
          value: item.userID,
          label: item.userName,
        }));
        setUserOptions(newData);
      })
      .catch((err) => console.log(err.data));

    api
      .get(
        `/api/Department/Get?id=${params.id}&customerID=${parsedObject.country}`
      )
      .then((res) => {
        setDepartmentData(res.data);
        setTitle(res.data.title);
        setName(res.data.name);
        setEditUser(res.data);
        setCustomerID(res.data.customerID);
        setDescription(res.data.description);
        const data = JSON.parse(res.data.keywords);
        const newData = data.map((item) => ({
          value: item,
          label: item,
          __isNew__: true,
        }));
        setKeywords(newData);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }, []);

  useEffect(() => {
    if (users && departmentData) {
      const matchedUser = users.find(
        (item) => item.userID === departmentData.userID
      );
      setDepartmentUser({
        value: matchedUser?.userID,
        label: matchedUser?.userName,
      });
    }
  }, [users, departmentData]);


  const postData = {
    departmentID: editUser?.departmentID,
    customerID: customerID,
    parentID: editUser?.parentID,
    userID: departmentUser.value,
    name,
    title,
    type: 0,
    status: 0,
    viewIndex: 0,
    description: description,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    api
      .put("/api/Department/Put/Put", postData)
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Departman başarılı bir şekilde değiştirildi ! ",
          showConfirmButton: false,
          timer: 3000,
        });
        navigate("/departments");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire("Departman Değiştirilemedi !");
      });
  };

  return (
    <>
      {token ? (
        <>
          <Header />
          <div className="flex">
            <Sidebars />
            <div style={{width:"80%"}} className="mx-auto pt-12">
              <div className="mb-5">
                <h1 className="text-3xl font-semibold text-blue-700 pb-12">
                  Departman Güncelle
                </h1>
              </div>
              <form
                onSubmit={handleSubmit}
                className="grid grid-cols-2 gap-7 items-center w-full"
              >
                <div className="">
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Departman Adı :
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="title"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Departman Başlığı :
                  </label>
                  <input
                    type="text"
                    id="title"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="keywords"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Anahtar Kelimeler :
                  </label>
                  <CreatableSelect
                    onChange={(e) => setKeywords(e)}
                    value={keywords}
                    options={customerKey}
                    isMulti
                    maxMenuHeight={200}
                    id="keywords"
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="departmentUser"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Kullanıcı Seç :
                  </label>
                  <Select
                    maxMenuHeight={200}
                    id="departmentUser"
                    value={departmentUser}
                    options={userOptions}
                    onChange={(e) => setDepartmentUser(e)}
                  />
                </div>

                <div>
                  <label
                    htmlFor="description"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Açıklama :
                  </label>
                  <textarea
                    id="description"
                    type="text"
                    className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                    rows={4}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "20px",
                  }}
                  className="col-span-2"
                >
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5"
                  >
                    Kaydet
                    <i className="fa-regular fa-floppy-disk ml-2"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default DepartmentEdit;
