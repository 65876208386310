import React, { useState, useEffect } from "react";
import "../login/login.css";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import jwtDecode from "jwt-decode";
import api from "../../api/api";
import urls from "../../api/urls";
import MoonLoader from "react-spinners/MoonLoader";

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const rememberMeValue = sessionStorage.getItem("rememberMe") === "true";
    setRememberMe(rememberMeValue);

    if (rememberMeValue) {
      const savedUserName = sessionStorage.getItem("userName");
      const savedPassword = sessionStorage.getItem("password");
      if (savedUserName && savedPassword) {
        // Otomatik olarak giriş yap
        handleSubmit({ userName: savedUserName, password: savedPassword });
      }
    }
  }, []);

  const handleSubmit = (values) => {
    setLoading(true);
    api
      .post(urls.auth, values)
      .then((res) => {
        const token = res.data;
        localStorage.setItem("token", token);
        const decode = jwtDecode(token);
        localStorage.setItem("userData", JSON.stringify(decode));

        if (rememberMe) {
          // "Beni Hatırla" seçeneği işaretlendiğinde bilgileri sessionStorage'e kaydet
          sessionStorage.setItem("rememberMe", true);
          sessionStorage.setItem("userName", values.userName);
          sessionStorage.setItem("password", values.password);
        }

        setLoading(false);
        navigate("/dashboards");
      })
      .catch((err) => {
        console.log(err.data);
        setLoading(false);
        Swal.fire("Email ya da şifre hatalı !");
      });
  };

  return (
    <>
      <div className="loginPage">
        <div className="imgContainer">
          <img src="/images/login.png" alt="" />
        </div>

        <div className="formContainer">
          <img src="/images/logo.png" alt="" />
          <h2>
            Propex'e <span>hoşgeldiniz !</span>
          </h2>

          <Formik
            initialValues={{
              userName: "",
              password: "",
            }}
            validationSchema={Yup.object({
              userName: Yup.string().required("Kullanıcı adı gerekli !"),
              password: Yup.string().required("Şifre Gerekli !"),
            })}
            onSubmit={handleSubmit}
          >
            {({ values, errors, handleSubmit, handleChange }) => (
              <form onSubmit={handleSubmit}>
                <div className="inputGrp">
                  <label htmlFor="userName">Kullanıcı Adı :</label>
                  <input
                    onChange={handleChange}
                    value={values.userName}
                    id="userName"
                    placeholder="admin"
                    className="input-text"
                    type="text"
                  />
                </div>
                <div className="inputGrp">
                  <label htmlFor="password">Şifre : </label>
                  <input
                    onChange={handleChange}
                    value={values.password}
                    id="password"
                    placeholder="••••••••"
                    className="input-text"
                    type="password"
                  />
                </div>

                <div className="spanCont">
                  <div className="checkboxCont">
                    <label htmlFor="checkbox">Beni hatırla</label>
                    <input
                      id="checkbox"
                      type="checkbox"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                  </div>
                  <a href="#">Şifremi unuttum</a>
                </div>

                <button type="submit" className="loginButton">
                  Giriş Yap
                </button>

                <div className="registerCont">
                  <p>Üye değil misiniz ?</p>
                  <a href="#">Kayıt Ol!</a>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      {loading && (
        <div style={{ display: "grid", placeItems: "center", height: "100vh", zIndex: "1000", position:"fixed", top:"0", left:"0", width:"100vw", backgroundColor:"whitesmoke"}}>
          <MoonLoader
            color={"blue"}
            loading={loading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
            speedMultiplier={0.5}
          />
        </div>
      )}
    </>
  );
};

export default Login;









// import React from "react";
// import "../login/login.css";
// import { Formik } from "formik";
// import * as Yup from "yup";
// import { useNavigate } from "react-router-dom";
// import Swal from "sweetalert2";
// import jwtDecode from "jwt-decode";
// import api from "../../api/api";
// import urls from "../../api/urls";
// import MoonLoader from "react-spinners/MoonLoader";
// import { useState } from "react";

// const Login = () => {
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);

//   return (
//     <>
//       <div className="loginPage">
//         <div className="imgContainer">
//           <img src="/images/login.png" alt="" />
//         </div>

//         <div className="formContainer">
//           <img src="/images/logo.png" alt="" />
//           <h2>
//             Propex'e <span>hoşgeldiniz !</span>
//           </h2>

//           <Formik
//             initialValues={{
//               userName: "",
//               password: "",
//             }}
//             validationSchema={Yup.object({
//               userName: Yup.string().required("Kullanıcı adı gerekli !"),
//               password: Yup.string().required("Şifre Gerekli !"),
//             })}
//             onSubmit={(values) => {
//               setLoading(true);
//               api
//                 .post(urls.auth, values)
//                 .then((res) => {
//                   const token = res.data;
//                   localStorage.setItem("token", token);
//                   const decode = jwtDecode(token);
//                   localStorage.setItem("userData", JSON.stringify(decode));
//                   setLoading(false);
//                   navigate("/dashboards");
//                 })
//                 .catch((err) => {
//                   console.log(err.data);
//                   setLoading(false);
//                   Swal.fire("Email ya da şifre hatalı !");
//                 });
//             }}
//           >
//             {({ values, errors, handleSubmit, handleChange }) => (
//               <form onSubmit={handleSubmit}>
//                 <div className="inputGrp">
//                   <label htmlFor="userName">Kullanıcı Adı :</label>
//                   <input
//                     onChange={handleChange}
//                     value={values.userName}
//                     id="userName"
//                     placeholder="admin"
//                     className="input-text"
//                     type="text"
//                   />
//                 </div>
//                 <div className="inputGrp">
//                   <label htmlFor="password">Şifre : </label>
//                   <input
//                     onChange={handleChange}
//                     value={values.password}
//                     id="password"
//                     placeholder="••••••••"
//                     className="input-text"
//                     type="password"
//                   />
//                 </div>

//                 <div className="spanCont">
//                   <div className="checkboxCont">
//                     <label htmlFor="checkbox">Beni hatırla</label>
//                     <input id="checkbox" defaultChecked type="checkbox" />
//                   </div>
//                   <a href="#">Şifremi unuttum</a>
//                 </div>

//                 <button type="submit" className="loginButton">
//                   Giriş Yap
//                 </button>

//                 <div className="registerCont">
//                   <p>Üye değil misiniz ?</p>
//                   <a href="#">Kayıt Ol!</a>
//                 </div>
//               </form>
//             )}
//           </Formik>
//         </div>
//       </div>
//       {loading && (
//         <div style={{ display: "grid", placeItems: "center", height: "100vh", zIndex: "1000", position:"fixed", top:"0", left:"0", width:"100vw", backgroundColor:"whitesmoke"}}>
//           <MoonLoader
//             color={"blue"}
//             loading={loading}
//             size={100}
//             aria-label="Loading Spinner"
//             data-testid="loader"
//             speedMultiplier={0.5}
//           />
//         </div>
//       )}
//     </>
//   );
// };

// export default Login;
