import React, { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet";
import CreatableSelect from "react-select/creatable";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import icon from "../../../assets/images/location.png";
import api from "../../../api/api";
import Swal from "sweetalert2";

const PutAccount = ({ onCancel = () => {} }) => {
  const [customerID, setCustomerID] = useState(Number());
 
  //ACCOUNTS DATA
  const [getAccounts,setGetAccounts] = useState([])
  const [accounts,setAccounts] = useState(null)
  const [accountID,setAccountID] = useState(null)
 
  //FORM STATE
  const [name, setName] = useState("");
  const [taxOffice, setTaxOffice] = useState("");
  const [taxNumber, setTaxNumber] = useState("");
  const [contactName, setContactName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [webUrl, setWebUrl] = useState("");
  const [postCode, setPostCode] = useState("");
  const [description, setDescription] = useState("");

  //FOR SELECT BOX OPTIONS
  const [getSector, setGetSector] = useState([]);
  const [getCountry, setGetCountry] = useState([]);
  const [getCity, setGetCity] = useState([]);
  const [getDistrict, setGetDistrict] = useState([]);
  const [getStatus, setGetStatus] = useState([]);
  const [getType, setGetType] = useState([]);

  //SELECT VALUE STATES
  const [sector, setSector] = useState({});
  const [country, setCountry] = useState({});
  const [city, setCity] = useState({});
  const [district, setDistrict] = useState({});
  const [status, setStatus] = useState({});
  const [type, setType] = useState({});
  const [keywords, setKeywords] = useState([]);

  //MAP STATE
  const [latLng, setLatLng] = useState({});

  useEffect(() => {
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);
    setCustomerID(parsedObject.country);

    api.get(`/api/Account/GetAccounts?customerID=${parsedObject.country}`)
    .then((res)=>{
        setGetAccounts(res.data)
    })
    .catch((err)=>{console.log(err.data)})

    api
      .get("/api/Common/GetParameters?parametername=Sector")
      .then((res) => {
        setGetSector(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });

    api
      .get("/api/Common/GetParameters?parametername=Country")
      .then((res) => {
        setGetCountry(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });

    api
      .get("/api/Common/GetParameters?parametername=City")
      .then((res) => {
        setGetCity(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });

    api
      .get("/api/Common/GetParameters?parametername=District")
      .then((res) => {
        setGetDistrict(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });

    api
      .get("/api/Common/GetParameters?parametername=AccountStatus")
      .then((res) => {
        setGetStatus(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });

    api
      .get("/api/Common/GetParameters?parametername=AccountType")
      .then((res) => {
        setGetType(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }, []);

  // REACT-LEAFLET
  const handleClick = (e) => {
    const { lat, lng } = e.latlng;
    setLatLng({ lat, lng });
  };

  const MapComponent = () => {
    const map = useMapEvents({
      click: handleClick,
    });

    return null;
  };
  const ozelIcon = new L.Icon({
    iconUrl: icon,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  //// SELECT OPTIONS /////////
  const sectorOptions = getSector?.map((item) => ({
    label: item.parameterValue2,
    value: item.parameterID,
  }));

  const countryOptions = getCountry?.map((item) => ({
    label: item.parameterValue2,
    value: item.parameterID,
  }));

  const cityOptions = getCity?.map((item) => ({
    label: item.parameterValue2,
    value: item.parameterID,
  }));

  const districtOptions = getDistrict?.map((item) => ({
    label: item.parameterValue2,
    value: item.parameterID,
  }));

  const statusOptions = getStatus?.map((item) => ({
    label: item.parameterValue2,
    value: item.parameterID,
  }));

  const typeOptions = getType?.map((item) => ({
    label: item.parameterValue2,
    value: item.parameterID,
  }));

  const accountsOptions = getAccounts?.map((item)=>({
    label: item.name,
    value: item.accountID
  }))

  // TO STRING FUNCTION
  const stringKeywords = keywords?.map((item) => item.label);


  const handleChanges = (selectedOption) => {
    setAccounts(selectedOption);
    if (selectedOption) {
      api
        .get(
          `/api/Account/Get?id=${selectedOption.value}&customerID=${customerID}`
        )
        .then((res) => {
          if (selectedOption) {
            setAccountID(res.data.accountID)
            setCustomerID(res.data.customerID)
            setName(res.data.name)
            setTaxOffice(res.data.taxOffice)
            setTaxNumber(res.data.taxNumber)
            setContactName(res.data.contactName)
            setTelephone(res.data.telephone)
            setCellphone(res.data.cellphone)
            setAddress(res.data.address)
            setEmail(res.data.email)
            setWebUrl(res.data.webUrl)
            setPostCode(res.data.postCode)
            setDescription(res.data.description)
            setLatLng(JSON.parse(res.data.latLng))
            setSector({label: res.data.sectorName, value:res.data.sector})
            setCountry({label:res.data.countryName, value:res.data.countryID})
            setCity({label:res.data.cityName, value:res.data.cityID})
            setDistrict({label:res.data.districtName, value:res.data.districtID})
            setType({label:res.data.typeString, value:res.data.type})
            setStatus({label:res.data.statusString, value:res.data.status})
            const data = JSON.parse(res.data.keywords);
            const newData = data.map((item) => ({
              value: item,
              label: item,
              __isNew__: true
            }));
            setKeywords(newData)
          }
    
          })

        .catch((error) => {
          console.error(error);
        });
    }
  };

  const postData = {
    accountID,
    customerID,
    name,
    contactName,
    taxOffice,
    taxNumber,
    address,
    email,
    webUrl,
    postCode,
    telephone,
    cellphone,
    description,
    sector: sector.value,
    countryID: country.value,
    cityID: city.value,
    districtID: district.value,
    status: status.value,
    type: type.value,
    keywords: JSON.stringify(stringKeywords),
    latLng: JSON.stringify(latLng),
    ImageFile: "",
    Notes: "",
  };
  console.log(postData);

  const handleSubmit = (e) => {
    e.preventDefault();
    api
      .put("/api/Account/Put/Put", postData)

      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Müşteri güncellendi !",
          showConfirmButton: false,
          timer: 1500,
        });
        onCancel();
      })

      .catch((err) => {
        console.log(err);
        Swal.fire("Müşteri güncellenemedi !");
      });
  };
  console.log(">>>", keywords);
  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.3)",
        zIndex: "1000",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "60%",
          padding: "20px 20px",
          backgroundColor: "#fff",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-3 gap-4 items-center"
        >
         
          <div
            className="col-span-4"
            style={{ display: "flex", alignItems: "center", gap: "100px" }}
          >
            <MapContainer
              center={[39.1417632, 34.1284977]}
              zoom={5}
              scrollWheelZoom={false}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {latLng.lat && (
                <Marker position={[latLng.lat, latLng.lng]} icon={ozelIcon}>
                  {/* Marker içerisinde ekstra içerik ekleyebilirsiniz */}
                  <Popup>
                    <span>Lat : {latLng?.lat}</span>
                    <br /> Lng: {latLng?.lng}
                  </Popup>
                </Marker>
              )}

              <MapComponent />
            </MapContainer>
          </div>
          <div className="col-span-4">
            <label style={{fontWeight:"600"}} htmlFor="get-account">
              Güncelleyeceğiniz ürünü seçiniz...
            </label>
            <Select value={accounts} onChange={handleChanges} options={accountsOptions} id="get-account" />
          </div>

          <div>
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Cari Adı :
            </label>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              id="title"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>
          <div>
            <label
              htmlFor="salesPrice"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Kişi adı :
            </label>
            <input
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
              type="text"
              id="salesPrice"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>

          <div>
            <label
              htmlFor="description"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Sektör :
            </label>
            <Select
              value={sector}
              onChange={(e) => setSector(e)}
              options={sectorOptions}
            />
          </div>
          <div>
            <label
              htmlFor="code"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Vergi Dairesi :
            </label>
            <input
              value={taxOffice}
              onChange={(e) => setTaxOffice(e.target.value)}
              type="text"
              id="code"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>
          <div>
            <label
              htmlFor="ProductCode"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Vergi No :
            </label>
            <input
              value={taxNumber}
              onChange={(e) => setTaxNumber(e.target.value)}
              type="number"
              id="ProductCode"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>
          <div>
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Adres :
            </label>
            <input
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              type="text"
              id="title"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>
          <div>
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Mail :
            </label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              id="title"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>
          <div>
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Web Site :
            </label>
            <input
              value={webUrl}
              onChange={(e) => setWebUrl(e.target.value)}
              type="text"
              id="title"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>
          <div>
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Posta Kodu :
            </label>
            <input
              value={postCode}
              onChange={(e) => setPostCode(e.target.value)}
              type="text"
              id="title"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>

          <div>
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Ülke :
            </label>
            <Select
              value={country}
              onChange={(e) => setCountry(e)}
              options={countryOptions}
            />
          </div>
          <div>
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              İl :
            </label>
            <Select
              value={city}
              onChange={(e) => setCity(e)}
              options={cityOptions}
            />
          </div>
          <div>
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              İlçe :
            </label>
            <Select
              value={district}
              onChange={(e) => setDistrict(e)}
              options={districtOptions}
            />
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            className="col-span-1"
          >
            <div>
              <label
                className="block mb-2 text-sm font-medium text-gray-900"
                htmlFor="categories"
              >
                Durum :
              </label>
              <Select
                value={status}
                onChange={(e) => setStatus(e)}
                options={statusOptions}
                id="categories"
                name="colors"
                classNamePrefix="select"
              />
            </div>
            <div>
              <label
                className="block mb-2 text-sm font-medium text-gray-900"
                htmlFor="categories"
              >
                Tip :
              </label>
              <Select
                value={type}
                onChange={(e) => setType(e)}
                options={typeOptions}
                id="categories"
                name="colors"
                classNamePrefix="select"
              />
            </div>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            className="col-span-1"
          >
            <div>
              <label
                htmlFor="purchasePrice"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Tel No :
              </label>
              <input
                value={telephone}
                onChange={(e) => setTelephone(e.target.value)}
                type="number"
                id="purchasePrice"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                required
              />
            </div>
            <div>
              <label
                htmlFor="purchasePrice"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Cep No :
              </label>
              <input
                value={cellphone}
                onChange={(e) => setCellphone(e.target.value)}
                type="number"
                id="purchasePrice"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                required
              />
            </div>
          </div>
          <div style={{ marginLeft: "6rem" }} className="col-span-1">
            <input type="file" />
          </div>

          <div className="col-span-2  ">
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Anahtar Kelimeler :
            </label>
            <CreatableSelect
            value={keywords}
              isMulti
              options={keywords}
              onChange={(e) => setKeywords(e)}
            />
          </div>
          <div className="col-span-2">
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Açıklama :
            </label>
            <input
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              type="text"
              id="title"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>

          <div
            className="col-span-4"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <button
              onClick={onCancel}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full m-5"
            >
              Vazgeç
            </button>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5"
            >
              Kaydet
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PutAccount;
