import Sidebars from "../../components/bars/Sidebar";
import Header from "../../components/bars/Header";
import React, { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import Switch from "react-switch";
import api from "../../api/api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";

const StockCardsAdd = () => {
  const navigate = useNavigate();
  const [getBrands, setGetBrands] = useState(null);
  const [getCategories, setGetCategories] = useState(null);
  const [getVat, setGetVat] = useState(null);
  const [getUnitTypes, setGetUnitTypes] = useState(null);
  const [getStockTypes, setGetStockTypes] = useState(null);
  const [getStockStatus, setGetStockStatus] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [code, setCode] = useState("");
  const [barcode, setBarcode] = useState("");
  const [purchasePrice, setPurchasePrice] = useState(Number());
  const [salesPrice, setSalesPrice] = useState(Number());
  const [alertLevel, setAlertLevel] = useState(Number());
  const [isAlert, setIsAlert] = useState(false);
  const [customerID, setCustomerID] = useState(Number());
  const [categoryList, setCategoryList] = useState([]);
  const [token, setToken] = useState(null);
  const [keywords, setKeywords] = useState([]);
  const [customerKey, setCustomerKey] = useState(null);
  const [stockStatus, setStockStatus] = useState({
    value: "",
    label: "",
  });
  const [stockTypes, setStockTypes] = useState({
    value: "",
    label: "",
  });
  const [unitTypes, setUnitTypes] = useState({
    value: "",
    label: "",
  });

  const [brand, setBrand] = useState({
    value: "",
    label: "",
  });
  const [vat, setVat] = useState({
    value: "",
    label: "",
  });

  const handleChange = (nextChecked) => {
    setIsAlert(nextChecked);
  };


  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);
    setCustomerID(parsedObject.country);

    api
      .get(
        `/api/StockCard/GetStockBrands/GetBrands?customerID=${parsedObject.country}`
      )
      .then((res) => {
        setGetBrands(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });

    api
      .get(`/api/Common/GetKeywords?customerID=${parsedObject.country}`)
      .then((res) => {
        const newData = res.data?.map((item) => ({
          value: item,
          label: item,
        }));
        setCustomerKey(newData);
      })
      .catch((err) => console.log(err.data));

    api
      .get(
        `/api/StockCard/GetStockCategories/GetCategories?customerID=${parsedObject.country}`
      )
      .then((res) => {
        setGetCategories(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });

    api
      .get("/api/Common/GetVat")
      .then((res) => {
        setGetVat(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });

    api
      .get("/api/Common/GetParameters?parametername=UnitType")
      .then((res) => {
        setGetUnitTypes(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });

    api
      .get("/api/Common/GetParameters?parametername=StockType")
      .then((res) => {
        setGetStockTypes(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });

    api
      .get("/api/Common/GetParameters?parametername=StockStatus")
      .then((res) => {
        setGetStockStatus(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }, []);


  const brandsName = getBrands?.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const categoriesName = getCategories?.map((item) => ({
    value: item.categoryID,
    label: item.name,
  }));
  const vatName = getVat?.map((item) => ({
    value: item,
    label: item,
  }));

  const unitTypeName = getUnitTypes?.map((item) => ({
    value: item.parameterID,
    label: item.parameterValue2,
  }));
  const stockTypeName = getStockTypes?.map((item) => ({
    value: item.parameterID,
    label: item.parameterValue2,
  }));
  const stockStatusName = getStockStatus?.map((item) => ({
    value: item.parameterID,
    label: item.parameterValue2,
  }));

  const find = getBrands?.find((item) => item.name === brand.value);
  const categoryId = categoryList?.map((item) => item.value);
  const stringCatId = JSON.stringify(categoryId);

  // const newCategoryList = categoryId?.map((item)=>item.value)

  const postData = {
    customerID: Number(customerID),
    brandID: find?.brandID,
    categoryList: stringCatId,
    code,
    name,
    barcode,
    status: Number(stockStatus.value),
    alert: isAlert,
    alertLevel: Number(alertLevel),
    viewIndex: 0,
    description,
    salesPrice: Number(salesPrice),
    purchasePrice: Number(purchasePrice),
    vat: Number(vat.value),
    type: Number(stockTypes.value),
    unitType: unitTypes.value,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    api
      .post("/api/StockCard/Post/Post", postData)

      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Ürün oluşturuldu !",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/stockCards");
      })

      .catch((err) => {
        console.log(err);
        Swal.fire("Ürün Oluşturalamadı !");
      });
  };

  return (
    <>
      {token ? (
        <>
          <Header />
          <div className="flex">
            <Sidebars />
            <div
              className="mt-12 mx-auto"
              style={{
                width: "80%",
                gap: "10px",
                flexDirection: "column",
              }}
            >
              <div className="mb-5">
                <h1 className="text-3xl font-semibold text-blue-700  ">
                  Ürün Oluştur
                </h1>
              </div>
              <form
                onSubmit={handleSubmit}
                className="grid grid-cols-2 gap-7 items-center w-full"
              >
                <div>
                  <label
                    htmlFor="title"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Adı :
                  </label>
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    id="title"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="description"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Açıklama :
                  </label>
                  <input
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    type="text"
                    id="description"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="code"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Kodu :
                  </label>
                  <input
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    type="text"
                    id="code"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <label
                      htmlFor="ProductCode"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Ürün Barkodu :
                    </label>
                    <input
                      value={barcode}
                      onChange={(e) => setBarcode(e.target.value)}
                      type="number"
                      id="ProductCode"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div style={{ width: "200px" }}>
                    <label
                      htmlFor="stockType"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Tip :
                    </label>
                    <Select
                      id="stockType"
                      options={stockTypeName}
                      value={stockTypes}
                      onChange={(e) => setStockTypes(e)}
                      maxMenuHeight={200}
                    />
                  </div>
                  <div style={{ width: "105px" }}>
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900"
                      htmlFor="unitTypes"
                    >
                      Birim :
                    </label>
                    <Select
                      id="unitTypes"
                      options={unitTypeName}
                      value={unitTypes}
                      onChange={(e) => setUnitTypes(e)}
                      maxMenuHeight={200}
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="salesPrice"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Satış Fiyatı :
                  </label>
                  <input
                    value={salesPrice}
                    onChange={(e) => setSalesPrice(e.target.value)}
                    type="number"
                    id="salesPrice"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <label
                      htmlFor="purchasePrice"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Alış Fiyatı :
                    </label>
                    <input
                      value={purchasePrice}
                      onChange={(e) => setPurchasePrice(e.target.value)}
                      type="number"
                      id="purchasePrice"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div style={{ width: "200px" }}>
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900"
                      htmlFor="stockStatus"
                    >
                      Durum :
                    </label>
                    <Select
                      id="stockStatus"
                      options={stockStatusName}
                      value={stockStatus}
                      onChange={(e) => setStockStatus(e)}
                      maxMenuHeight={200}
                    />
                  </div>

                  <div style={{ width: "105px" }}>
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900"
                      htmlFor="vatRate"
                    >
                      KDV Oranı :
                    </label>
                    <Select
                      id="vatRate"
                      options={vatName}
                      value={vat}
                      onChange={(e) => setVat(e)}
                      maxMenuHeight={200}
                    />
                  </div>
                </div>
                <div>
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="brand"
                  >
                    Marka :
                  </label>
                  <Select
                    value={brand}
                    onChange={(e) => setBrand(e)}
                    options={brandsName}
                    id="brand"
                    maxMenuHeight={200}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "30px",
                    justifyContent: "end",
                  }}
                >
                  <div>
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900"
                      htmlFor=""
                    >
                      Stok Adet :
                    </label>
                    <input
                      value={alertLevel}
                      onChange={(e) => setAlertLevel(e.target.value)}
                      type="number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5"
                      required
                    />
                  </div>
                  <div className="flex flex-col">
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900"
                      htmlFor=""
                    >
                      Bildirim :
                    </label>
                    <Switch checked={isAlert} onChange={handleChange} />
                  </div>
                </div>
                <div className="col-span-2">
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="categories"
                  >
                    Kategoriler :
                  </label>
                  <Select
                    id="categories"
                    options={categoriesName}
                    isMulti
                    name="colors"
                    classNamePrefix="select"
                    value={categoryList}
                    onChange={(e) => setCategoryList(e)}
                    maxMenuHeight={200}
                  />
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="visitors"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Anahtar Kelimeler
                  </label>
                  <CreatableSelect
                    onChange={(e) => setKeywords(e)}
                    options={customerKey}
                    isMulti
                    maxMenuHeight={200}
                  />
                </div>

                <div
                  className="col-span-2"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "20px",
                  }}
                >
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5"
                  >
                    Kaydet
                    <i className="fa-regular fa-floppy-disk ml-2"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default StockCardsAdd;
