import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import { useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import api from "../../api/api";
import CreatableSelect from "react-select/creatable";
import MoonLoader from "react-spinners/MoonLoader";

const OrderEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [demandItems, setDemandItems] = useState([
    {
      quantity: Number(),
      unitPrice: Number(),
      discount: Number(),
      name: "",
      taxRate: "",
      totalPrice: Number(),
      price: "",
      taxPrice: "",
      description: "",
      orderItemID: Number(),
      orderID: Number(),
      customerID: Number(),
      stockCardID: Number(),
    },
  ]);
  const [startDate, setStartDate] = useState(new Date());
  const [finishDate, setFinishDate] = useState(new Date());
  const [vat, setVat] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [stockCards, setStockCards] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [title, setTitle] = useState("");
  const [editUser, setEditUser] = useState([]);
  const [token, setToken] = useState(null);
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [customerKey, setCustomerKey] = useState(null);
  const [loading, setLoading] = useState(true);

  const [name, setName] = useState({
    value: "",
    label: "",
  });
  const [currencyValue, setCurrencyValue] = useState({
    value: "TL",
    label: "TL",
  });

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);

    api
      .get(`/api/Account/GetAccounts?customerID=${parsedObject.country}`)
      .then((res) => setAccounts(res.data))
      .catch((err) => console.log(err.data));

    api
      .get(`/api/StockCard/GetStockCards?customerID=${parsedObject.country}`)
      .then((res) => setStockCards(res.data))
      .catch((err) => console.log(err.data));

    api
      .get(`/api/Common/GetKeywords?customerID=${parsedObject.country}`)
      .then((res) => {
        const newData = res.data?.map((item) => ({
          value: item,
          label: item,
        }));
        setCustomerKey(newData);
      })
      .catch((err) => console.log(err.data));

    api
      .get("/api/Common/GetVat")
      .then((res) => setVat(res.data))
      .catch((err) => console.log(err.data));

    api.get("/api/Common/GetCurrencies").then((res) => setCurrency(res.data));

    api
      .get(`/api/Order/Get?id=${params.id}&customerID=${parsedObject.country}`)
      .then((res) => {
        setTitle(res.data.title);
        setName({ value: res.data.account.name, label: res.data.account.name });
        setDemandItems(res.data.orderItems);
        setEditUser(res.data);
        setStartDate(new Date(res.data.orderDate));
        setFinishDate(new Date(res.data.expiryDate));
        setDescription(res.data.description);
        const data = JSON.parse(res.data.keywords);
        const newData = data.map((item) => ({
          value: item,
          label: item,
          __isNew__: true,
        }));
        setKeywords(newData);
      })

      .catch((err) => {
        console.log(err.data);
      })
      .finally(() => setLoading(false));
  }, []);

  const optionsVat = vat.map((item) => ({
    value: item,
    label: item,
  }));

  const optionsCurrency = currency.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const stockCardName = stockCards.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const contactName = accounts.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  //ADD ITEMS///

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...demandItems];
    list[index][name] = value;

    //totalPrice
    let total =
      list[index].quantity * list[index].unitPrice - list[index].discount;
    let tax = (total * list[index].taxRate) / 100;
    let result = total + tax;
    list[index].totalPrice = result;

    //ara toplam
    let altToplam = list[index].quantity * list[index].unitPrice;
    list[index].price = altToplam;

    //KDV
    list[index].taxPrice = tax;

    setDemandItems(list);
  };

  const handleSelectChange = (e, index) => {
    const list = [...demandItems];
    list[index].name = e;
    const stockCardsValue = stockCards.find((item) => item.name === e);
    list[index].stockCardID = stockCardsValue.stockCardID;
    setDemandItems(list);
  };
  const handleSelectChangeVat = (e, index) => {
    const list = [...demandItems];
    list[index].taxRate = e;

    //totalPrice
    let total =
      list[index].quantity * list[index].unitPrice - list[index].discount;
    let tax = (total * list[index].taxRate) / 100;
    let result = total + tax;
    list[index].totalPrice = result;

    //KDV
    list[index].taxPrice = tax;

    setDemandItems(list);
  };

  const handleServiceRemove = (index) => {
    const list = [...demandItems];
    list.splice(index, 1);
    setDemandItems(list);
  };

  const handleServiceAdd = () => {
    setDemandItems([
      ...demandItems,
      {
        quantity: Number(),
        unitPrice: Number(),
        discount: Number(),
        name: "",
        taxRate: "",
        totalPrice: Number(),
        price: "",
        taxPrice: "",
        description: "",
        orderItemID: Number(),
        orderID: Number(),
        customerID: Number(),
        stockCardID: Number(),
      },
    ]);
  };

  //////////////////////////

  const find = accounts.find((item) => item.name === name.value);
  const currencyID = currency.find((item) => item.name === currencyValue.value);

  ////////calculations
  const resultDiscount = demandItems?.length
    ? demandItems
        .map((sayi) => Number(sayi.discount))
        .reduce((acc, currentValue) => acc + currentValue, 0)
    : 0;

  const resultTotalPrice = demandItems?.length
    ? demandItems
        .map((sayi) => Number(sayi.totalPrice))
        .reduce((acc, currentValue) => acc + currentValue, 0)
    : 0;

  const resultSubTotal = demandItems?.length
    ? demandItems
        .map((sayi) => Number(sayi.price))
        .reduce((acc, currentValue) => acc + currentValue, 0)
    : 0;

  const resultKdvTotal = demandItems?.length
    ? demandItems
        .map((sayi) => Number(sayi.taxPrice))
        .reduce((acc, currentValue) => acc + currentValue, 0)
    : 0;

  const postData = {
    orderID: editUser?.orderID,
    customerID: find?.customerID,
    accountID: find?.accountID,
    currencyID: currencyID?.currencyID,
    orderNo: "",
    name: find?.name,
    title: title,
    address: find?.address,
    taxOffice: find?.taxOffice,
    taxNumber: find?.taxNumber,
    orderDate: startDate,
    expiryDate: finishDate,
    price: resultSubTotal,
    lineDiscounts: resultDiscount,
    taxPrice: resultKdvTotal,
    totalPrice: resultTotalPrice,
    type: 0,
    status: 0,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
    description,
    orderItems: demandItems,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    api
      .put("/api/Order/Put/Put", postData)

      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Sipariş başarılı bir şekilde değiştirildi ! ",
          showConfirmButton: false,
          timer: 3000,
        });
        navigate("/orders");
      })

      .catch((err) => {
        console.log(err);
        Swal.fire("Sipariş Değiştirilemedi !");
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {token ? (
        <>
          <Header />

          <div className="flex">
            <Sidebars />
            {loading ? (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "100vh",
                  width: "100vw",
                }}
              >
                <MoonLoader
                  color={"blue"}
                  loading={loading}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={0.5}
                />
              </div>
            ) : (
              <form
                onSubmit={handleSubmit}
                style={{ height: "100vh", width: "80%" }}
                className="mt-12 mx-auto"
              >
                <div className="mb-5">
                  <h1 className="text-3xl font-semibold text-blue-700">
                    Sipariş Güncelle
                  </h1>
                </div>

                <div className="mb-6">
                  <label
                    htmlFor="title"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Başlık
                  </label>
                  <input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    type="text"
                    id="title"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
                <div className="grid gap-6 mb-6 md:grid-cols-2">
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Cari
                    </label>
                    <Select
                      maxMenuHeight={200}
                      id="name"
                      onChange={(e) => setName(e)}
                      value={name}
                      options={contactName}
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="taxOffice"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Vergi Dairesi
                    </label>
                    <input
                      value={find?.taxOffice}
                      readOnly
                      type="text"
                      id="taxOffice"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Vergi No
                    </label>
                    <input
                      value={find?.taxNumber}
                      readOnly
                      type="text"
                      id="password"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Anahtar Kelimeler
                    </label>
                    <CreatableSelect
                      value={keywords}
                      onChange={(e) => setKeywords(e)}
                      options={customerKey}
                      isMulti
                      maxMenuHeight={200}
                    />
                  </div>

                  <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <div>
                      <label
                        htmlFor="visitors"
                        className="block mb-2 text-sm font-medium text-gray-900"
                      >
                        Para Birimi
                      </label>
                      <Select
                        maxMenuHeight={200}
                        onChange={(e) => setCurrencyValue(e)}
                        value={currencyValue}
                        options={optionsCurrency}
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="description"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Açıklama
                    </label>
                    <input
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      type="text"
                      id="description"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Tarih
                    </label>
                    <DatePicker
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      locale={tr}
                      dateFormat={"dd/MM/yy"}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="visitors"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Ödeme Tarihi
                    </label>
                    <DatePicker
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      selected={finishDate}
                      onChange={(date) => setFinishDate(date)}
                      locale={tr}
                      dateFormat={"dd/MM/yy"}
                    />
                  </div>
                </div>

                <div className="relative overflow-x-auto shadow-md sm:rounded-lg mb-5">
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Ürün Adı
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Miktar
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Birim Fiyat
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Indirim
                        </th>
                        <th scope="col" className="px-6 py-3">
                          KDV
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Toplam
                        </th>
                        <th scope="col" className="px-6 py-3">
                          İşlem
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {demandItems.map((singleService, index) => (
                        <tr key={index} className="bg-white border-b">
                          <td className="px-6 py-4">
                            <div style={{ width: "180px" }}>
                              <Select
                                maxMenuHeight={200}
                                value={{
                                  label: singleService.name,
                                  value: singleService.name,
                                }}
                                onChange={(e) =>
                                  handleSelectChange(e.value, index)
                                }
                                options={stockCardName}
                              />
                            </div>
                          </td>

                          <td className="px-6 py-4">
                            <input
                              value={singleService.quantity}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="quantity"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              required
                            />
                          </td>
                          <td className="px-6 py-4">
                            <input
                              value={singleService.unitPrice}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="unitPrice"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              required
                            />
                          </td>
                          <td className="px-6 py-4">
                            <input
                              value={singleService.discount}
                              onChange={(e) => handleServiceChange(e, index)}
                              name="discount"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              required
                            />
                          </td>
                          <td className="px-6 py-4">
                            <div style={{ width: "100px" }}>
                              <Select
                                maxMenuHeight={200}
                                value={{
                                  value: singleService.taxRate,
                                  label: singleService.taxRate,
                                }}
                                onChange={(e) =>
                                  handleSelectChangeVat(e.value, index)
                                }
                                options={optionsVat}
                              />
                            </div>
                          </td>
                          <td className="px-6 py-4">
                            <input
                              value={singleService.totalPrice}
                              readOnly
                              name="totalPrice"
                              type="number"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              required
                            />
                          </td>
                          <td className="px-6 py-4">
                            <button
                              onClick={() => handleServiceRemove(index)}
                              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full m-5"
                            >
                              <i className="fa-regular fa-trash-can"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <button
                      onClick={handleServiceAdd}
                      type="button"
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5"
                    >
                      Ekle
                      <i className="fa-solid fa-plus ml-2"></i>
                    </button>
                  </table>

                  <div className="resultMoney">
                    <div className="resultMoneyInfo">
                      <h1>Ara Toplam :</h1>
                      <p>{resultSubTotal}</p>
                      <span>{currencyValue.value}</span>
                    </div>
                    <div className="resultMoneyInfo">
                      <h1>Satır İndirimi :</h1>
                      <p>{resultDiscount}</p>
                      <span>{currencyValue.value}</span>
                    </div>
                    <div className="resultMoneyInfo">
                      <h1>KDV :</h1>
                      <p>{resultKdvTotal}</p>
                      <span>{currencyValue.value}</span>
                    </div>
                    <div className="resultMoneyInfo">
                      <h1>Toplam :</h1>
                      <p>{resultTotalPrice}</p>
                      <span>{currencyValue.value}</span>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    style={{ width: "150px", float: "right" }}
                    type="submit"
                    className="text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2"
                  >
                    Kaydet <i className="fa-regular fa-floppy-disk ml-2"></i>
                  </button>
                </div>
              </form>
            )}
          </div>
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default OrderEdit;
