import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";

const MachineGroupAdd = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(Number());
  const [token, setToken] = useState(null);

  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);
    setCustomerID(parsedObject.country);
    setLoading(false)

  
  }, []);




  const postData = {
    customerID,
    name,
    title,
    description
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const storedToken = localStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Axios isteği yapılandırma
      await api.post("/api/Machine/PostMachineGroup/PostMachineGroup", postData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Makine grubu oluşturuldu !",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/machineGroups");
    } catch (error) {
      console.error("Hata alındı: ", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${error}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  console.log(postData);
  return (
    <>
      {token ? (
        <>
          <Header />
          <div className="flex">
            <Sidebars />
            {loading ? (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "100vh",
                  width:"100vw"
                }}
              >
                <MoonLoader
                  color={"blue"}
                  loading={loading}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={0.5}
                />
              </div>
            ) : (
              <div
                className="m-12 w-full "
              
              >
                <div className="mb-5">
                  <h1 className="text-3xl font-semibold text-blue-700">
                    Makine Grubu Oluştur
                  </h1>
                </div>
                <form
                  className="grid grid-cols-4 gap-7 items-center w-full"
                  onSubmit={handleSubmit}
                >
                  <div className="col-span-2">
                    <label
                      htmlFor="title"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Başlık :
                    </label>
                    <input
                      type="text"
                      id="title"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div className="col-span-2">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Adı :
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
     
       

                  <div className="col-span-4">
                    <label
                      htmlFor="description"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Açıklama :
                    </label>
                    <textarea
                      id="description"
                      type="text"
                      className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      rows={4}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
          


                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      gap: "20px",
                    }}
                    className="col-span-4"
                  >
                    <button
                      type="submit"
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5"
                    >
                      Kaydet
                      <i className="fa-regular fa-floppy-disk ml-2"></i>
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default MachineGroupAdd;
