import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import MoonLoader from "react-spinners/MoonLoader";
import Select from "react-select";

const PersonelWageAdd = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [customerID, setCustomerID] = useState(Number());
  const [wageStartDate, setWageStartDate] = useState("");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [wageType, setWageType] = useState([]);
  const [selectedWageType, setSelectedWageType] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);
    setCustomerID(parsedObject.country);

    api
      .get("/api/Common/GetParameters?parametername=wageType")
      .then((res) => {
        const wageData = res.data;
        const newWageData = wageData.map((item) => ({
          value: item.parameterID, //backend istenen
          label: item.parameterValue2, //ekrana basılan
        }));
        setWageType(newWageData);
      })
      .catch((err) => console.log(err.data));

    api
      .get(
        `/api/Personel/GetPersonWages?customerID=${parsedObject.country}&personID=${params.id}`
      )
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.error(err.data))
      .finally(() => setLoading(false));
  }, []);

  const postData = {
    customerID,
    personelID: params.id,
    amount,
    wageStartDate,
    description,
    wageType: selectedWageType?.value,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Başlangıç tarihi seçilmediyse uyarı ver
    if (!wageStartDate) {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Geçerli bir tarih seçin",
        showConfirmButton: false,
        timer: 1500,
      });
      setLoading(false); // Loading durumunu kapat
      return; // Fonksiyondan çık
    }

    // Seçilen tarih, mevcut maaş verilerinden önceki bir tarih değilse uyarı ver
    const latestWageDates = data.map((wage) => new Date(wage.wageStartDate));
    const selectedDate = new Date(wageStartDate);

    if (latestWageDates.some((date) => selectedDate <= date)) {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Geçerli bir tarih seçin",
        showConfirmButton: false,
        timer: 1500,
      });
      setLoading(false); // Loading durumunu kapat
      return; // Fonksiyondan çık
    }

    try {
      const storedToken = localStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı.");
      }

      await api.post("/api/Personel/PostPersonWage/PostPersonWage", postData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Maaş Eklendi !",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate(`/personelWages/${params.id}`);
    } catch (error) {
      console.error("Hata alındı: ", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${error}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {token ? (
        <>
          <Header />
          <div className="flex">
            <Sidebars />
            {loading ? (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "100vh",
                  width: "100vw",
                }}
              >
                <MoonLoader
                  color={"blue"}
                  loading={loading}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={0.5}
                />
              </div>
            ) : (
              <div className="m-12 w-full">
                <div className="mb-5">
                  <h1 className="text-3xl font-semibold text-blue-700">
                    Maaş Ekle
                  </h1>
                </div>
                <form
                  onSubmit={handleSubmit}
                  className="grid grid-cols-2 gap-7 items-center w-full"
                >
                  <div className="col-span-1">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Maaş :
                    </label>
                    <input
                      type="number"
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </div>
                  <div className="col-span-1">
                    <label
                      htmlFor="birthDate"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Başlangıç Tarihi :
                    </label>
                    <DatePicker
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      selected={wageStartDate}
                      onChange={(date) => setWageStartDate(date)}
                      locale={tr}
                      dateFormat={"dd/MM/yy"}
                    />
                  </div>
                  <div className="">
                    <label
                      htmlFor="wageType"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Maaş Tipi :
                    </label>
                    <Select
                      maxMenuHeight={200}
                      id="wageType"
                      options={wageType}
                      value={selectedWageType}
                      onChange={(e) => setSelectedWageType(e)}
                    />
                  </div>
                  <div className="col-span-1">
                    <label
                      htmlFor="description"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Açıklama :
                    </label>
                    <textarea
                      id="description"
                      type="text"
                      className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                      rows={4}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                    }}
                    className="col-span-1"
                  >
                    <button
                      type="submit"
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full "
                    >
                      Kaydet
                      <i className="fa-regular fa-floppy-disk ml-2"></i>
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default PersonelWageAdd;
