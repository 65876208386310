import React, { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import Switch from "react-switch";
import api from "../../api/api";
import Swal from "sweetalert2";

const PutProduct = ({ onCancel = () => {} }) => {
  const [getBrands, setGetBrands] = useState(null);
  const [getCategories, setGetCategories] = useState(null);
  const [getVat, setGetVat] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [code, setCode] = useState("");
  const [barcode, setBarcode] = useState("");
  const [purchasePrice, setPurchasePrice] = useState(Number());
  const [salesPrice, setSalesPrice] = useState(Number());
  const [alertLevel, setAlertLevel] = useState(Number());
  const [isAlert, setIsAlert] = useState(false);
  const [customerID, setCustomerID] = useState(Number());
  const [categoryList, setCategoryList] = useState([]);
  const [stockCardsList, setStockCardList] = useState([]);
  const [stockCardsListName, setStockCardListName] = useState(null);
  const [getStockCardID, setGetStockCardID] = useState(null);

  const [type, setType] = useState({
    value: "",
    label: "",
  });
  const [brand, setBrand] = useState({
    value: "",
    label: "",
  });
  const [vat, setVat] = useState({
    value: "",
    label: "",
  });

  const handleChange = (nextChecked) => {
    setIsAlert(nextChecked);
  };

  const typeOptions = [
    { value: 1, label: "Adet" },
    { value: 2, label: "Gram" },
  ];

  useEffect(() => {
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);
    setCustomerID(parsedObject.country);

    api
      .get(
        `/api/StockCard/GetStockBrands/GetBrands?customerID=${parsedObject.country}`
      )
      .then((res) => {
        setGetBrands(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });

    api
      .get(
        `/api/StockCard/GetStockCategories/GetCategories?customerID=${parsedObject.country}`
      )
      .then((res) => {
        setGetCategories(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });

    api
      .get("/api/Common/GetVat")
      .then((res) => {
        setGetVat(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });

    api
      .get(`/api/StockCard/GetStockCards?customerID=${parsedObject.country}`)
      .then((res) => {
        setStockCardList(res.data);
      })
      .catch((err) => {
        console.log(err.data);
      });
  }, []);

  const brandsName = getBrands?.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const categoriesName = getCategories?.map((item) => ({
    value: item.categoryID,
    label: item.name,
  }));
  const vatName = getVat?.map((item) => ({
    value: item,
    label: item,
  }));

  const find = getBrands?.find((item) => item.name === brand.value);
  const categoryId = categoryList?.map((item) => item.value);
  const stringCatId = JSON.stringify(categoryId);

  const getStockCardsName = stockCardsList?.map((item) => ({
    value: item.stockCardID,
    label: item.name,
  }));

  const handleChanges = (selectedOption) => {
    setStockCardListName(selectedOption);
    if (selectedOption) {
      api
        .get(
          `/api/StockCard/Get?id=${selectedOption.value}&customerID=${customerID}`
        )
        .then((res) => {
          setGetStockCardID(res.data.stockCardID);
          setName(res.data.name);
          setDescription(res.data.description);
          setBarcode(res.data.barcode);
          setCode(res.data.code);
          setSalesPrice(res.data.salesPrice);
          setPurchasePrice(res.data.purchasePrice);
          setVat({
            value: res.data.vat.toString(),
            label: res.data.vat.toString(),
          });
          setAlertLevel(res.data.alertLevel);
          setIsAlert(res.data.alert);
          setBrand({ value: res.data.brandName, label: res.data.brandName });
          setType({ value: res.data.type, label: res.data.typeString });
          const data = JSON.parse(res.data.categoryList);
          if (data && data.length > 1) {
            const newData = data.map((item) => ({
              value: item.CategoryID,
              label: item.Name,
            }));
            setCategoryList(newData);
          } else {
            if (data && data.length === 1) {
              const singleItem = {
                value: data[0].CategoryID,
                label: data[0].Name,
              };
              setCategoryList([singleItem]);
            } else {
              setCategoryList([]);
            }
          }
        })

        .catch((error) => {
          console.error(error);
        });
    }
  };

  console.log(">>", categoryList);
  const postData = {
    stockCardID: getStockCardID,
    customerID: Number(customerID),
    brandID: find?.brandID,
    categoryList: stringCatId,
    code,
    name,
    barcode,
    status: 0,
    alert: isAlert,
    alertLevel,
    viewIndex: 0,
    description,
    salesPrice,
    purchasePrice,
    vat: vat.value,
    type: type.value,
  };
  console.log("postData", postData);

  const handleSubmit = (e) => {
    e.preventDefault();
    api
      .put("/api/StockCard/Put/Put", postData)

      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Ürün güncellendi !",
          showConfirmButton: false,
          timer: 1500,
        });
        onCancel();
      })

      .catch((err) => {
        console.log(err.data);
        Swal.fire("Ürün güncellenemedi !");
      });
  };

  // console.log(postData);
  return (
    <div
      style={{
        position: "absolute",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.3)",
        zIndex: "1000",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "60%",
          padding: "30px 20px",
          backgroundColor: "#fff",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-2 gap-7 items-center"
        >
          <div className="col-span-2">
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Güncellenecek ürünü seçiniz...
            </label>
            <Select
              value={stockCardsListName}
              onChange={handleChanges}
              options={getStockCardsName}
              id="getStockCard"
            />
          </div>
          <div className="col-span-2">
            <input type="file" />
          </div>
          <div>
            <label
              htmlFor="title"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Adı :
            </label>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              id="title"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>
          <div>
            <label
              htmlFor="description"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Açıklama :
            </label>
            <input
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              type="text"
              id="description"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>
          <div>
            <label
              htmlFor="code"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Kodu :
            </label>
            <input
              value={code}
              onChange={(e) => setCode(e.target.value)}
              type="text"
              id="code"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "space-between",
            }}
          >
            <div>
              <label
                htmlFor="ProductCode"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Ürün Barkodu :
              </label>
              <input
                value={barcode}
                onChange={(e) => setBarcode(e.target.value)}
                type="number"
                id="ProductCode"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                required
              />
            </div>
            <div style={{ width: "105px" }}>
              <Select
                options={typeOptions}
                value={type}
                onChange={(e) => setType(e)}
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="salesPrice"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Satış Fiyatı :
            </label>
            <input
              value={salesPrice}
              onChange={(e) => setSalesPrice(e.target.value)}
              type="number"
              id="salesPrice"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              required
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "space-between",
            }}
          >
            <div>
              <label
                htmlFor="purchasePrice"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Alış Fiyatı :
              </label>
              <input
                value={purchasePrice}
                onChange={(e) => setPurchasePrice(e.target.value)}
                type="number"
                id="purchasePrice"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                required
              />
            </div>
            <div style={{ width: "105px" }}>
              <Select
                options={vatName}
                value={vat}
                onChange={(e) => setVat(e)}
              />
            </div>
          </div>
          <div>
            <label htmlFor="brand">Marka :</label>
            <Select
              value={brand}
              onChange={(e) => setBrand(e)}
              options={brandsName}
              id="brand"
            />
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "30px",
              justifyContent: "end",
            }}
          >
            <input
              value={alertLevel}
              onChange={(e) => setAlertLevel(e.target.value)}
              type="number"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-20 p-2.5"
              required
            />
            <Switch checked={isAlert} onChange={handleChange} />
          </div>
          <div className="col-span-2">
            <label htmlFor="categories">Kategoriler :</label>
            <Select
              id="categories"
              options={categoriesName}
              isMulti
              name="colors"
              classNamePrefix="select"
              value={categoryList}
              onChange={(e) => setCategoryList(e)}
            />
          </div>

          <div
            className="col-span-2"
            style={{ display: "flex", justifyContent: "center", gap: "20px" }}
          >
            <button
              onClick={onCancel}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full m-5"
            >
              Vazgeç
            </button>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5"
            >
              Onayla
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PutProduct;
