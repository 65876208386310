import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import "../demands/demands.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../../api/api";
import Swal from "sweetalert2";
import MoonLoader from "react-spinners/MoonLoader";

const MeetingLogin = () => {
  const params = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [newData, setNewData] = useState([]);
  const [description, setDescription] = useState("");

  useEffect(() => {
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);

    api
      .get(
        `/api/Meeting/Get?id=${params.id}&customerID=${parsedObject.country}`
      )
      .then((res) => {
        setData(res.data);
        setLoading(false);
        const descData = res.data.meeting_Users.find(
          (item) => item.userID === Number(parsedObject.nameidentifier)
        );
        setDescription(descData.description);
      })
      .catch((err) => console.error(err.data));

    api
      .get(`/api/User/GetUsers?customerID=${parsedObject.country}`)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => console.error(err.data));
  }, []);

  useEffect(() => {
    if (users && data.meeting_Users) {
      // Veri ve kullanıcılar yüklendiyse eşleşme işlemini yap
      const matchedUsers = data.meeting_Users.map((item) => {
        const matchedUser = users.find((user) => user.userID === item.userID);
        if (matchedUser) {
          return { ...item, userName: matchedUser.userName };
        }
        return item;
      });
      // Eşleşmiş kullanıcıları içeren veriyi set et
      setNewData(matchedUsers);
    }
  }, [users, data]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const storedToken = localStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Axios isteği yapılandırma
      await api.put(
        `/api/Meeting/PutUserDescription/PutUserDescription?meetingID=${params.id}&description=${description}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Not oluşturuldu!",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.error("Hata alındı: ", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Not oluşturulamadı!",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <div
            style={{ 
              display: "grid",
              placeItems: "center",
              height: "100vh",
              width:"100vw"
            }}
          >
            <MoonLoader
              color={"blue"}
              loading={loading}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
              speedMultiplier={0.5}
            />
          </div>
        ) : (
          <div
          className="mt-12 mx-auto"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "90px",
              width:"80%"
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                gap: "20px",
              }}
            >
              {data.zoom_URL === null ? (
                null
              ) : (
                <div style={{ width: "70vw" }}>
                <iframe
                  height={500}
                  width={500}
                  style={{ width: "100%" }}
                  src={`${data?.zoom_URL}`}
                  allow="camera; microphone; display-capture"
                ></iframe>
              </div>
              )}
              <div
              className="bg-gray-50"
                style={{
                  width: data?.zoom_URL ? "100vw" : "70vw",
                  maxHeight: "500px",
                  marginRight: "20px",
                  overflowY: "scroll",
                  minHeight:"200px"
                }}
              >
                <ul className="text-sm font-medium text-blue-600 bg-white border border-gray-200 rounded-lg ">
                  <li className="w-full px-4 py-2 border-b border-gray-200 rounded-t-lg font-semibold text-2xl">
                    Toplantı Gündem Maddeleri
                  </li>
                  {data?.meetingAgendaItems.map((item, index) => (
                    <li
                      key={index}
                      className="w-full px-4 py-2 border-b border-gray-200 text-slate-600 text-lg"
                    >
                      <div className="flex gap-4">
                        <p className="text-blue-600">{index + 1}.</p>
                        <p>{item?.description}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "50px",
              }}
            >
              <div
                style={{
                  width: "40vw",
                  maxHeight: "300px",
                  marginRight: "20px",
                  overflowY: "scroll",
                }}
              >
                <ul className="text-sm font-medium text-blue-600 bg-white border border-gray-200 rounded-lg ">
                  <li className="w-full px-4 py-2 border-b border-gray-200 rounded-t-lg font-semibold text-2xl">
                    Katılımcılar
                  </li>
                  {newData?.map((item) => (
                    <li className="w-full px-4 py-2 border-b border-gray-200 text-slate-600 text-lg">
                      <div className="flex justify-between">
                        <p>{item?.userName}</p>
                        {item?.status === 1 ? (
                          <span className="text-green-600 text-base">
                            Katıldı
                          </span>
                        ) : (
                          <span className="text-red-600 text-base">
                            Katılmadı
                          </span>
                        )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <form onSubmit={handleSubmit} style={{ width: "40vw" }}>
                <label
                  htmlFor="description"
                  className="block text-xl font-semibold text-blue-600"
                >
                  Not :
                </label>
                <textarea
                  id="description"
                  type="text"
                  className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <button
                  type="submit"
                  className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-1.5 focus:outline-none w-24"
                >
                  Kaydet
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MeetingLogin;
