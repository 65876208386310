import React, { memo, useEffect, useState } from 'react';
import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';
import api from '../../api/api';



function Select({ value, handleId, nodeId }) {
  const [data,setData] = useState(null)
  useEffect(() => {
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);

    api
      .get(`/api/FormBuilder/GetForms?customerID=${parsedObject.country}`)
      .then((res) =>{
        const datas = res.data
        const newData = datas.map((item) => ({
          value: item.formID,
          label: item.name,
        }));
        setData(newData)
      })
      .catch((err) => console.log(err.data))

  }, []);



  const { setNodes } = useReactFlow();
  const store = useStoreApi();

  const onChange = (evt) => {
    const { nodeInternals } = store.getState();
    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...node.data,
            formID: {
              ...node.data.formID,
              [handleId]: evt.target.value,
            },
          };
        }

        return node;
      })
    );
  };


  return (
    <div className="custom-node__select">
      <Handle style={{top:"24px", left:"-15px",width:"6px", height:"10px",borderRadius:"2px",backgroundColor:"#778899"}} type='target' position={Position.Left} id={handleId}/>
      <div style={{fontSize:"12px", fontWeight:"500", paddingRight:"10px", width:"100px"}}>Seçiniz</div>
      <select className="nodrag" onChange={onChange} value={value}>
        {data?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
     
      <Handle style={{top:"24px", right:"-15px",width:"6px", height:"10px",borderRadius:"2px",backgroundColor:"#778899"}} type="source" position={Position.Right} id={handleId} />
    </div>
  );
}

function CustomNodeFormModule({ id, data }) {
  return (
    <>
   <div style={{backgroundColor:"#f5f5f6",borderRadius:"10px"}}>
   <div className="custom-node__header">
        This is a <strong>{data.label}</strong>
      </div>
      <div className="custom-node__body">
        {Object.keys(data.formID).map((handleId) => (
          <Select key={handleId} nodeId={id} value={data.formID[handleId]} handleId={handleId} />
        ))}
      </div>
      
   </div>
    </>
  );
}

export default memo(CustomNodeFormModule);



