import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import { useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import MoonLoader from "react-spinners/MoonLoader";

const PersonelGroupDetail = () => {
    const params = useParams();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(null);
    const [groupData, setGroupData] = useState([]);

    useEffect(() => {
        setToken(localStorage.getItem("token"));
        const storedToken = window.localStorage.getItem("userData");
        const parsed = JSON.parse(storedToken);
        function parseClaimsObject(parsed) {
          const result = {};
    
          for (const key in parsed) {
            if (key.endsWith("/country")) {
              result.country = parsed[key];
            } else if (key.endsWith("/emailaddress")) {
              result.emailaddress = parsed[key];
            } else if (key.endsWith("/name")) {
              result.name = parsed[key];
            } else if (key.endsWith("/nameidentifier")) {
              result.nameidentifier = parsed[key];
            }
          }
    
          return result;
        }
        const parsedObject = parseClaimsObject(parsed);
    
        api
          .get(
            `/api/Personel/GetPersonGroup?id=${params.id}`
          )
          .then((res) => {
            setGroupData(res.data);
          })
          .catch((err) => {
            console.log(err.data);
          })
          .finally(() => setLoading(false));
      }, []);
  return (
    <>
    <Header />
    <div className="flex">
      <Sidebars />
      {loading ? (
        <div
          style={{
            display: "grid",
            placeItems: "center",
            height: "100vh",
            width: "100vw",
          }}
        >
          <MoonLoader
            color={"blue"}
            loading={loading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
            speedMultiplier={0.5}
          />
        </div>
      ) : (
        <div className="flex justify-center items-center gap-8 mx-auto">
          <div className="flex justify-center items-center h-screen flex-col gap-10">
            <div
              style={{
                minWidth: "40rem",
                maxWidth: "40rem",
              }}
              className="bg-white max-w-3xl shadow overflow-hidden sm:rounded-lg"
            >
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-blue-600">
                  Personel Grup Bilgileri
                </h3>
                
              </div>
              <div className="border-t border-gray-200 grid-cols-2 grid">
                <div className="col-span-2 sm:col-span-1">
                  <div className="bg-white px-4 py-5 items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <label
                      htmlFor="userSelect"
                      className="text-sm  font-medium text-gray-500"
                    >
                      Personel Grup Adı :
                    </label>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {groupData?.name}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <label
                      htmlFor="userSelect"
                      className="text-sm font-medium text-gray-500"
                    >
                      Başlık :
                    </label>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {groupData?.title}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 items-center sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <label
                      htmlFor="userSelect"
                      className="text-sm font-medium text-gray-500"
                    >
                      Açıklama :
                    </label>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {groupData?.description}
                    </dd>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  </>
  )
}

export default PersonelGroupDetail