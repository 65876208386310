import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import left from "../../assets/images/left-click.png";
import right from "../../assets/images/right-click.png";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { RiDeleteBin6Line } from "react-icons/ri";
import MoonLoader from "react-spinners/MoonLoader";

const RoleIdentify = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [customerID, setCustomerID] = useState([]);
  const [data, setData] = useState([]);
  const [role, setRole] = useState([]);
  const [assignedModules, setAssignedModules] = useState([]);
  const [preAssignedModules, setPreAssignedModules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showChecks, setShowChecks] = useState(false);
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [token, setToken] = useState(null);

  // CHECK STATES
  const [insertPermission, setInsertPermission] = useState(false);
  const [updatePermission, setUpdatePermission] = useState(false);
  const [deletePermission, setDeletePermission] = useState(false);
  const [reportPermission, setReportPermission] = useState(false);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);
    setCustomerID(parsedObject.country);

    api
      .get(
        `/api/User/GetUserModules?customerID=${parsedObject.country}&userID=${parsedObject.nameidentifier}`
      )
      .then((res) => setData(res.data))
      .catch((err) => console.log(err.data))
      

    api
      .get(
        `/api/User/GetRole?customerID=${parsedObject.country}&roleID=${params.id}`
      )
      .then((res) => {
        setRole(res.data);
        const a1 = res.data.rolesModules;
        const a2 = a1.map((item) => item.module);
        setPreAssignedModules(a2);
      })
      .catch((err) => console.log(err.data))
      .finally(() => setLoading(false));
  }, [preAssignedModules]);

  const handleItemClick = (item) => {
    setSelectedItemData(item);
    setShowChecks(true);
  };

  const moveItemToAssigned = () => {
    if (selectedItemData && !assignedModules.includes(selectedItemData)) {
      const yeniOge = {
        ...selectedItemData,
        customerID,
        roleID: params.id,
        insertPermission,
        updatePermission,
        deletePermission,
        reportPermission,
      };

      setAssignedModules((oncekiModuller) => [...oncekiModuller, yeniOge]);
      setData((oncekiData) =>
        oncekiData.filter((oge) => oge !== selectedItemData)
      );
      setSelectedItemData(null);
      setShowChecks(false);
    }
  };

  const handleSave = () => {
    const assignedModulesWithPermissions = assignedModules?.map((item) => ({
      moduleID: item.moduleID,
      roleID: params.id,
      customerID,
      insertPermission,
      updatePermission,
      deletePermission,
      reportPermission,
      description: "",
    }));

    api
      .post(
        "/api/User/PostRolesModules/PostRolesModules",
        assignedModulesWithPermissions
      )
      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "İşlem başarılı !",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/roles");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire("İşlem başarısız !");
      });

  };

  const moveItemToAllModules = () => {
    if (selectedItemData && !data.includes(selectedItemData)) {
      setData((prevData) => [...prevData, selectedItemData]);
      setAssignedModules((prevModules) =>
        prevModules.filter((item) => item !== selectedItemData)
      );
      setSelectedItemData(null);
    }
    setShowChecks(false);
  };

  const handleDelete = async (moduleID) => {
    const dataToDelete = {
      customerID: customerID,
      roleID: params.id,
      moduleID,
    };
    try {
      Swal.fire({
        title: "Silmek istediğinize emin misiniz?",
        text: "Bunu geri döndüremezsiniz!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, eminim !",
        cancelButtonText: "Kapat",
      }).then((result) => {
        if (result.isConfirmed) {
          api.delete("/api/User/DeleteRoleModule/DeleteRoleModule", {
            data: dataToDelete,
          });
          Swal.fire({
            title: "Başarıyla silindi!",
            text: "Sipariş silindi.",
            icon: "success",
          });
        }
      });
    } catch (error) {
      console.log(error.data);
    }
  };

  return (
    <>
    {token ? (
       <>
       <Header />
      <div className="flex">
       <Sidebars />
       {loading ? (
                  <div
                  style={{
                    display: "grid",
                    placeItems: "center",
                    height: "100vh",
                    width:"100vw"
                  }}
                >
                  <MoonLoader
                    color={"blue"}
                    loading={loading}
                    size={100}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    speedMultiplier={0.5}
                  />
                </div>
       ) : (
      <div
         style={{ height: "600px", width:"90%" }}
         className="flex flex-col justify-center items-center mt-24 gap-24 mx-auto"
       >
         <div style={{width:"90%", }} className="flex justify-between">
           <h1
             className="text-3xl font-semibold text-gray-600 "
           >
             Rol:{" "}
             <span className="font-medium ml-2 text-gray-500">{role.name}</span>
           </h1>
           <div>
             <button
               onClick={handleSave}
               type="submit"
               className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-16 rounded-full "
             >
               Kaydet
               <i className="fa-regular fa-floppy-disk ml-2"></i>
             </button>
           </div>
         </div>
 
         <div className="flex gap-16 h-full">
           <div className="items-center"></div>
           <div
             style={{ overflowY: "scroll", maxHeight: "500px", minWidth:"300px" }}
             className=" origin-top-right right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none "
           >
             <h1 className="px-4 py-2 text-xl font-medium text-red-500">
               Tüm Modüller
             </h1>
             <div
               className="py-1"
               role="menu"
               aria-orientation="vertical"
               aria-labelledby="options-menu"
             >
               {data
                 .filter(
                   (item) =>
                     !preAssignedModules.some(
                       (assignedItem) => assignedItem.moduleID === item.moduleID
                     ) &&
                     !assignedModules.some(
                       (assignedItem) => assignedItem.moduleID === item.moduleID
                     )
                 )
                 .map((item, index) => (
                   <button
                     key={index}
                     className="block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                     role="menuitem"
                     onClick={() => handleItemClick(item)}
                     type="button"
                   >
                     {item.moduleName}
                   </button>
                 ))}
             </div>
           </div>
           <div className="flex flex-col gap-7 justify-center">
             <button onClick={moveItemToAssigned}>
               <img src={right} alt="" />
             </button>
             <button onClick={moveItemToAllModules}>
               <img src={left} alt="" />
             </button>
           </div>
           <div
             style={{ overflowY: "scroll", maxHeight: "500px", minWidth:"300px" }}
             className=" origin-top-right right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
           >
             <h1 className="px-4 py-2 text-xl font-medium text-blue-500">
               Atanacak Modüller
             </h1>
             <div
               className="py-1"
               role="menu"
               aria-orientation="vertical"
               aria-labelledby="options-menu"
             >
               {assignedModules.map((item) => (
                 <button
                   key={item.id}
                   className="block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                   role="menuitem"
                   onClick={() => handleItemClick(item)}
                 >
                   {item.moduleName}
                 </button>
               ))}
             </div>
           </div>
           <div
             style={{ overflowY: "scroll", maxHeight: "500px", minWidth:"300px" }}
             className=" origin-top-right right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none "
           >
             <h1 className="px-4 py-2 px-4 py-2 text-xl font-medium text-green-500">
               Atanmış Modüller
             </h1>
             <div
               className="py-1"
               role="menu"
               aria-orientation="vertical"
               aria-labelledby="options-menu"
             >
               {preAssignedModules.map((item, index) => (
                 <button
                   key={index}
                   className="text-left block w-full px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                   role="menuitem"
                 >
                   <div className="flex justify-between items-center">
                     <p>{item.name}</p>
                     <button
                       onClick={() => {
                         handleDelete(item.moduleID);
                       }}
                       className="font-medium text-red-600 hover:underline"
                     >
                       <RiDeleteBin6Line />
                     </button>
                   </div>
                 </button>
               ))}
             </div>
           </div>
         </div>
         {showChecks && (
           <ul className="items-center w-1/2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex">
             <li className="w-full">
               <div className="flex items-center ps-3">
                 <input
                   id="laravel-checkbox-list"
                   type="checkbox"
                   value={reportPermission}
                   className=" w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                   onChange={() => setReportPermission(!reportPermission)}
                 />
                 <label
                   htmlFor="laravel-checkbox-list"
                   className="w-full py-3 ms-2 text-sm font-medium text-gray-900"
                 >
                   Raporlar
                 </label>
               </div>
             </li>
             <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r">
               <div className="flex items-center ps-3">
                 <input
                   id="vue-checkbox-list"
                   type="checkbox"
                   value={insertPermission}
                   onChange={() => setInsertPermission(!insertPermission)}
                   className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                 />
                 <label
                   htmlFor="vue-checkbox-list"
                   className="w-full py-3 ms-2 text-sm font-medium text-gray-900"
                 >
                   Ekleme
                 </label>
               </div>
             </li>
             <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r">
               <div class="flex items-center ps-3">
                 <input
                   id="react-checkbox-list"
                   type="checkbox"
                   value={updatePermission}
                   onChange={() => setUpdatePermission(!updatePermission)}
                   className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                 />
                 <label
                   htmlFor="react-checkbox-list"
                   className="w-full py-3 ms-2 text-sm font-medium text-gray-900"
                 >
                   Güncelleme
                 </label>
               </div>
             </li>
             <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r">
               <div className="flex items-center ps-3">
                 <input
                   id="angular-checkbox-list"
                   type="checkbox"
                   value={deletePermission}
                   onChange={() => setDeletePermission(!deletePermission)}
                   className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                 />
                 <label
                   htmlFor="angular-checkbox-list"
                   className="w-full py-3 ms-2 text-sm font-medium text-gray-900"
                 >
                   Silme
                 </label>
               </div>
             </li>
           </ul>
         )}
       </div>

       )}
      </div>
     </>
    ) : (
      navigate("/")
    )}
    </>
  );
};

export default RoleIdentify;
