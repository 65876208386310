import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import MoonLoader from "react-spinners/MoonLoader";
import { useNavigate } from "react-router-dom";
import TasksByAssignedUser from "./TasksByAssignedUser";
import TasksByCreatorUser from "./TasksByCreatorUser";
import TasksAllByUser from "./TasksAllByUser";

const Tasks = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab") || "AssignerUser"
  ); // Varsayılan olarak local storage'dan veya 'AssignerUser' olarak başlat

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    localStorage.setItem("activeTab", tabName); // Sekme değiştiğinde local storage'a kaydet
  };

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);

    api
      .get(`/api/Task/GetTasks?customerID=${parsedObject.country}`)
      .then((res) => setData(res.data))
      .catch((err) => console.log(err.data))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {token ? (
        <>
          <Header />
          <div className="flex">
          <Sidebars />
            {loading ? (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "100vh",
                  width:"100vw"
                }}
              >
                <MoonLoader
                  color={"blue"}
                  loading={loading}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={0.5}
                />
              </div>
            ) : (
              <div style={{width:"90%"}} className="m-12">
                <div className="add-button">
                  <div
                    style={{ marginTop: "-30px" }}
                    className="text-sm font-medium text-center text-gray-500 border-b border-gray-200"
                  >
                    <ul className="flex flex-wrap -mb-px ">
                      <li className="me-2">
                        <a
                          href="#"
                          className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg ${
                            activeTab === "AllUser"
                              ? "text-blue-500 border-blue-300"
                              : "hover:text-gray-600 hover:border-gray-300 text-gray-500 "
                          }`}
                          onClick={() => handleTabClick("AllUser")}
                        >
                          Tüm Görevler
                        </a>
                      </li>
                      <li className="me-2">
                        <a
                          href="#"
                          className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg ${
                            activeTab === "CreatorUser"
                              ? "text-blue-500 border-blue-300"
                              : "hover:text-gray-600 hover:border-gray-300 text-gray-500 "
                          }`}
                          onClick={() => handleTabClick("CreatorUser")}
                        >
                          Atadığım Görevler
                        </a>
                      </li>
                      <li className="me-2">
                        <a
                          href="#"
                          className={`inline-block p-4 border-b-2 border-transparent rounded-t-lg ${
                            activeTab === "AssignerUser"
                              ? "text-blue-600 border-blue-300 "
                              : "hover:text-gray-600 hover:border-gray-300 text-gray-500 "
                          }`}
                          onClick={() => handleTabClick("AssignerUser")}
                        >
                          Atanmış Görevler
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div>
                    {activeTab === "AssignerUser" && <TasksByAssignedUser />}
                    {activeTab === "CreatorUser" && <TasksByCreatorUser />}
                    {activeTab === "AllUser" && <TasksAllByUser />}
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default Tasks;
