import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../../api/api";
import MoonLoader from "react-spinners/MoonLoader";
import Swal from "sweetalert2";
import Select from "react-select";
import { FaStar } from "react-icons/fa";
import doc from "../../assets/images/docimg.png";

/// REACT SPLIDE
import { Splide, SplideSlide } from "@splidejs/react-splide";
// Default theme
import "@splidejs/react-splide/css";

const TasksByAssignedUserDetail = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [users, setUsers] = useState(null);
  const [data, setData] = useState(null);
  const [newData, setNewData] = useState(null);
  const [userID, setUserID] = useState(null);
  const [customerID, setCustomerID] = useState(null);
  const [taskTodo, setTaskTodo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setToken] = useState(null);
  const [description, setDescription] = useState("");
  const [isAddingComment, setIsAddingComment] = useState(false);
  const [taskComments, setTaskComments] = useState([]);
  const [newTaskComments, setNewTaskComments] = useState(null);
  const [taskStatus, setTaskStatus] = useState([]);
  const [selectedTypeStatus, setSelectedTypeStatus] = useState([]);
  const [allStatus, setAllStatus] = useState([]);
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);
  const [dataImages, setDataImages] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);
    setUserID(parsedObject.nameidentifier);
    setCustomerID(parsedObject.country);

    api
      .get(`/api/User/GetUsers?customerID=${parsedObject.country}`)
      .then((res) => setUsers(res.data))
      .catch((err) => console.error(err.data));

    api
      .get(`/api/Task/Get?id=${params.id}&customerID=${parsedObject.country}`)
      .then((res) => {
        setData(res.data);
        setDataImages(res.data.imageFiles);
        setRating(res.data.taskDifficulty);
      })
      .catch((err) => console.log(err.data))
      .finally(() => setLoading(false));
    api
      .get(
        `/api/Task/GetTaskTodos?taskID=${params.id}&customerID=${parsedObject.country}`
      )
      .then((res) => setTaskTodo(res.data))
      .catch((err) => console.log(err.data));

    api
      .get(`/api/Task/GetTaskComments?taskID=${params.id}`)
      .then((res) => setTaskComments(res.data))
      .catch((err) => console.log(err.data));
    api
      .get("/api/Task/GetTaskStatus")
      .then((res) => {
        setAllStatus(res.data);
        const dataTaskStatus = res.data;
        const newDataTasksStatus = dataTaskStatus.map((item) => ({
          value: item.parameterID,
          label: item.parameterValue2,
        }));
        setTaskStatus(newDataTasksStatus);
      })
      .catch((err) => console.log(err.data));
  }, [params.id]);

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();

    // Saate 3 ekleyerek hesaplama yapalım
    let saat = tarihObjesi.getHours();

    // Eğer saat 24'ten büyükse 24'e göre modunu alalım
    saat = saat % 24;

    // Saati string formatına çevirirken padding işlemi yapalım
    const saatString = saat.toString().padStart(2, "0");
    const dakika = tarihObjesi.getMinutes().toString().padStart(2, "0");

    return `${gun}.${ay}.${yil} - ${saatString}.${dakika} `;
  };
  const formatDateComment = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();

    // Saate 3 ekleyerek hesaplama yapalım
    let saat = tarihObjesi.getHours();

    // Eğer saat 24'ten büyükse 24'e göre modunu alalım
    saat = saat % 24;

    // Saati string formatına çevirirken padding işlemi yapalım
    const saatString = saat.toString().padStart(2, "0");
    const dakika = tarihObjesi.getMinutes().toString().padStart(2, "0");

    return `${gun}.${ay}.${yil} - ${saatString}.${dakika} `;
  };

  const handleCheck = (index) => {
    const updatedTasks = [...taskTodo];
    updatedTasks[index].isFinished = !updatedTasks[index].isFinished; // Toggle isFinished
    setTaskTodo(updatedTasks);
  };

  const postData = taskTodo?.map((item) => ({
    toDoID: item.toDoID,
    customerID: item.customerID,
    taskID: item.taskID,
    userID: item.userID,
    name: item.name,
    isFinished: item.isFinished,
    viewIndex: 0,
  }));



  const handleSave = async () => {
    await api
      .put("/api/Task/PutTaskTodos", postData)
      .then(() => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Alt görev güncellendi !",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/tasks");
      })
      .catch((err) => {
        console.error(err);
        Swal.fire("Alt görev güncellenemedi !");
      });
  };

  const postCommentData = {
    description,
    userID,
    taskID: params.id,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsAddingComment(true); // Yorum ekleniyor olarak işaretle
    await api
      .post("/api/Task/PostTaskComment", postCommentData)
      .then((res) => {
        setDescription("");
        api
          .get(`/api/Task/GetTaskComments?taskID=${params.id}`)
          .then((res) => setTaskComments(res.data))
          .catch((err) => console.log(err.data))
          .finally(() => setLoading(false));
      })
      .catch((err) => {
        console.error(err.data);
      })
      .finally(() => {
        setIsAddingComment(false); // Yorum eklendikten sonra işareti kaldır
      });
  };

  useEffect(() => {
    if (users && taskComments && data && allStatus) {
      // Veri ve kullanıcılar yüklendiyse eşleşme işlemini yap
      const matchedModules = taskComments.map((item) => {
        // Kullanıcıyı bul
        const matchedUser = users.find((user) => user.userID === item.userID);
        // Kullanıcının verilerini ve todoList'teki ilgili verileri kullanarak yeni bir nesne oluştur
        return {
          ...item,
          // Kullanıcı bilgilerini de ekle
          userName: matchedUser.userName,
        };
      });
      // Eşleşmiş kullanıcıları içeren veriyi set et
      setNewTaskComments(matchedModules);

      const matchedUserForData = users.find(
        (user) => user.userID === data.userID
      );
      setNewData((prevData) => ({
        ...prevData,
        userName: matchedUserForData.userName,
      }));

      const matchedStatusForData = allStatus.find(
        (status) => status.parameterID === data?.status
      );
      setSelectedTypeStatus({
        value: matchedStatusForData?.parameterID,
        label: matchedStatusForData?.parameterValue2,
      });
    }
  }, [users, taskComments, data, allStatus]);



  const handleChangeStatus = async (e) => {
    setLoading(true);
    setSelectedTypeStatus(e);

    try {
      const storedToken = localStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }
      api.put(
        `/api/Task/PutTaskStatus?taskID=${params.id}&status=${e.value}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Tamamlanma durumu güncellendi !",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Tamamlanma durumu güncellenemedi!",
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  // FILE ONCHANGE
  const handleChangeFile = (event) => {
    setFile(event.target.files);
  };

  const handleDocSubmit = async (e) => {
    setLoading(true);

    const formData = new FormData();
    // Dosya yüklenmişse formData'ya ekle
    if (file && file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        formData.append(`file`, file[i]);
      }
    }
    try {
      const storedToken = localStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Dosyaları yükle (eğer varsa)
      if (file && file.length > 0) {
        await api.post(
          `/api/FileProcess/UploadFiles/UploadFiles?customerID=${customerID}&type=110&relationID=${params.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${storedToken}`,
            },
          }
        );
      }

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Dosyalar yüklendi !",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.error("Hata alındı: ", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${error}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  return (
    <>
      <Header />
      <div className="flex">
      <Sidebars />
      {loading ? (
        <div style={{
          display: "grid",
          placeItems: "center",
          height: "100vh",
          width:"100vw"
        }}>
          <MoonLoader
            color={"blue"}
            loading={loading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
            speedMultiplier={0.5}
          />
        </div>
      ) : (
        <div
          style={{ height: "100vh", overflowY: "scroll", width:"100%"}}
          className="flex flex-col gap-20 items-center mx-auto"
        >
          <div className="flex justify-center mt-12 gap-10">
            <div
              style={{ minWidth: "40rem", maxWidth: "40rem" }}
              className="bg-white max-w-3xl shadow overflow-hidden sm:rounded-lg"
            >
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {data?.title}
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  {data?.name}
                </p>
              </div>
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Atayan Kişi
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {newData?.userName}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Başlangıç Tarihi
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {formatDate(data?.startDate)}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Bitiş Tarihi
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {formatDate(data?.endDate)}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Görev Zorluğu
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <div className="flex gap-2">
                        {[...Array(10)].map((star, index) => {
                          const currentRating = index + 1;

                          return (
                            <label key={currentRating}>
                              <input
                                style={{ display: "none" }}
                                type="radio"
                                name="rating"
                                value={currentRating}
                              />
                              <FaStar
                                size={20}
                                color={
                                  currentRating <= (hover || rating)
                                    ? "#ffc107"
                                    : "#e4e5e9"
                                }
                              />
                            </label>
                          );
                        })}
                      </div>
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Yüksek Öncelikli
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {data?.highPriority === true ? "Evet" : "Hayır"}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Açıklama
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {data?.description}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <div
              style={{ minWidth: "40rem", maxWidth: "60rem" }}
              className="relative overflow-x-auto shadow-md sm:rounded-lg w-96 overflow-y-auto"
            >
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Alt Görevler
                    </th>
                    <th scope="col" className="px-6 py-3 float-right">
                      İslemler
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {taskTodo?.map((singleService, index) => (
                    <tr key={index} className="bg-white border-b">
                      <td className="px-6 py-4">
                        <p
                          style={{
                            textDecoration: singleService.isFinished
                              ? "line-through"
                              : "none",
                          }}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        >
                          {singleService.name}
                        </p>
                      </td>
                      {singleService.isFinished ? (
                        <td className="px-6 py-4 float-right">
                          <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full m-5"
                            onClick={() => handleCheck(index)}
                          >
                            <i className="fa-regular fa-circle-xmark"></i>
                          </button>
                        </td>
                      ) : (
                        <td className="px-6 py-4 float-right">
                          <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5"
                            onClick={() => handleCheck(index)}
                          >
                            <i className="fa-solid fa-check"></i>
                          </button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
              <button
                type="button"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5"
                onClick={handleSave}
              >
                Kaydet
                <i className="fa-regular fa-floppy-disk ml-2"></i>
              </button>
            </div>
          </div>
          <div style={{ width: "80rem" }}>
            <Splide
              options={{
                autoWidth: true,
                gap: 100,
                pagination: false,
                padding: 100,
              }}
              aria-label="My Favorite Images"
            >
              {dataImages?.map((item, index) => {
                const imageName = item.split("/").pop();
                const altText = imageName.split("_").pop().split(".")[0];
                // Resim URL'si kontrolü
                const isImage = /\.(jpg|jpeg|png|gif)$/i.test(item);
                return (
                  <SplideSlide key={index}>
                    {isImage ? (
                      <Link to={item}>
                        <img
                          style={{ maxWidth: "200px", cursor: "pointer" }}
                          src={item}
                          alt={`Image ${index + 1}`}
                        />
                      </Link>
                    ) : (
                      <Link to={item}>
                        <img
                          style={{ maxWidth: "100px", cursor: "pointer" }}
                          src={`${doc}`}
                        />
                      </Link>
                    )}
                    <p className="text-center font-medium">{altText}</p>
                  </SplideSlide>
                );
              })}
            </Splide>
          </div>
          <div className="flex items-center" style={{ width: "80rem" }}>
            <input
              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none"
              id="multiple_files"
              type="file"
              multiple
              onChange={handleChangeFile}
            />
            <button
              type="button"
              className="bg-gray-900  hover:bg-gray-700  text-white font-bold py-2 px-4 rounded-xl"
              onClick={() => handleDocSubmit()}
            >
              Yükle
            </button>
          </div>
          <div style={{ width: "80rem" }}>
            <label
              htmlFor="taskStatus"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Tamamlanma Durumu :
            </label>
            <Select
              value={selectedTypeStatus}
              onChange={(e) => handleChangeStatus(e)}
              options={taskStatus}
              id="taskStatus"
              maxMenuHeight={200}
            />
          </div>

          <div
            style={{ width: "80rem", minHeight: "16rem", marginBottom: "60px" }}
            className="bg-slate-50 pt-8 pb-6 flex flex-col gap-4 items-start py-3 px-3 rounded-2xl shadow-md overflow-y-auto max-h-96"
          >
            {newTaskComments && newTaskComments.length > 0 ? (
              newTaskComments.map((item) => (
                <div
                  key={item.viewIndex}
                  className="bg-white py-3 px-5 rounded-full"
                >
                  <h1 className="text-blue-600 font-medium">
                    {item?.userName}
                    <span className="text-slate-400 font-normal text-sm ml-1">
                      {formatDateComment(item.insertDateTime)}
                    </span>
                  </h1>
                  <p>{item.description}</p>
                </div>
              ))
            ) : (
              <h1 className="w-full text-center text-gray-400 font-medium text-2xl">
                Henüz yapılan bir yorum yok.
              </h1>
            )}

            <form
              onSubmit={handleSubmit}
              className="flex flex-row items-start w-full gap-3 mt-auto"
            >
              <input
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Yorum Ekle..."
                className="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                type="text"
              />
              <button
                disabled={!description || isAddingComment}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full"
                type="submit"
              >
                {isAddingComment ? "..." : "Ekle"}
              </button>
            </form>
          </div>
        </div>
      )}
      </div>
    </>
  );
};

export default TasksByAssignedUserDetail;
