import React, { useEffect, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Calendar,
  momentLocalizer,
  dateFnsLocalizer,
} from "react-big-calendar";
import moment from "moment";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import { useNavigate } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import "moment/locale/tr";
import tr from "date-fns/locale/tr";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import "./calendar.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);

const Calendars = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [assignedUser, setAssignedUser] = useState([]);
  const [myMeetings, setMyMeetings] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken1 = localStorage.getItem("token");
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);

    api
      .get(
        `/api/Task/GetTasksByAssignedUser?customerID=${parsedObject.country}&userID=${parsedObject.nameidentifier}`
      )
      .then((res) => {
        const formattedTasks = res.data.map((task) => ({
          title: task.name,
          start: moment(task.startDate).toDate(),
          end: moment(task.endDate).toDate(),
          id: task.taskID,
          color: "#94a0df",
        }));
        setAssignedUser(formattedTasks);
      })
      .catch((err) => console.log(err.data))
      .finally(() => setLoading(false));

    const fetchData = async () => {
      try {
        // Axios isteği yapılandırma
        const response = await api
          .get(
            `/api/Meeting/GetMeetingsForUser?customerID=${parsedObject.country}`,
            {
              headers: {
                Authorization: `Bearer ${storedToken1}`,
              },
            }
          )
          .then((res) => {
            const formattedMyMeetings = res.data.map((meeting) => ({
              title: meeting.name,
              type: meeting.type,
              name: meeting.title,
              description: meeting.description,
              id: meeting.meetingID,
              start: moment(meeting.startDate).toDate(),
              end: moment(meeting.startDate)
                .add(meeting.duration, "minutes")
                .toDate(), // duration ekleme
              color: "#e67c73",
            }));
            setMyMeetings(formattedMyMeetings);
          });

        setData(response.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false); // İstek tamamlandığında setLoading(false) çağrısı ekleniyor.
      }
    };

    fetchData();
  }, []);

  const messages = {
    allDay: "Tüm gün",
    previous: "Önceki",
    next: "Sonraki",
    today: "Bugün",
    month: "Ay",
    week: "Hafta",
    day: "Gün",
    agenda: "Ajanda",
    date: "Tarih",
    time: "Saat",
    event: "Etkinlik",
    showMore: (total, meetings) => `+${total} daha`,
  };

  const locales = {
    tr: tr, // Türkçe dil dosyası
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const handleClick = (event) => {
    const isMeeting = myMeetings.some((meeting) => meeting.id === event.id);
    const isTask = assignedUser.some((task) => task.id === event.id);

    if (isMeeting) {
      // Eğer tıklanan etkinlik bir toplantı ise ilgili toplantının detayına git
      navigate(`../my-meetings`);
    } else if (isTask) {
      // Eğer tıklanan etkinlik bir görev ise ilgili görevin detayına git
      navigate(`../taskDetailByAssignedUser/${event.id}`);
    }
  };


  return (
    <>
      {token ? (
        <>
          <Header />
          <div className="flex">
            <Sidebars />
            {loading ? (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "100vh",
                  width:"100vw"
                }}
              >
                <MoonLoader
                  color={"blue"}
                  loading={loading}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={0.5}
                />
              </div>
            ) : (
              <div className="mx-auto" style={{ height: "80vh" }}>
                <Calendar
                  localizer={localizer}
                  culture="tr"
                  events={[...assignedUser, ...myMeetings]}
                  startAccessor="start"
                  endAccessor="end"
                  onDoubleClickEvent={handleClick}
                  style={{
                    marginTop: "50px",
                    width: "80vw",
                  }}
                  views={["month", "week", "day"]}
                  messages={messages}
                  selectable={true}
                  eventPropGetter={(event) => ({
                    style: {
                      backgroundColor: event.color,
                    },
                  })}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default Calendars;
