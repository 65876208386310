import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import Select from "react-select";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import MoonLoader from "react-spinners/MoonLoader";

const MachineEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [customerID, setCustomerID] = useState(Number());
  const [stockCards, setStockCards] = useState([]);
  const [machineGroups, setMachineGroups] = useState([]);
  const [machineStatusOptions, setMachineStatusOptions] = useState([]);
  const [selectedMachineStatus, setSelectedMachineStatus] = useState({
    value: 0,
    label: "Makine Durumunu Seçiniz...",
  });
  const [token, setToken] = useState(null);
  //FORM
  const [machineProductionItems, setMachineProductionItems] = useState([
    {
      stockCardID: Number(),
      productionCapacityPerHour: Number(),
      productionCapacityPerMinute: Number(),
      name: "",
      title: "",
      type: Number(),
      status: "",
      stockCard: { stockCardID: null, name: "" }, // Bu kısmı ekledik
    },
  ]);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [notes, setNotes] = useState("");
  const [machineGroupID, setMachineGroupID] = useState({});
  const [keywords, setKeywords] = useState([]);
  const [keywordsData, setKeywordsData] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);
    setCustomerID(parsedObject.country);

    api
      .get(
        `/api/Machine/Get?id=${params.id}&customerID=${parsedObject.country}`
      )
      .then((res) => {
        setData(res.data);
        setTitle(res.data.title);
        setName(res.data.name);
        setDescription(res.data.description);
        setNotes(res.data.notes);
        const dataKeyw = JSON.parse(res.data.keywords);
        const newData = dataKeyw.map((item) => ({
          value: item,
          label: item,
          __isNew__: true,
        }));
        setKeywords(newData);
        setMachineGroupID({
          value: res.data.machineGroupID,
          label: res.data.machineGroupName,
        });
        setMachineProductionItems(res.data.machineProductionItems);
      })
      .catch((err) => console.log(err.data));
    api
      .get(`/api/StockCard/GetStockCards?customerID=${parsedObject.country}`)
      .then((res) => {
        const dataStockCards = res.data;
        const newDataStockCards = dataStockCards?.map((item) => ({
          value: item.stockCardID,
          label: item.name,
        }));
        setStockCards(newDataStockCards);
      })
      .catch((err) => console.log(err.data));

    api
      .get(`/api/Machine/GetMachineGroups?customerID=${parsedObject.country}`)
      .then((res) => {
        const dataMachineGroups = res.data;
        const newDataMachineGroups = dataMachineGroups?.map((item) => ({
          value: item.machineGroupID,
          label: item.name,
        }));
        setMachineGroups(newDataMachineGroups);
      })
      .catch((err) => console.log(err.data));
    api
      .get(`/api/Common/GetParameters?parametername=MachineStatus`)
      .then((res) => {
        const dataMachinesStatus = res.data;
        const newDataMachineGroups = dataMachinesStatus?.map((item) => ({
          value: item.parameterID,
          label: item.parameterValue2,
        }));
        setMachineStatusOptions(newDataMachineGroups);
      })
      .catch((err) => console.log(err.data));

    api
      .get(`/api/Common/GetKeywords?customerID=${parsedObject.country}`)
      .then((res) => {
        const newDataKey = res.data?.map((item) => ({
          value: item,
          label: item,
        }));
        setKeywordsData(newDataKey);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  const handleServiceRemove = (index) => {
    const list = [...machineProductionItems];
    list.splice(index, 1);
    setMachineProductionItems(list);
  };

  const handleServiceAdd = () => {
    setMachineProductionItems([
      ...machineProductionItems,
      {
        stockCardID: Number(),
        productionCapacityPerHour: Number(),
        productionCapacityPerMinute: Number(),
        name: "",
        title: "",
        type: Number(),
        status: "",
        stockCard: { stockCardID: null, name: "" }, // Bu kısmı ekledik
      },
    ]);
  };

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...machineProductionItems];
    list[index][name] = value;
    setMachineProductionItems(list);
  };
  const handleSelectChange = (selectedOption, index) => {
    const list = [...machineProductionItems];
    list[index].stockCardID = selectedOption.value;
    list[index].stockCard.name = selectedOption.label;
    setMachineProductionItems(list);
  };

  const postData = {
    machineID: params.id,
    machineGroupID: machineGroupID?.value,
    customerID,
    name,
    title,
    type: 0,
    status: selectedMachineStatus.value,
    description,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
    notes,
    machineProductionItems: machineProductionItems.map((item) => ({
      machineProductionItemID: item?.machineProductionItemID,
      machineID: item?.machineID,
      stockCardID: item?.stockCardID,
      productionCapacityPerHour: item?.productionCapacityPerHour,
      productionCapacityPerMinute: item?.productionCapacityPerMinute,
    })),
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const storedToken = localStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Axios isteği yapılandırma
      await api.put("/api/Machine/Put/Put", postData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Makine güncellendi !",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/machines");
    } catch (error) {
      console.error("Hata alındı: ", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${error.response.data.errors.Notes}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data && machineStatusOptions) {
      const matchedID = machineStatusOptions.find(
        (item) => item.value === data.status
      );
      setSelectedMachineStatus({
        value: matchedID?.value,
        label: matchedID?.label,
      });
    }
  }, [data, machineStatusOptions]);

  return (
    <>
      {token ? (
        <>
          <Header />
          <div className="flex">
            <Sidebars />
            {loading ? (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "100vh",
                  width: "100vw",
                }}
              >
                <MoonLoader
                  color={"blue"}
                  loading={loading}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={0.5}
                />
              </div>
            ) : (
              <div className="m-12 w-full">
                <div className="mb-5">
                  <h1 className="text-3xl font-semibold text-blue-700">
                    Makine Güncelle
                  </h1>
                </div>
                <form
                  className="grid grid-cols-4 gap-7 items-center w-full"
                  onSubmit={handleSubmit}
                >
                  <div className="col-span-2">
                    <label
                      htmlFor="title"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Başlık :
                    </label>
                    <input
                      type="text"
                      id="title"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div className="col-span-2">
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Adı :
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col-span-2">
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Makine Grubu :
                    </label>
                    <Select
                      value={machineGroupID}
                      onChange={(e) => setMachineGroupID(e)}
                      options={machineGroups}
                      maxMenuHeight={200}
                    />
                  </div>
                  <div className="col-span-2">
                    <label
                      htmlFor="keywords"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Anahtar Kelimeler :
                    </label>
                    <CreatableSelect
                      isMulti
                      maxMenuHeight={200}
                      id="keywords"
                      onChange={(e) => setKeywords(e)}
                      options={keywordsData}
                      value={keywords}
                    />
                  </div>
                  <div className="col-span-4">
                    <label className="block mb-2 text-sm font-medium text-gray-900">
                      Durum :
                    </label>
                    <Select
                      maxMenuHeight={200}
                      options={machineStatusOptions}
                      value={selectedMachineStatus}
                      onChange={(e) => setSelectedMachineStatus(e)}
                    />
                  </div>

                  <div className="col-span-3">
                    <label
                      htmlFor="description"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Açıklama :
                    </label>
                    <textarea
                      id="description"
                      type="text"
                      className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      rows={4}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                  <div className="col-span-1">
                    <label
                      htmlFor="note"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Not :
                    </label>
                    <textarea
                      id="note"
                      type="text"
                      className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      rows={4}
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </div>

                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg mb-5 col-span-4 mt-12">
                    <table className="w-full text-sm text-left text-gray-500">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            Ürün
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Saatlik Üretim Adedi
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Dakikalık Üretim Adedi
                          </th>
                          <th scope="col" className="px-6 py-3">
                            İşlem
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {machineProductionItems.map((singleService, index) => (
                          <tr key={index} className="bg-white border-b">
                            <td className="px-6 py-4">
                              <div>
                                <Select
                                  value={{
                                    value: singleService?.stockCardID,
                                    label: singleService?.stockCard?.name,
                                  }}
                                  onChange={(e) => handleSelectChange(e, index)}
                                  options={stockCards}
                                  maxMenuHeight={200}
                                />
                              </div>
                            </td>

                            <td className="px-6 py-4">
                              <input
                                value={singleService.productionCapacityPerHour}
                                onChange={(e) => handleServiceChange(e, index)}
                                name="productionCapacityPerHour"
                                type="number"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                required
                              />
                            </td>
                            <td className="px-6 py-4">
                              <input
                                value={
                                  singleService.productionCapacityPerMinute
                                }
                                onChange={(e) => handleServiceChange(e, index)}
                                name="productionCapacityPerMinute"
                                type="number"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                required
                              />
                            </td>

                            <td className="px-6 py-4">
                              <button
                                onClick={() => handleServiceRemove(index)}
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full "
                              >
                                <i className="fa-regular fa-trash-can"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <button
                        onClick={handleServiceAdd}
                        type="button"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5"
                      >
                        Ekle
                        <i className="fa-solid fa-plus ml-2"></i>
                      </button>
                    </table>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      gap: "20px",
                    }}
                    className="col-span-4"
                  >
                    <button
                      type="submit"
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5"
                    >
                      Kaydet
                      <i className="fa-regular fa-floppy-disk ml-2"></i>
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default MachineEdit;
