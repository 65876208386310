import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import Select from "react-select";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import left from "../../assets/images/left-click.png";
import right from "../../assets/images/right-click.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import { setHours, setMinutes } from "date-fns";
import CreatableSelect from "react-select/creatable";
import Switch from "react-switch";
import MoonLoader from "react-spinners/MoonLoader";
import { FaStar } from "react-icons/fa";

const TaskAdd = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [customerID, setCustomerID] = useState(Number());
  const [userID, setUserID] = useState(Number());
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);

  //FORM
  const [startDate, setStartDate] = useState(
    setHours(setMinutes(new Date(), 0), 9)
  );
  const [endDate, setEendDate] = useState(
    setHours(setMinutes(new Date(), 0), 9)
  );
  const [accounts, setAccounts] = useState([]);
  const [accountID, setAccountID] = useState([]);
  const [highPriority, setHighPriority] = useState(false);
  const [customerKey, setCustomerKey] = useState(null);
  const [keywords, setKeywords] = useState([]);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [projects, setProjects] = useState([]);
  const [projectID, setProjectID] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [taskType, setTaskType] = useState([]);
  const [selectedTaskType, setSelectedTaskType] = useState({});
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);

  //ASSIGNED USERS MODULE
  const [data, setData] = useState([]);
  const [assignedModules, setAssignedModules] = useState([]);
  const [preAssignedModules, setPreAssignedModules] = useState([]);
  const [selectedItemData, setSelectedItemData] = useState(null);

  //TODOS
  const [taskTodos, setTaskTodos] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);
    setCustomerID(parsedObject.country);
    setUserID(parsedObject.nameidentifier);

    api
      .get(
        `/api/Task/GetSubUsers?customerID=${parsedObject.country}&userID=${parsedObject.nameidentifier}`
      )
      .then((res) => setUsers(res.data))
      .catch((err) => console.error(err.data));

    api
      .get(`/api/Project/GetProjects?customerID=${parsedObject.country}`)
      .then((res) => {
        const dataProject = res.data;
        const newDataProject = dataProject.map((item) => ({
          value: item.projectID,
          label: item.name,
        }));
        setProjects(newDataProject);
      })
      .catch((err) => console.error(err.data));

    api
      .get(`/api/Common/GetKeywords?customerID=${parsedObject.country}`)
      .then((res) => {
        const newData = res.data?.map((item) => ({
          value: item,
          label: item,
        }));
        setCustomerKey(newData);
      })
      .catch((err) => console.log(err.data));

    api
      .get(`/api/Account/GetAccounts?customerID=${parsedObject.country}`)
      .then((res) => {
        const dataAccount = res.data;
        const newDataAccount = dataAccount.map((item) => ({
          value: item.accountID,
          label: item.name,
        }));
        setAccounts(newDataAccount);
      })
      .catch((err) => {
        console.error(err.data);
      });

    api
      .get(`/api/Task/GetTasks?customerID=${parsedObject.country}`)
      .then((res) => {
        const dataTasks = res.data;
        const newDataTasks = dataTasks.map((item) => ({
          value: item.taskID,
          label: item.name,
        }));
        setTasks(newDataTasks);
      })
      .catch((err) => console.log(err.data));

    api
      .get(`/api/Common/GetLocations?customerID=${parsedObject.country}`)
      .then((res) => {
        let dataLocations = res.data;
        let newDataLocations = dataLocations.map((item) => ({
          value: item,
          label: item,
        }));
        setLocations(newDataLocations);
      })
      .catch((err) => console.log(err.data));
    api
      .get(`/api/Common/GetParameters?parametername=TaskType`)
      .then((res) => {
        let dataTaskType = res.data;
        let newDataTaskType = dataTaskType.map((item) => ({
          value: item.parameterID,
          label: item.parameterValue2,
        }));
        setTaskType(newDataTaskType);
      })
      .catch((err) => console.log(err.data));
  }, []);

  /// DATE OPTIONS
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  /// ASSIGNED USERS MODEL OPTIONS
  const moveItemToAssigned = () => {
    if (selectedItemData && !assignedModules.includes(selectedItemData)) {
      const yeniOge = {
        ...selectedItemData,
        customerID,
      };

      setAssignedModules((oncekiModuller) => [...oncekiModuller, yeniOge]);
      setData((oncekiData) =>
        oncekiData.filter((oge) => oge !== selectedItemData)
      );
      setSelectedItemData(null);
    }
  };

  const moveItemToAllModules = () => {
    if (selectedItemData && !data.includes(selectedItemData)) {
      setData((prevData) => [...prevData, selectedItemData]);
      setAssignedModules((prevModules) =>
        prevModules.filter((item) => item !== selectedItemData)
      );
      setSelectedItemData(null);
    }
  };

  // TODO OPTIONS
  const handleServiceChange = (e, index) => {
    const { value } = e.target;
    const list = [...taskTodos];
    list[index].name = value;
    setTaskTodos(list);
  };

  const handleServiceAdd = () => {
    setTaskTodos([
      ...taskTodos,
      {
        name: "",
        customerID,
        taskID: "",
        userID,
      },
    ]);
  };

  const handleServiceRemove = (index) => {
    const list = [...taskTodos];
    list.splice(index, 1);
    setTaskTodos(list);
  };

  const postData = {
    customerID,
    parentID: 0,
    projectID: projectID.value,
    userID,
    priorityTaskID: selectedTask.value,
    revisionTaskID: 0,
    revisionNumber: 0,
    revisionDescription: "",
    accountID: accountID.value,
    document: "",
    name,
    title,
    startDate,
    endDate,
    location: selectedLocation.label, ////////////
    highPriority,
    intensityPercent: 0,
    taskDifficulty: rating,
    type: selectedTaskType.value,
    status: 0,
    viewIndex: 0,
    description,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
    todo: taskTodos.map((item) => item),

    assignedUser: assignedModules.map((item) => ({
      customerID: item.customerID,
      userID: item.userID,
      taskID: "",
      status: 0,
      approvalUserID: 0,
      approvalDate: "",
      viewIndex: 0,
      type: 0,
    })),
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const storedToken = localStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Axios isteği yapılandırma
      await api.post("/api/Task/Post", postData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Görev oluşturuldu !",
        showConfirmButton: false,
        timer: 1500,
      });
      navigate("/tasks");
    } catch (error) {
      console.error("Hata alındı: ", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${error}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {token ? (
        <>
          <Header />
          <div className="flex">
            <Sidebars />
            {loading ? (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "100vh",
                  width: "100vw",
                }}
              >
                <MoonLoader
                  color={"blue"}
                  loading={loading}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={0.5}
                />
              </div>
            ) : (
              <div style={{ width: "80%" }} className="mt-12 mx-auto">
                <div className="mb-5">
                  <h1 className="text-3xl font-semibold text-blue-700 pb-12">
                    Görev Oluştur
                  </h1>
                </div>
                <form
                  onSubmit={handleSubmit}
                  className="grid grid-cols-4 gap-7 items-center w-full"
                >
                  <div className="col-span-2">
                    <label
                      htmlFor="title"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Başlık :
                    </label>
                    <input
                      type="text"
                      id="title"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div className="col-span-2">
                    <label
                      htmlFor="taskName"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Görev Adı :
                    </label>
                    <input
                      type="text"
                      id="taskName"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col-span-1">
                    <label
                      htmlFor="project"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Proje Seç :
                    </label>
                    <Select
                      id="project"
                      value={projectID}
                      onChange={(e) => setProjectID(e)}
                      options={projects}
                      maxMenuHeight={200}
                    />
                  </div>

                  <div className="col-span-1">
                    <label
                      htmlFor="account"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Cari Seç :
                    </label>
                    <Select
                      value={accountID}
                      onChange={(e) => setAccountID(e)}
                      options={accounts}
                      id="account"
                      maxMenuHeight={200}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="telephone"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Lokasyon :
                    </label>
                    <Select
                      value={selectedLocation}
                      onChange={(e) => setSelectedLocation(e)}
                      options={locations}
                      maxMenuHeight={200}
                    />
                  </div>
                  <div className="col-span-1">
                    <label
                      htmlFor="taskType"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Görev Tipi :
                    </label>
                    <Select
                      value={selectedTaskType}
                      onChange={(e) => setSelectedTaskType(e)}
                      options={taskType}
                      maxMenuHeight={200}
                    />
                  </div>

                  <div className="col-span-1">
                    <label
                      htmlFor="startDate"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Başlangıç Tarih :
                    </label>
                    <DatePicker
                      id="startDate"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showTimeSelect
                      filterTime={filterPassedTime}
                      locale={tr}
                      dateFormat="MMMM d, yyyy h:mm aa"
                    />
                  </div>
                  <div className="col-span-1">
                    <label
                      htmlFor="endDate"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Bitiş Tarih :
                    </label>
                    <DatePicker
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      selected={endDate}
                      onChange={(date) => setEendDate(date)}
                      showTimeSelect
                      locale={tr}
                      filterTime={filterPassedTime}
                      dateFormat="MMMM d, yyyy h:mm aa"
                    />
                  </div>

                  <div className="col-span-1">
                    <label
                      htmlFor="priorityTaskID"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Öncelikli Görev :
                    </label>
                    <Select
                      value={selectedTask}
                      onChange={(e) => setSelectedTask(e)}
                      options={tasks}
                      id="priorityTaskID"
                      maxMenuHeight={200}
                    />
                  </div>
                  <div className="col-span-1">
                    <label
                      htmlFor="taskName"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Anahtar Kelimeler :
                    </label>
                    <CreatableSelect
                      onChange={(e) => setKeywords(e)}
                      options={customerKey}
                      isMulti
                      maxMenuHeight={200}
                    />
                  </div>

                  <div className="col-span-1">
                    <label
                      htmlFor="description"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Görev Zorluğu :
                    </label>
                    <div className="flex gap-2">
                      {[...Array(10)].map((star, index) => {
                        const currentRating = index + 1;

                        return (
                          <label key={currentRating}>
                            <input
                              style={{ display: "none" }}
                              type="radio"
                              name="rating"
                              value={currentRating}
                              onClick={() => setRating(currentRating)}
                            />
                            <FaStar
                              style={{ cursor: "pointer" }}
                              size={30}
                              color={
                                currentRating <= (hover || rating)
                                  ? "#ffc107"
                                  : "#e4e5e9"
                              }
                              onMouseEnter={() => setHover(currentRating)}
                              onMouseLeave={() => setHover(null)}
                            />
                          </label>
                        );
                      })}
                    </div>
                  </div>

                  <div className="flex flex-col justify-center items-center gap-2">
                    <label
                      htmlFor="highPriority"
                      className="block text-lg font-medium text-gray-900"
                    >
                      Yüksek Öncelikli :
                    </label>
                    <Switch
                      checked={highPriority}
                      onChange={(e) => setHighPriority(e)}
                    />
                  </div>
                  <div className="col-span-2">
                    <label
                      htmlFor="description"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Açıklama :
                    </label>
                    <textarea
                      id="description"
                      type="text"
                      className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                      rows={4}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>

                  <div className="flex gap-16 h-full mt-16 col-span-4">
                    <div
                      style={{
                        overflowY: "scroll",
                        maxHeight: "500px",
                        minWidth: "300px",
                        minHeight: "300px",
                      }}
                      className=" origin-top-right right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none col-span-3"
                    >
                      <h1 className="px-4 py-2 text-xl font-medium text-red-500">
                        Tüm Kullanıcılar
                      </h1>
                      <div
                        className="py-1"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        {users
                          .filter(
                            (item) =>
                              !preAssignedModules.some(
                                (assignedItem) =>
                                  assignedItem.userID === item.userID
                              ) &&
                              !assignedModules.some(
                                (assignedItem) =>
                                  assignedItem.userID === item.userID
                              )
                          )
                          .map((item, index) => (
                            <button
                              key={index}
                              className="block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                              role="menuitem"
                              type="button"
                              onClick={() => setSelectedItemData(item)}
                            >
                              {item.userName}
                            </button>
                          ))}
                      </div>
                    </div>
                    <div className="flex flex-col gap-7 justify-center">
                      <button
                        onClick={moveItemToAssigned}
                        type="button"
                        className="w-12"
                      >
                        <img src={right} alt="" />
                      </button>
                      <button
                        onClick={moveItemToAllModules}
                        type="button"
                        className="w-12"
                      >
                        <img src={left} alt="" />
                      </button>
                    </div>
                    <div
                      style={{
                        overflowY: "scroll",
                        maxHeight: "500px",
                        minWidth: "300px",
                      }}
                      className=" origin-top-right right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <h1 className="px-4 py-2 text-xl font-medium text-blue-500">
                        Atanacak Kullanıcılar
                      </h1>
                      <div
                        className="py-1"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        {assignedModules.map((item) => (
                          <button
                            key={item.userID}
                            type="button"
                            className="block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                            role="menuitem"
                            onClick={() => setSelectedItemData(item)}
                          >
                            {item.userName}
                          </button>
                        ))}
                      </div>
                    </div>

                    <div
                      style={{ maxHeight: "500px" }}
                      className="relative overflow-x-auto shadow-md sm:rounded-lg mb-5 w-full overflow-y-auto"
                    >
                      <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                          <tr>
                            <th scope="col" className="px-6 py-3">
                              Yapılacaklar Listesi
                            </th>
                            <th scope="col" className="px-6 py-3">
                              İslemler
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {taskTodos.map((singleService, index) => (
                            <tr key={index} className="bg-white border-b">
                              <td className="px-6 py-4">
                                <input
                                  name="taskTodo"
                                  type="text"
                                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                  required
                                  onChange={(e) =>
                                    handleServiceChange(e, index)
                                  }
                                  value={singleService.name}
                                />
                              </td>
                              <td className="px-6 py-4">
                                <button
                                  onClick={() => handleServiceRemove(index)}
                                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full m-5"
                                >
                                  <i className="fa-regular fa-trash-can"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <button
                          type="button"
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5 outline-none"
                          onClick={handleServiceAdd}
                        >
                          Ekle
                          <i className="fa-solid fa-plus ml-2"></i>
                        </button>
                      </table>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      gap: "20px",
                    }}
                    className="col-span-4"
                  >
                    <button
                      type="submit"
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5"
                    >
                      Kaydet
                      <i className="fa-regular fa-floppy-disk ml-2"></i>
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default TaskAdd;
