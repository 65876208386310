import React, { useEffect, useState } from "react";
import api from "../../api/api";

const TaskGraph = () => {
const [data,setData] = useState([])
    
  useEffect(() => {
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);

    api
      .get(
        `/api/Report/CalculateTaskGraph?customerID=${parsedObject.country}&startDate=2024-3-1&endDate=2024-3-31`
      )
      .then((res) => {
        setData(res.data)
      })
      .catch((err) => console.log(err));
  }, []);

  console.log("data",data);
  return (
     <div className="w-full bg-white border border-gray-200 rounded-lg shadow sm:p-10">
   
      <div className="flow-root">
        <ul role="list" className="divide-y divide-gray-200">
          <li className="py-3 sm:py-4">
            <div className="flex items-center">
              <div className="flex-1 min-w-0 ms-4">
                <p className="text-base font-semibold text-yellow-600 truncate">
                  Aktif Görevler
                </p>
              </div>
              <div className="inline-flex items-center text-base font-semibold text-yellow-600">
                {data[0]?.totalTasks}
              </div>
            </div>
          </li>
          <li className="py-3 sm:py-4">
            <div className="flex items-center">
              <div className="flex-1 min-w-0 ms-4">
                <p className="text-base font-semibold text-blue-600 truncate">
                  Zamanında Biten Görevler
                </p>
              </div>
              <div className="inline-flex items-center text-base font-semibold text-blue-600">
                {data[0]?.completedOnTimeTasks}
              </div>
            </div>
          </li>
          <li className="py-3 sm:py-4">
            <div className="flex items-center">
              <div className="flex-1 min-w-0 ms-4">
                <p className="text-base font-semibold text-red-600 truncate">
                  Geciken Görevler
                </p>
              </div>
              <div className="inline-flex items-center text-base font-semibold text-red-600">
                {data[0]?.completedLateTasks}
              </div>
            </div>
          </li>
          <li className="py-3 sm:py-4">
            <div className="flex items-center">
              <div className="flex-1 min-w-0 ms-4">
                <p className="text-base font-semibold text-green-600 truncate">
                  Tamamlanan Görevler
                </p>
              </div>
              <div className="inline-flex items-center text-base font-semibold text-green-600">
                {data[0]?.completedTasks}
              </div>
            </div>
          </li>
          <li className="py-3 sm:py-4">
            <div className="flex items-center">
              <div className="flex-1 min-w-0 ms-4">
                <p className="text-base font-semibold text-gray-500 truncate">
                  Tamamlanan Görev Yüzdesi
                </p>
              </div>
              <div className="inline-flex items-center text-base font-semibold text-gray-500">
                %{data[0]?.completionPercentage}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
   
  );
};

export default TaskGraph;
