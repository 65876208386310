import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import "../demands/demands.css";
import { Link, useNavigate } from "react-router-dom";
import api from "../../api/api";
import MoonLoader from "react-spinners/MoonLoader";

const MyMeetings = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [meetingTypes, setMeetingTypes] = useState([]);
  const [newData, setNewData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken1 = localStorage.getItem("token");
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);
    api
      .get("/api/Common/GetParameters?parametername=MeetingType")
      .then((res) => setMeetingTypes(res.data))
      .catch((err) => console.error(err.data));

    const fetchData = async () => {
      try {
        // Axios isteği yapılandırma
        const response = await api.get(
          `/api/Meeting/GetMeetingsForUser?customerID=${parsedObject.country}`,
          {
            headers: {
              Authorization: `Bearer ${storedToken1}`,
            },
          }
        );

        setData(response.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false); // İstek tamamlandığında setLoading(false) çağrısı ekleniyor.
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (id) => {
    try {
      const storedToken = localStorage.getItem("token");
      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      // Axios isteği yapılandırma
      await api.put(
        `/api/Meeting/PutUserJoin/PutUserJoin?meetingID=${id}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );

      navigate(`/meetingLogin/${id}`);
    } catch (error) {
      console.error("Hata alındı: ", error);
    }
  };

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();

    // Saate 3 ekleyerek hesaplama yapalım
    let saat = tarihObjesi.getHours();

    // Eğer saat 24'ten büyükse 24'e göre modunu alalım
    saat = saat % 24;

    // Saati string formatına çevirirken padding işlemi yapalım
    const saatString = saat.toString().padStart(2, "0");
    const dakika = tarihObjesi.getMinutes().toString().padStart(2, "0");

    return `${gun}/${ay}/${yil} - ${saatString}.${dakika} `;
  };

  const getMeetingStatus = (startDate) => {
    const meetingDate = new Date(startDate); // Toplantı başlangıç tarihi
    const currentDate = new Date(); // Şu anki tarih ve saat

    // Eğer toplantı başlangıç tarihi şu andan önceyse "başlayacak", değilse "başladı"
    return meetingDate > currentDate ? "Başlayacak" : "Başladı";
  };

  useEffect(() => {
    if (meetingTypes && data) {
      // Veri ve kullanıcılar yüklendiyse eşleşme işlemini yap
      const matchedUsers = data.map((item) => {
        const matchedUser = meetingTypes.find(
          (type) => type.parameterID === item.type
        );
        if (matchedUser) {
          return {
            ...item,
            typeName: matchedUser.parameterValue2,
            meetStatus: getMeetingStatus(item.startDate),
          };
        }
        return item;
      });
      // Eşleşmiş kullanıcıları içeren veriyi set et
      setNewData(matchedUsers);
    }
  }, [meetingTypes, data]);


  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <div
            style={{ 
              display: "grid",
              placeItems: "center",
              height: "100vh",
              width:"100vw"
            }}
          >
            <MoonLoader
              color={"blue"}
              loading={loading}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
              speedMultiplier={0.5}
            />
          </div>
        ) : (
          <div className="m-12 w-full">
            <div className="add-button">
              <div className="mb-5">
                <h1 className="text-3xl font-semibold text-blue-700">
                  Toplantılarım
                </h1>
              </div>
              <div className="flex">
                <input
                  className="w-96 block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                  type="text"
                  placeholder="Toplantı ara.."
                  onChange={(e) => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
              </div>
            </div>

            <div
              style={{ height: "85vh" }}
              className="relative overflow-x-auto shadow-md sm:rounded-lg"
            >
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 sticky top-0">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Toplantı Adı
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Başlama Saati
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Tipi
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Durumu
                    </th>
                    <th scope="col" className="px-6 py-3">
                      İslemler
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {newData
                    ?.filter((item) =>
                      item.name
                        ?.toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    )
                    .map((item, index) => (
                      <tr
                        key={index}
                        className="bg-white border-b hover:bg-gray-50"
                      >
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                        >
                          {item?.name}
                        </th>
                        <td className="px-6 py-4">
                          {formatDate(item?.startDate)}
                        </td>
                        <td className="px-6 py-4">{item?.typeName}</td>

                        {item?.meetStatus === "Başlayacak" ? (
                          <td className="px-6 py-4 text-green-500 font-semibold">
                            {item?.meetStatus}
                          </td>
                        ) : (
                          <td className="px-6 py-4 text-yellow-500 font-semibold">
                            {item?.meetStatus}
                          </td>
                        )}
                        <td className="px-6 py-4">
                          <button
                            onClick={() => handleSubmit(item.meetingID)}
                            className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-1.5 focus:outline-none"
                          >
                            Katıl
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MyMeetings;
