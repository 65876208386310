import React, { useEffect, useState } from "react";
import Select from "react-select";
import api from "../../api/api";
import MoonLoader from "react-spinners/MoonLoader";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const TaskTransferModal = ({ onCancel = () => {} , taskID}) => {
    const navigate = useNavigate()
    const [data,setData] = useState([])
    const [selectedUser,setSelectedUser] = useState({})
    const [loading,setLoading] = useState(true)
    const [userID,setUserID] = useState(null)
    
  useEffect(() => {
      const storedToken = window.localStorage.getItem("userData");
      const parsed = JSON.parse(storedToken);
      function parseClaimsObject(parsed) {
          const result = {};
          
          for (const key in parsed) {
              if (key.endsWith("/country")) {
                  result.country = parsed[key];
                } else if (key.endsWith("/emailaddress")) {
                    result.emailaddress = parsed[key];
                } else if (key.endsWith("/name")) {
                    result.name = parsed[key];
                } else if (key.endsWith("/nameidentifier")) {
                    result.nameidentifier = parsed[key];
                }
            }
            
            return result;
        }
        const parsedObject = parseClaimsObject(parsed);
        setUserID(parsedObject.nameidentifier)

    api
      .get(`/api/User/GetUsers?customerID=${parsedObject.country}`)
      .then((res) =>{
        const dataUser = res.data;
        const newData = dataUser?.map((item) => ({
          value: item.userID,
          label: item.userName,
        }));
        setData(newData);
      })
      .catch((err) => console.log(err.data))
      .finally(() => setLoading(false));
  }, []);

  const postData = {
   taskID,
   transferringUserID: Number(userID),
   transferredUserID: selectedUser.value

  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
    
      // Axios isteği yapılandırma
      await api.put("/api/Task/PutTaskTransfer", postData);

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Görev transfer edildi !",
        showConfirmButton: false,
        timer: 1500,
      });
      onCancel()
    } catch (error) {
      console.error("Hata alındı: ", error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: `${error}`,
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
   <>
   {loading ? (
           <div style={{ 
            display: "grid",
            placeItems: "center",
            height: "100vh",
            width:"100vw"
           }}>
           <MoonLoader
             color={"blue"}
             loading={loading}
             size={100}
             aria-label="Loading Spinner"
             data-testid="loader"
             speedMultiplier={0.5}
           />
         </div>
   ) : (
       <div
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0,0,0,0.3)",
          zIndex: "1000",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "1000",
        }}
      >
        <div
          style={{
            width: "60%",
            padding: "30px 20px",
            backgroundColor: "#fff",
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100%" }}>
            <label
              htmlFor="title"
              className="block mb-2 text-lg font-medium text-gray-900"
            >
              Transfer edilecek kullanıcıyı seçiniz...
            </label>
            <Select value={selectedUser} onChange={(e)=>setSelectedUser(e)} maxMenuHeight={200}  options={data}/>
          </div>
          <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
            <button
              onClick={onCancel}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full m-5"
            >
              Vazgeç
            </button>
            <button
              type="button"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5"
              onClick={(e)=>handleSubmit(e)}
            >
              Onayla
            </button>
          </div>
        </div>
      </div>

   )}
   </>
  );
};

export default TaskTransferModal;
