import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import { Link, useParams } from "react-router-dom";
import api from "../../api/api";
import MoonLoader from "react-spinners/MoonLoader";

const DepartmentDetail = () => {
  const params = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [token, setToken] = useState(null);
  const [departmentData, setDepartmentData] = useState([]);
  const [departmentUser, setDepartmentUser] = useState([]);
  const [userOptions, setUserOptions] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);

    api
      .get(`/api/User/GetUsers?customerID=${parsedObject.country}`)
      .then((res) => {
        setUsers(res.data);
        const data = res.data;
        const newData = data.map((item) => ({
          value: item.userID,
          label: item.userName,
        }));
        setUserOptions(newData);
      })
      .catch((err) => console.error(err.data));
    api
      .get(
        `/api/Department/Get?id=${params.id}&customerID=${parsedObject.country}`
      )
      .then((res) => {
        setDepartmentData(res.data);
        setData(res.data);
      })
      .catch((err) => console.log(err.data))
      .finally(() => setLoading(false));
  }, []);



  useEffect(() => {
    if (users && departmentData) {
      const matchedUser = users.find(
        (item) => item.userID === departmentData.userID
      );
      setDepartmentUser({
        value: matchedUser?.userID,
        label: matchedUser?.userName,
      });
    }
  }, [users, departmentData]);


  return (
    <>
      <Header />
      <div className="flex">
        <Sidebars />
        {loading ? (
          <div
            style={{ 
              display: "grid",
              placeItems: "center",
              height: "100vh",
              width:"100vw"
            }}
          >
            <MoonLoader
              color={"blue"}
              loading={loading}
              size={100}
              aria-label="Loading Spinner"
              data-testid="loader"
              speedMultiplier={0.5}
            />
          </div>
        ) : (
          <div className="flex justify-center items-center h-screen flex-col gap-10 mx-auto">
            <div
              style={{
                minWidth: "60rem",
                maxWidth: "60rem",
              }}
              className="bg-white max-w-3xl shadow overflow-hidden sm:rounded-lg "
            >
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {data?.name}
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  {data?.title}
                </p>
              </div>
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <label
                      htmlFor="userSelect"
                      className="text-sm font-medium text-gray-500"
                    >
                      Atanan Kişi
                    </label>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {departmentUser?.label}
                    </dd>
                  </div>

                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Açıklama
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {data?.description}
                    </dd>
                  </div>
                </dl>
              </div>
              <Link to={"/departments"}>
                <button
                  type="button"
                  className="bg-green-600 hover:bg-green-700 float-left text-white font-bold py-2 px-4 rounded-full m-5"
                >
                  <i className="fa-solid fa-arrow-left mr-2"></i>
                  Geri Dön
                </button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DepartmentDetail;
