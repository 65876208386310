import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import MoonLoader from "react-spinners/MoonLoader";
import { useNavigate } from "react-router-dom";

const Notifications = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);

    api
      .get(
        `/api/UserNotification/GetNotifications?userID=${parsedObject.nameidentifier}&customerID=${parsedObject.country}`
      )
      .then((res) => setData(res.data))
      .catch((err) => console.log(err.data))
      .finally(() => setLoading(false));
  }, []);

  const handleClick = (notificationID, customerID, linkTo) => {
    api
      .put(
        `/api/UserNotification/PutNotificationStatus/PutNotificationStatus?notificationID=${notificationID}&customerID=${customerID}`
      )
      .then((res) => {
        navigate(linkTo);
      });
  };
  return (
    <>
      {token ? (
        <>
          <Header />
          <div className="flex">
            <Sidebars />
            {loading ? (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "100vh",
                  width:"100vw"
                }}
              >
                <MoonLoader
                  color={"blue"}
                  loading={loading}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={0.5}
                />
              </div>
            ) : (
              <div className="m-12 w-full">
                <div className="add-button">
                  <div className="flex">
                    <input
                      className="w-96 block  p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                      type="text"
                      placeholder="Bildirim ara.."
                      onChange={(e) => setSearchTerm(e.target.value)}
                      value={searchTerm}
                    />
                  </div>
                </div>

                <div
                  style={{ height: "85vh" }}
                  className="relative overflow-x-auto shadow-md sm:rounded-lg"
                >
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-100 top-0 sticky">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Bildirim
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Tarih
                        </th>
                        <th scope="col" className="px-6 py-3">
                          Saat
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data
                        ?.filter((item) =>
                          item.notification
                            ?.toLowerCase()
                            .includes(searchTerm.toLowerCase())
                        )
                        .map((item, index) => {
                          const tarihNesnesi = new Date(item.insertDate);
                          const gun = tarihNesnesi.getDate();
                          const ay = (tarihNesnesi.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const yil = tarihNesnesi.getFullYear().toString();
                          const saat = tarihNesnesi
                            .getHours()
                            .toString()
                            .padStart(2, "0");
                          const dakika = tarihNesnesi
                            .getMinutes()
                            .toString()
                            .padStart(2, "0");

                          // Tarihi istediğiniz formatta oluşturun
                          const formatliTarih = `${gun}/${ay}/${yil}`;
                          const newClock = `${saat}:${dakika}`;
                          const rowClass =
                            item.status === 1 ? "bg-gray-50" : "bg-white";

                          // switch case ile yönlendirme yapılıyor
                          let linkTo = "/notifications"; // Varsayılan değer

                          switch (item.type) {
                            case 122:
                              linkTo = `/demandEdit/${item.relationID}`;
                              break;
                            // Diğer type'lar için gerekli case'leri ekleyin
                            case 123:
                              linkTo = `/offerEdit/${item.relationID}`;
                              break;
                            case 124:
                              linkTo = `/orderEdit/${item.relationID}`;
                              break;
                            case 125:
                              linkTo = `/invoiceEdit/${item.relationID}`;
                              break;
                            case 126:
                              linkTo = `/projectEdit/${item.relationID}`;
                              break;
                            case 127:
                              linkTo = `/taskDetailByAssignedUser/${item.relationID}`;
                              break;
                            case 128:
                              linkTo = `/formBuilderSchemaDetail/${item.relationID}`;
                              break;
                            case 129:
                              linkTo = `/stockCardEdit/${item.relationID}`;
                              break;
                            case 130:
                              linkTo = `/workFlowEdit/${item.relationID}`;
                              break;

                            default:
                              break;
                          }
                          return (
                            <tr
                              key={index}
                              className={`border-b hover:bg-gray-50 cursor-pointer ${rowClass}`}
                              onClick={() =>
                                handleClick(
                                  item.notificationID,
                                  item.customerID,
                                  linkTo
                                )
                              }
                            >
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                              >
                                {item.notification}
                              </th>
                              <td className="px-6 py-4">{formatliTarih}</td>
                              <td className="px-6 py-4">{newClock}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default Notifications;
