import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import api from "../../api/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import CreatableSelect from "react-select/creatable";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import Select from "react-select";
import left from "../../assets/images/left-click.png";
import right from "../../assets/images/right-click.png";
import MoonLoader from "react-spinners/MoonLoader";

const ProjectEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  const [users, setUsers] = useState(null);
  const [selectedUser, setSelectedUser] = useState([]);
  const [customerID, setCustomerID] = useState(Number());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [editUser, setEditUser] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [name, setName] = useState("");
  const [customerKey, setCustomerKey] = useState(null);
  const [projectType, setProjectType] = useState(null);
  const [selectedProjectType, setSelectedProjectType] = useState([]);
  const [projectsData, setProjectsData] = useState([]);
  const [data, setData] = useState([]);

  const [statusData, setStatusData] = useState([]);
  const [statusType, setStatusType] = useState([]);
  const [selectedStatusType, setSelectedStatusType] = useState([]);

  const [file, setFile] = useState(null);
  const [projectID, setProjectID] = useState(null);

  //ASSIGNED USERS MODULE
  const [dataProject, setDataProject] = useState([]);
  const [assignedModules, setAssignedModules] = useState([]);
  const [preAssignedModules, setPreAssignedModules] = useState([]);
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [userModules, setUserModules] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);

    api
      .get(`/api/Common/GetKeywords?customerID=${parsedObject.country}`)
      .then((res) => {
        const newData = res.data?.map((item) => ({
          value: item,
          label: item,
        }));
        setCustomerKey(newData);
      })
      .catch((err) => console.log(err.data));

    api
      .get(`/api/User/GetUsers?customerID=${parsedObject.country}`)
      .then((res) => {
        setUserModules(res.data);
        const data = res.data;
        const newData = data.map((item) => ({
          value: item.userID,
          label: item.userName,
        }));
        setUsers(newData);
      })
      .catch((err) => console.log(err.data));

    api
      .get("/api/Common/GetParameters?parametername=ProjectType")
      .then((res) => {
        setProjectsData(res.data);
        const projectData = res.data;
        const newDataProject = projectData.map((item) => ({
          value: item.parameterID, //backend istenen
          label: item.parameterValue2, //ekrana basılan
        }));
        setProjectType(newDataProject);
      })
      .catch((err) => console.log(err.data));
    api
      .get("/api/Common/GetParameters?parametername=ProjectStatus")
      .then((res) => {
        setStatusData(res.data);
        const statusData = res.data;
        const newStatusData = statusData.map((item) => ({
          value: item.parameterID, //backend istenen
          label: item.parameterValue2, //ekrana basılan
        }));
        setStatusType(newStatusData);
      })
      .catch((err) => console.log(err.data));

    api
      .get(
        `/api/Project/Get?id=${params.id}&customerID=${parsedObject.country}`
      )
      .then((res) => {
        setData(res.data);
        setTitle(res.data.title);
        setName(res.data.name);
        setProjectID(res.data.projectID);
        setSelectedUser({
          label: res.data.user.userName,
          value: res.data.user.userID,
        });
        setCustomerID(res.data.customerID);
        setEditUser(res.data);
        setAssignedModules(res.data.project_Users);
        setStartDate(new Date(res.data.startDate));
        setEndDate(new Date(res.data.endDate));
        setDescription(res.data.description);
        setDescription(res.data.description);
        const data = JSON.parse(res.data.keywords);
        const newData = data.map((item) => ({
          value: item,
          label: item,
          __isNew__: true,
        }));
        setKeywords(newData);
      })
      .catch((err) => {
        console.log(err.data);
      })
      .finally(() => setLoading(false));
  }, []);

  /// ASSIGNED USERS MODEL OPTIONS
  const moveItemToAssigned = () => {
    if (selectedItemData && !assignedModules.includes(selectedItemData)) {
      const yeniOge = {
        assigmentID: null,
        customerID: selectedItemData?.customerID,
        projectID: selectedItemData?.projectID,
        userID: selectedItemData?.userID,
        userName: selectedItemData?.userName,
      };

      setAssignedModules((oncekiModuller) => [...oncekiModuller, yeniOge]);
      setDataProject((oncekiData) =>
        oncekiData.filter((oge) => oge !== selectedItemData)
      );
      setSelectedItemData(null);
    }
  };

  const moveItemToAllModules = () => {
    if (selectedItemData && !dataProject.includes(selectedItemData)) {
      setDataProject((prevDataProject) => [
        ...prevDataProject,
        selectedItemData,
      ]);
      setAssignedModules((prevModules) =>
        prevModules.filter((item) => item !== selectedItemData)
      );
      setSelectedItemData(null);
    }
  };

  useEffect(() => {
    if (data && projectsData && statusData) {
      const matchedType = projectsData.find(
        (item) => item.parameterID === data.type
      );
      const matchedStatus = statusData.find(
        (item) => item.parameterID === data.status
      );
      setSelectedProjectType({
        value: matchedType?.parameterID,
        label: matchedType?.parameterValue2,
      });
      setSelectedStatusType({
        value: matchedStatus?.parameterID,
        label: matchedStatus?.parameterValue2,
      });
    }
  }, [data, projectsData, statusData]);

  const handleChangeFile = (event) => {
    setFile(event.target.files);
  };

  const postData = {
    projectID: params.id,
    customerID: customerID,
    parentID: editUser?.parentID,
    userID: selectedUser.value,
    document: "",
    name,
    title,
    startDate: startDate,
    endDate: endDate,
    type: selectedProjectType.value,
    status: selectedStatusType.value,
    viewIndex: 0,
    description: description,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),

    assignedUsers: assignedModules.map((item) => ({
      assigmentID: item?.assigmentID,
      customerID: item.customerID,
      projectID: params.id,
      userID: item.userID,
    })),
  };


  useEffect(() => {
    if (userModules && data.project_Users) {
      const matchedModules = data.project_Users.map((item) => {
        const matchedUser = userModules.find((u) => u.userID === item.userID);
        if (matchedUser) {
          return {
            assigmentID: item.assigmentID,
            customerID: item.customerID,
            userID: item.userID,
            projectID: item.projectID,
            userName: matchedUser.userName,
          };
        }
        return null;
      });

      setAssignedModules(matchedModules);
    }
  }, [userModules, data.project_Users]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    // Dosya yüklenmişse formData'ya ekle
    if (file && file.length > 0) {
      for (let i = 0; i < file.length; i++) {
        formData.append(`file`, file[i]);
      }
    }
    api
      .put("/api/Project/Put", postData)
      .then((res) => {
        if (file && file.length > 0) {
          api
            .post(
              `/api/FileProcess/UploadFiles/UploadFiles?customerID=${customerID}&type=109&relationID=${projectID}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(() => {
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Proje güncellendi !",
                showConfirmButton: false,
                timer: 1500,
              });
              navigate("/projects");
            })
            .catch((error) => {
              console.error(error);
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: `${error}`,
                showConfirmButton: false,
                timer: 1500,
              });
            });
        } else {
          // Dosya yüklenmemişse direkt olarak bildirim göster
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Proje güncellendi !",
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/projects");
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `${err}`,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  return (
    <>
      {token ? (
        <>
          <Header />
          <div className="flex">
            <Sidebars />
            {loading ? (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "100vh",
                  width:"100vw"
                }}
              >
                <MoonLoader
                  color={"blue"}
                  loading={loading}
                  size={100}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                  speedMultiplier={0.5}
                />
              </div>
            ) : (
              <div style={{width:"80%"}} className="mx-auto">
                <div className="mb-5">
                  <h1 className="text-3xl font-semibold text-blue-700 mt-12 pb-12">
                    Proje Güncelle
                  </h1>
                </div>
                <form
                  onSubmit={handleSubmit}
                  className="grid grid-cols-2 gap-7 items-center w-full"
                >
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Proje Adı :
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="title"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Proje Başlığı :
                    </label>
                    <input
                      type="text"
                      id="title"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div className="col-span-1">
                    <label
                      htmlFor="keywords"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Anahtar Kelimeler :
                    </label>
                    <CreatableSelect
                      onChange={(e) => setKeywords(e)}
                      options={customerKey}
                      isMulti
                      maxMenuHeight={200}
                      value={keywords}
                    />
                  </div>

                  <div className="">
                    <label
                      htmlFor="userSelect"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Kullanıcı Seç :
                    </label>
                    <Select
                      maxMenuHeight={200}
                      id="userSelect"
                      options={users}
                      value={selectedUser}
                      onChange={(e) => setSelectedUser(e)}
                    />
                  </div>
                  <div className="col-span-1">
                    <label
                      htmlFor="startDate"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Başlangıç Tarih :
                    </label>
                    <DatePicker
                      id="startDate"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      locale={tr}
                      dateFormat={"dd/MM/yy"}
                    />
                  </div>

                  <div className="col-span-1">
                    <label
                      htmlFor="statusType"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Proje Tipi :
                    </label>
                    <Select
                      maxMenuHeight={200}
                      id="projectType"
                      options={projectType}
                      value={selectedProjectType}
                      onChange={(e) => setSelectedProjectType(e)}
                    />
                  </div>
                  <div className="col-span-1">
                    <label
                      htmlFor="endDate"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Bitiş Tarih :
                    </label>
                    <DatePicker
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      locale={tr}
                      dateFormat={"dd/MM/yy"}
                    />
                  </div>
                  <div className="">
                    <label
                      htmlFor="statusType"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Proje Durumu :
                    </label>
                    <Select
                      maxMenuHeight={200}
                      id="statusType"
                      options={statusType}
                      value={selectedStatusType}
                      onChange={(e) => setSelectedStatusType(e)}
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="description"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Açıklama :
                    </label>
                    <textarea
                      id="description"
                      type="text"
                      className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                      rows={4}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                  {/* file */}
                  <div className="">
                    <input
                      className="block w-54 text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
                      type="file"
                      onChange={handleChangeFile}
                      multiple
                    />
                  </div>

                  <div className="flex gap-16 h-full mt-16 col-span-2">
                    <div
                      style={{
                        overflowY: "scroll",
                        maxHeight: "500px",
                        minWidth: "300px",
                        minHeight: "300px",
                      }}
                      className=" origin-top-right right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none col-span-3"
                    >
                      <h1 className="px-4 py-2 text-xl font-medium text-red-500">
                        Tüm Kullanıcılar
                      </h1>
                      <div
                        className="py-1"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        {userModules
                          ?.filter(
                            (item) =>
                              !preAssignedModules.some(
                                (assignedItem) =>
                                  assignedItem.userID === item.userID
                              ) &&
                              !assignedModules.some(
                                (assignedItem) =>
                                  assignedItem.userID === item.userID
                              )
                          )
                          .map((item, index) => (
                            <button
                              key={index}
                              className="block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                              role="menuitem"
                              type="button"
                              onClick={() => setSelectedItemData(item)}
                            >
                              {item.userName}
                            </button>
                          ))}
                      </div>
                    </div>
                    <div className="flex flex-col gap-7 justify-center">
                      <button
                        onClick={moveItemToAssigned}
                        type="button"
                        className="w-12"
                      >
                        <img src={right} alt="" />
                      </button>
                      <button
                        onClick={moveItemToAllModules}
                        type="button"
                        className="w-12"
                      >
                        <img src={left} alt="" />
                      </button>
                    </div>
                    <div
                      style={{
                        overflowY: "scroll",
                        maxHeight: "500px",
                        minWidth: "300px",
                      }}
                      className=" origin-top-right right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <h1 className="px-4 py-2 text-xl font-medium text-blue-500">
                        Atanacak Kullanıcılar
                      </h1>
                      <div
                        className="py-1"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        {assignedModules.map((item) => (
                          <button
                            key={item.userID}
                            type="button"
                            className="block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                            role="menuitem"
                            onClick={() => setSelectedItemData(item)}
                          >
                            {item.userName}
                          </button>
                        ))}
                      </div>
                    </div>

                    {/* <div className="relative overflow-x-auto shadow-md sm:rounded-lg mb-5 w-full max-h-80 overflow-y-auto">
                    <table className="w-full text-sm text-left text-gray-500">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            Alt Proje
                          </th>
                          <th scope="col" className="px-6 py-3">
                            İslemler
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                       
                          <tr  className="bg-white border-b">
                            <td className="px-6 py-4">
                              <input
                                name="taskTodo"
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                // required
                                // onChange={(e) => handleServiceChange(e, index)}
                                // value={singleService.name}
                              />
                            </td>
                            <td className="px-6 py-4">
                              <button
                                // onClick={() => handleServiceRemove(index)}
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full m-5"
                              >
                                <i className="fa-regular fa-trash-can"></i>
                              </button>
                            </td>
                          </tr>
                     
                      </tbody>
                      <button
                        type="button"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5 outline-none"
                        // onClick={handleServiceAdd}
                      >
                        Ekle
                        <i className="fa-solid fa-plus ml-2"></i>
                      </button>
                    </table>
                  </div> */}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      gap: "20px",
                    }}
                    className="col-span-2"
                  >
                    <button
                      type="submit"
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5"
                    >
                      Kaydet
                      <i className="fa-regular fa-floppy-disk ml-2"></i>
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default ProjectEdit;
