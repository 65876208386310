import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { FaStar } from "react-icons/fa";

const FormBuilderSchemaDetail = () => {
  const [data, setData] = useState(null);
  const params = useParams();
  const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();
  

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);

    api
      .get(
        `/api/FormBuilder/Get?id=${params.id}&customerID=${parsedObject.country}`
      )
      .then((res) => setData(res.data))
      .catch((err) => console.log(err.data));
  }, []);


  return (
   <>
      {token ? (
         <>
         <Header />
      <div className="flex">
         <Sidebars />
      <form
      className="mt-12 mx-auto"
           style={{
             width: "80vw",
             height: "90vh",
             padding: "30px 20px",
             backgroundColor: "rgb(246, 246, 246)",
             borderRadius: "10px",
             display: "flex",
             flexDirection: "column",
             gap: "10px",
             overflowY: "scroll",
           }}
         >
           <h1
             style={{ textAlign: "center", fontWeight: "500", fontSize: "25px" }}
           >
             {data?.name}
           </h1>
           <p style={{ marginBottom: "50px", marginTop: "50px" }}>
             <span style={{ fontWeight: "500", marginRight: "5px" }}>
               Açıklama:
             </span>{" "}
             {data?.description}
           </p>
           {
             /* formFields dizisini map ederek her bir form alanı için input ve label oluştur */
             data?.formFields?.map((field) => (
               <div key={field.formFieldID} style={{ marginBottom: "20px" }}>
                 <label
                   style={{ fontWeight: "500", marginRight: "5px" }}
                   htmlFor={`field-${field.formFieldID}`}
                 >
                   {field.name}:
                 </label>
                 {getFieldInputByType(field, setRating, rating, hover, setHover)}
               </div>
             ))
           }
         </form>
      </div>
       </>
      ) : (
        navigate("/")
      )}
   </>
  );
};

// Her bir form alanı için uygun input elementini oluşturan yardımcı bir fonksiyon
const getFieldInputByType = (field, setRating, rating, hover, setHover) => {
  switch (field.type) {
    case 93:
      return (
        <input
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5"
          type="text"
          id={`field-${field.formFieldID}`}
        />
      );
    case 94:
      return (
        <textarea
          className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 h-40 p-2.5"
          id={`field-${field.formFieldID}`}
          style={{ resize: "none" }}
        ></textarea>
      );
    // Diğer case'leri ekleyebilirsiniz
    case 95:
      return <input type="radio" id={`field-${field.formFieldID}`} />;
    // Diğer case'leri ekleyebilirsiniz
    case 96:
      return <input type="checkbox" id={`field-${field.formFieldID}`} />;
    // Diğer case'leri ekleyebilirsiniz
    case 99:
      return (
        <input
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-48 p-2.5"
          type="date"
          id={`field-${field.formFieldID}`}
        />
      );
    // Diğer case'leri ekleyebilirsiniz
    case 100:
      return <input type="file" id={`field-${field.formFieldID}`} />;
    // Diğer case'leri ekleyebilirsiniz
    case 97:
      const selectOptions = field.value
        .split(",")
        .map((word) => ({ value: word.trim(), label: word.trim() }));
      return (
        <Select
          options={selectOptions}
          className="w-48 my-1"
          placeholder={"Seçiniz"}
          maxMenuHeight={200}
          id={`field-${field.formFieldID}`}
        />
      );
    case 98:
      return (
        <div className="flex gap-2" id={`field-${field.formFieldID}`}>
          {[...Array(5)].map((star, index) => {
            const currentRating = index + 1;

            return (
              <label key={currentRating}>
                <input
                  style={{ display: "none" }}
                  type="radio"
                  name="rating"
                  value={currentRating}
                  onClick={() => setRating(currentRating)}
                />
                <FaStar
                  style={{ cursor: "pointer" }}
                  size={30}
                  color={
                    currentRating <= (hover || rating) ? "#ffc107" : "#e4e5e9"
                  }
                  onMouseEnter={() => setHover(currentRating)}
                  onMouseLeave={() => setHover(null)}
                />
              </label>
            );
          })}
        </div>
      );
    // Diğer case'leri ekleyebilirsiniz
    default:
      return null;
  }
};

export default FormBuilderSchemaDetail;
