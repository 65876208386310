import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import { Link, useParams } from "react-router-dom";
import api from "../../api/api";
import MoonLoader from "react-spinners/MoonLoader";

const ProjectDetail = () => {
  const params = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState(null);
  const [token, setToken] = useState(null);
  const [projectTask, setProjectTask] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);

    api
      .get(`/api/User/GetUsers?customerID=${parsedObject.country}`)
      .then((res) => {
        const data = res.data;
        const newData = data.map((item) => ({
          value: item.userID,
          label: item.userName,
        }));
        setUsers(newData);
      })
      .catch((err) => console.log(err.data));
  

    api
      .get(
        `/api/Project/GetProjectTasks?customerID=${parsedObject.country}&projectID=${params.id}`
      )
      .then((res) => {
        setProjectTask(res.data);
      })
      .catch((err) => console.log(err))
    api
      .get(
        `/api/Project/Get?id=${params.id}&customerID=${parsedObject.country}`
      )
      .then((res) => setData(res.data))
      .catch((err) => console.log(err.data))
      .finally(() => setLoading(false));
  }, []);

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();

    return `${gun}/${ay}/${yil}`;
  };

  return (
    <>
      <Header />
    <div className="flex">
      <Sidebars />
    {loading ? (
        <div style={{ 
          display: "grid",
          placeItems: "center",
          height: "100vh",
          width:"100vw"
        }}>
          <MoonLoader
            color={"blue"}
            loading={loading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
            speedMultiplier={0.5}
          />
        </div>
      ) : (
        <div className="flex justify-center items-center gap-8 mx-auto">
          <div className="flex justify-center items-center h-screen flex-col gap-10">
            <div
              style={{
                marginTop: "-150px",
                minWidth: "40rem",
                maxWidth: "40rem",
              }}
              className="bg-white max-w-3xl shadow overflow-hidden sm:rounded-lg "
            >
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-blue-600">
                  {data?.name}
                </h3>
                <p className="mt-1 max-w-2xl text-sm font-medium text-gray-500 ">
                  {data?.title}
                </p>
              </div>
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <label
                      htmlFor="userSelect"
                      className="text-sm font-medium text-gray-500"
                    >
                      Atanan Kişi :
                    </label>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {data?.user.userName}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Başlangıç Tarihi :
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {formatDate(data?.startDate)}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Bitiş Tarihi :
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {formatDate(data?.endDate)}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Açıklama :
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      {data?.description}
                    </dd>
                  </div>
                </dl>
              </div>
              <Link to={"/projects"}>
                <button
                  type="button"
                  className="bg-green-600 hover:bg-green-700 float-left text-white font-bold py-2 px-4 rounded-full m-5"
                >
                  <i className="fa-solid fa-arrow-left mr-2"></i>
                  Geri Dön
                </button>
              </Link>
            </div>
          </div>
          <div className="flex justify-center items-center h-screen flex-col gap-10">
            <div
              style={{
                marginTop: "-150px",
                minWidth: "40rem",
                maxWidth: "40rem",
                minHeight: "26rem",
                maxHeight: "26rem",
                overflowY: "auto",
              }}
              className="bg-white max-w-3xl shadow overflow-hidden sm:rounded-lg "
            >
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-blue-700">
                  Proje Görevleri
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-slate-500 font-medium">
                  Detaylar
                </p>
              </div>
              <div className="border-t border-gray-200 ">
                <dl>
                  <div className="bg-white px-4 py-5  sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    {projectTask.map((item, index) => (
                      <div className="flex col-span-3 border-b justify-between items-center">
                        <dd
                          key={index}
                          className="mt-1 text-base text-gray-600 sm:mt-0 sm:col-span-2 font-medium"
                        >
                          {item.name}
                        </dd>
                        <Link
                          to={`../../taskDetailByAssignedUser/${item.taskID}`}
                        >
                          <button
                            type="button"
                            className="bg-blue-500 h-9 items-center hover:bg-blue-600 float-right text-white font-semibold text-sm py-2 px-4 rounded-full m-5"
                          >
                            Göreve Git
                            <i class="fa-solid fa-list-check ml-2"></i>
                          </button>
                        </Link>
                      </div>
                    ))}
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default ProjectDetail;
