import React from 'react';
import Timeline from 'react-calendar-timeline';
import moment from 'moment';
import 'react-calendar-timeline/lib/Timeline.css';

const groups = [
  { id: 1, title: 'Group 1' },
  { id: 2, title: 'Group 2' }
];

const items = [
  { id: 1, group: 1, title: 'Item 1', start_time: moment().add(-6, 'hour'), end_time: moment().add(6, 'hour') },
  { id: 2, group: 2, title: 'Item 2', start_time: moment().add(-5, 'hour'), end_time: moment().add(7, 'hour') }
];



const App = () => {
  // Timeline genişliğini ayarlamak için inline stil kullanın
  const timelineStyle = {
    width: '50%', // Genişliği yüzde olarak ayarlayabilirsiniz
    margin: '0 auto' // Ortalamak için
  };

  return (
    <div style={timelineStyle}>
      <Timeline
        groups={groups}
        items={items}
        defaultTimeStart={moment().add(-12, 'hour')}
        defaultTimeEnd={moment().add(12, 'hour')}
      />
    </div>
  );
};

export default App;
