import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import { Link, useNavigate } from "react-router-dom";

const WorkFlowAddForm = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    title: "",
    name: "",
    description: "",
  });
  const [token, setToken] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/workFlow", { state: form });
  };
  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);
  return (
    <>
      {token ? (
        <>
          <Header />
          <div className="flex">
          <Sidebars />
            <div style={{width:"80%"}} className="mt-12 mx-auto">
              <div className="mb-5">
                <h1 className="text-3xl font-semibold text-blue-700 pb-12">
                  İş Akışı Oluştur
                </h1>
              </div>
              <form
                onSubmit={handleSubmit}
                className="grid grid-cols-1 gap-7 items-center w-full"
              >
                <div className="col-span-2">
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    İş Akış Başlığı :
                  </label>
                  <input
                    value={form.title}
                    onChange={(e) =>
                      setForm({ ...form, title: e.target.value })
                    }
                    type="text"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="name"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    İş Akış Adı :
                  </label>
                  <input
                    value={form.name}
                    onChange={(e) => setForm({ ...form, name: e.target.value })}
                    type="text"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
                <div className="col-span-2">
                  <label
                    htmlFor="description"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    İş Akış Açıklaması :
                  </label>
                  <textarea
                    value={form.description}
                    onChange={(e) =>
                      setForm({ ...form, description: e.target.value })
                    }
                    id="description"
                    rows="4"
                    className="resize-none block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Açıklama yazınız..."
                  ></textarea>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "20px",
                  }}
                  className="col-span-3"
                >
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5"
                  >
                    Devam Et
                    <i className="fa-solid fa-arrow-right ml-2"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default WorkFlowAddForm;
