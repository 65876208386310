import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import api from "../../api/api";

const ColumnChart = () => {
  const [state, setState] = useState({
    series: [
      {
        name: "Teklifler",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Talepler",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Faturalar",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Siparişler",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Ocak",
          "Şubat",
          "Mart",
          "Nisan",
          "Mayıs",
          "Haziran",
          "Temmuz",
          "Ağustos",
          "Eylül",
          "Ekim",
          "Kasım",
          "Aralık",
        ],
      },
      yaxis: {
        title: {
          text: "",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " adet";
          },
        },
      },
    },
  });

  useEffect(() => {
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);

    api
      .get(
        `/api/Report/GetDOOICounts?customerID=${parsedObject.country}&startDate=2024-1-1&endDate=2024-12-31`
      )
      .then((res) => {
        // API'den gelen verileri kullanarak state'i güncelle
        const updatedDataInvoice = res.data.map((item) => item.invoiceCount);
        const updatedDataOffer = res.data.map((item) => item.offerCount);
        const updatedDataOrder = res.data.map((item) => item.orderCount);
        const updatedDataDemand = res.data.map((item) => item.demandCount);
        setState((prevState) => ({
          ...prevState,
          series: prevState.series.map((serie) => {
            if (serie.name === "Faturalar") {
              return { ...serie, data: updatedDataInvoice };
            } else if (serie.name === "Teklifler") {
              return { ...serie, data: updatedDataOffer };
            } else if (serie.name === "Siparişler") {
              return { ...serie, data: updatedDataOrder };
            } else if (serie.name === "Talepler") {
              return { ...serie, data: updatedDataDemand };
            }
            return serie;
          }),
        }));
      })
      .catch((err) => console.log(err.data));
  }, []);

  return (
    <div className="w-full bg-white border border-gray-200 rounded-lg shadow ">
      <div id="chart">
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  );
};

export default ColumnChart;
