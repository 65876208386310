import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api/api";

export const getUser =  createAsyncThunk('user/getUser', async ()=>{
    
    const storedToken = window.localStorage.getItem("userData")
    const parsed = JSON.parse(storedToken)
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith('/country')) {
          result.country = parsed[key];
        } else if (key.endsWith('/emailaddress')) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith('/name')) {
          result.name = parsed[key];
        } else if (key.endsWith('/nameidentifier')) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);

    const res = await api.get(`/api/User/Get?id=${parsedObject.nameidentifier}&customerID=${parsedObject.country}`)

    return res.data
})

export const getModules = createAsyncThunk('user/getModules', async ()=>{
   
  const storedToken = window.localStorage.getItem("userData")
  const parsed = JSON.parse(storedToken)
  function parseClaimsObject(parsed) {
    const result = {};

    for (const key in parsed) {
      if (key.endsWith('/country')) {
        result.country = parsed[key];
      } else if (key.endsWith('/emailaddress')) {
        result.emailaddress = parsed[key];
      } else if (key.endsWith('/name')) {
        result.name = parsed[key];
      } else if (key.endsWith('/nameidentifier')) {
        result.nameidentifier = parsed[key];
      }
    }

    return result;
  }
  const parsedObject = parseClaimsObject(parsed);

  const res = await api.get(`/api/User/GetUserModules?customerID=${parsedObject.country}&userID=${parsedObject.nameidentifier}`)

  return res.data
})

export const getNotification =  createAsyncThunk('notifications/getNotifications', async ()=>{
    
  const storedToken = window.localStorage.getItem("userData")
  const parsed = JSON.parse(storedToken)
  function parseClaimsObject(parsed) {
    const result = {};

    for (const key in parsed) {
      if (key.endsWith('/country')) {
        result.country = parsed[key];
      } else if (key.endsWith('/emailaddress')) {
        result.emailaddress = parsed[key];
      } else if (key.endsWith('/name')) {
        result.name = parsed[key];
      } else if (key.endsWith('/nameidentifier')) {
        result.nameidentifier = parsed[key];
      }
    }

    return result;
  }
  const parsedObject = parseClaimsObject(parsed);

  const res = await api.get(`/api/UserNotification/GetNotifications?userID=${parsedObject.nameidentifier}&customerID=${parsedObject.country}`)

  return res.data
})


const initialState = {
    userData: [], 
    userModules:[],
    notifications:[],
    isLoading : false,
    isError : false
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    extraReducers:{
        [getUser.pending]: (state,action)=>{
            state.isLoading = true;
            state.isError = false;
        },
        
        [getUser.fulfilled]: (state,action)=>{
            state.userData = action.payload;
            state.isLoading = false;
            state.isError = false;
        },
        
        [getUser.rejected]: (state,action)=>{
            state.isLoading = false;
            state.isError = true;
        },
        
        [getModules.pending]: (state,action)=>{
            state.isLoading = true;
            state.isError = false;
        },
        [getModules.fulfilled]: (state,action)=>{
          state.userModules = action.payload;
          state.isLoading = false;
          state.isError = false;
        },
        [getModules.rejected]: (state,action)=>{
            state.isLoading = false;
            state.isError = true;
        },
        [getNotification.fulfilled]: (state,action)=>{
          state.notifications = action.payload;
         
      },
    }
})



export default userSlice.reducer;

