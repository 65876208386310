import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";

const AccountProducts = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [customerID, setCustomerID] = useState(Number());
    const [token, setToken] = useState(null);

    useEffect(() => {
        setToken(localStorage.getItem("token"));
        const storedToken = window.localStorage.getItem("userData");
        const parsed = JSON.parse(storedToken);
        function parseClaimsObject(parsed) {
          const result = {};
    
          for (const key in parsed) {
            if (key.endsWith("/country")) {
              result.country = parsed[key];
            } else if (key.endsWith("/emailaddress")) {
              result.emailaddress = parsed[key];
            } else if (key.endsWith("/name")) {
              result.name = parsed[key];
            } else if (key.endsWith("/nameidentifier")) {
              result.nameidentifier = parsed[key];
            }
          }
    
          return result;
        }
        const parsedObject = parseClaimsObject(parsed);
        setCustomerID(parsedObject.country);
    
      
      }, []);
  return (
    <>
    {token ? (
      <>
        <Header />
        <Sidebars />
    
      </>
    ) : (
      navigate("/")
    )}
  </>
  )
}

export default AccountProducts