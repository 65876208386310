import React, { useEffect, useState } from "react";
import Header from "../../components/bars/Header";
import Sidebars from "../../components/bars/Sidebar";
import api from "../../api/api";
import Select from "react-select";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import left from "../../assets/images/left-click.png";
import right from "../../assets/images/right-click.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import { setHours, setMinutes } from "date-fns";
import CreatableSelect from "react-select/creatable";

const MeetingEdit = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [meetingData, setMeetingData] = useState([]);
  const [users, setUsers] = useState([]);
  const [meetingType, setMeetingType] = useState([]);
  const [customerID, setCustomerID] = useState(Number());
  const [userID, setUserID] = useState(Number());
  const [token, setToken] = useState(null);
  const [meetingTypes, setMeetingTypes] = useState(null);
  const [departmentsOpt, setDepartmentsOpt] = useState([]);
  const [departments, setDepartments] = useState([]);

  //FORM
  const [startDate, setStartDate] = useState(
    setHours(setMinutes(new Date(), 0), 9)
  );
  const [meetingOwner, setMeetingOwner] = useState([]);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [customerKey, setCustomerKey] = useState(null);
  const [keywords, setKeywords] = useState([]);
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState([]);
  const [selectedMeetingType, setSelectedMeetingType] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [zoomPassword, setZoomPassword] = useState("");
  const [zoomWaitingRoom, setZoomWaitingRoom] = useState(null);
  const [zoomMute, setZoomMute] = useState(null);
  const [zoomRecord, setZoomRecord] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState([]);
  const [meetingTempOpt,setMeetingTempOpt] = useState([]);
  const [meetingTemps,setMeetingTemps] = useState([]);
  const [selectedMeetingTemp,setSelectedMeetingTemp] = useState({});
  const [locations,setLocations] = useState([]);
  const [selectedLocation,setSelectedLocation] = useState({});

  //ASSIGNED USERS MODULE
  const [data, setData] = useState([]);
  const [assignedModules, setAssignedModules] = useState([]);
  const [preAssignedModules, setPreAssignedModules] = useState([]);
  const [selectedItemData, setSelectedItemData] = useState(null);

  //AGENDA ITEMS
  const [meetingAgendaItems, setMeetingAgendaItems] = useState([]);

    // MEETING GUEST USERS
    const [guestUsers, setGuestUsers] = useState([]);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);
    setCustomerID(parsedObject.country);
    setUserID(parsedObject.nameidentifier);

    api
      .get(
        `/api/Meeting/Get?id=${params.id}&customerID=${parsedObject.country}`
      )
      .then((res) => {
        setMeetingData(res.data);
        setTitle(res.data.title);
        setName(res.data.name);
        setDuration({ label: res.data.duration, value: res.data.duration });
        setSelectedLocation({label: res.data.location, value: res.data.location})
        setDescription(res.data.description);
        setStartDate(new Date(res.data.startDate));
        const dataKey = JSON.parse(res.data.keywords);
        const newData = dataKey.map((item) => ({
          value: item,
          label: item,
          __isNew__: true,
        }));
        setKeywords(newData);
        setZoomPassword(res.data.zoom_Password);
        setZoomWaitingRoom(Boolean(res.data.zoom_Waiting_Room));
        setZoomMute(Boolean(res.data.zoom_Mute_Upon_Entry));
        setZoomRecord(Boolean(res.data.zoom_Auto_Recording));
      })
      .catch((err) => console.error(err.data));

    api
      .get(`/api/User/GetUsers?customerID=${parsedObject.country}`)
      .then((res) => {
        setUsers(res.data);
        const data = res.data;
        const newData = data.map((item) => ({
          value: item.userID,
          label: item.userName,
        }));
        setUserOptions(newData);
      })
      .catch((err) => console.error(err.data));
    
      api
      .get(`/api/Department/GetDepartments?customerID=${parsedObject.country}`)
      .then((res) => {
        setDepartments(res.data);
        const dataDep = res.data;
        const newDataDep = dataDep.map((item) => ({
          value: item.departmentID,
          label: item.name,
        }));
        setDepartmentsOpt(newDataDep);
      })
      .catch((err) => console.error(err.data));
    api
      .get("/api/Common/GetParameters?parametername=MeetingType")
      .then((res) => {
        setMeetingTypes(res.data);
        const data = res.data;
        const newData = data.map((item) => ({
          value: item.parameterID,
          label: item.parameterValue2,
        }));
        setMeetingType(newData);
      })
      .catch((err) => console.error(err.data));
      api.get(`/api/Common/GetKeywords?customerID=${parsedObject.country}`)
      .then((res)=>{
          const newData = res.data?.map((item) => ({
            value: item,
            label: item,
          }));
          setCustomerKey(newData)
      })
      .catch((err)=>console.log(err.data))

      api
      .get("/api/Meeting/GetMeetingTypes")
      .then((res) => {
        setMeetingTemps(res.data);
        let dataTemp = res.data;
        let newDataTemp = dataTemp.map((item) => ({
          value: item.meetingTypeID,
          label: item.name,
        }));
        setMeetingTempOpt(newDataTemp)
      })
      .catch((err) => console.log(err.data));

      api
      .get(`/api/Common/GetLocations?customerID=${parsedObject.country}`)
      .then((res) => {
        let dataLocations = res.data;
        let newDataLocations = dataLocations.map((item) => ({
          value: item,
          label: item,
        }));
        setLocations(newDataLocations)
      })
      .catch((err) => console.log(err.data));

  }, []);

   // MEETING TEMPLATE ONCHANGE
 
   const meetingTempChange = (e)=>{
    Swal.fire({
      title: "Şablonda Tanımlı Gündem Maddelerini Kullanmak İstiyor Musunuz ?",
      text: "Gündem maddeler otomatik olarak eklenecektir !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet, istiyorum!",
      cancelButtonText: "Kapat",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Tanımlı gündem maddeleri eklendi !",
          text: "Tanımlı gündem maddeler, toplantı gündem maddelerine eklendi.",
          icon: "success"
        });
        setSelectedMeetingTemp(e);
       
        const matchedTempType = meetingTemps?.find((item)=>item.meetingTypeID === e.value)
    
        setMeetingAgendaItems([
          ...matchedTempType.meetingTypeItems?.map((item)=>(
            {
              agendaItemSelect: {},
              description: item.description,
              customerID,
              userID: {},
              departmentID: {},
              // endDate: new Date(),
            }
          ))
        ]);
      }
    });
  }

  /// DATE OPTIONS
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  /// ASSIGNED USERS MODEL OPTIONS
  const moveItemToAssigned = () => {
    if (selectedItemData && !assignedModules.includes(selectedItemData)) {
      const yeniOge = {
        ...selectedItemData,
        customerID,
      };

      setAssignedModules((oncekiModuller) => [...oncekiModuller, yeniOge]);
      setData((oncekiData) =>
        oncekiData.filter((oge) => oge !== selectedItemData)
      );
      setSelectedItemData(null);
    }
  };

  const moveItemToAllModules = () => {
    if (selectedItemData && !data.includes(selectedItemData)) {
      setData((prevData) => [...prevData, selectedItemData]);
      setAssignedModules((prevModules) =>
        prevModules.filter((item) => item !== selectedItemData)
      );
      setSelectedItemData(null);
    }
  };

  // MEETING AGENTAL ITEMS OPTIONS
  const handleServiceChange = (e, index) => {
    const { value } = e.target;
    const list = [...meetingAgendaItems];
    list[index].description = value;
    setMeetingAgendaItems(list);
  };
  const handleAgendaItemChange = (e, index) => {
    const list = [...meetingAgendaItems];
    list[index].agendaItemSelect = e;
    setMeetingAgendaItems(list);
  };
  const handleServiceSelectChange = (e, index) => {
    const list = [...meetingAgendaItems];
    list[index].userID = e;
    setMeetingAgendaItems(list);
  };
  const handleServiceDepSelectChange = (e, index) => {
    const list = [...meetingAgendaItems];
    list[index].departmentID = e;
    setMeetingAgendaItems(list);
  };

  const handleServiceAdd = () => {
    setMeetingAgendaItems([
      ...meetingAgendaItems,
      {
        agendaItemSelect: {},
        description: "",
        customerID,
        userID: {},
        departmentID: {},
      },
    ]);
  };

  const handleServiceRemove = (index) => {
    const list = [...meetingAgendaItems];
    list.splice(index, 1);
    setMeetingAgendaItems(list);
  };



  // MEETING GUEST USERS OPTIONS
  const handleServiceNameChange = (e, index) => {
    const { value } = e.target;
    const list = [...guestUsers];
    list[index].name = value;
    setGuestUsers(list);
  };
  const handleServiceEmailChange = (e, index) => {
    const { value } = e.target;
    const list = [...guestUsers];
    list[index].email = value;
    setGuestUsers(list);
  };


  const handleServiceGuestAdd = () => {
    setGuestUsers([
      ...guestUsers,
      {
        email: "",
        name:""
        
      },
    ]);
  };
  const handleServiceGuestRemove = (index) => {
    const list = [...guestUsers];
    list.splice(index, 1);
    setGuestUsers(list);
  };


  // DURATION OPTIONS
  const durationOptions = [];
  for (let i = 15; i <= 120; i++) {
    durationOptions.push({ value: i, label: i });
  }

  //ZOOM PASSWORD INPUT CHANGE
  const handleChangePassword = (event) => {
    if (event.target.value.length <= 5) {
      setZoomPassword(event.target.value);
    }
  };

  const postData = {
    meetingID:params.id,
    customerID,
    departmentID: 0,
    userID: meetingOwner.value,
    meetingTypeID: selectedMeetingTemp.value,
    title,
    name,
    zoom_URL: meetingData.zoom_URL,
    location: selectedLocation.label,
    zoom_Password: zoomPassword,
    zoom_Waiting_Room: zoomWaitingRoom,
    zoom_Mute_Upon_Entry: zoomMute,
    zoom_Auto_Recording: zoomRecord,
    startDate,
    duration: duration.value,
    type: selectedMeetingType.value,
    status: 0,
    viewIndex: 0,
    description,
    keywords: JSON.stringify(keywords?.map((item) => item.label)),
    meetingAgendaItems: meetingAgendaItems.map((item) => ({
      agendaItemID:item?.agendaItemID,
      userID: item?.userID.value,
      description: item?.description,
      departmentID: item?.departmentID.value
      
    })),
    meeting_Users: assignedModules.map((item) => ({
      userID: item.userID,
      meetingUserID: item?.meetingUserID
    })),
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    api
      .put("/api/Meeting/Put/Put", postData)

      .then((res) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Toplantı güncellendi !",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/meetings");
      })

      .catch((err) => {
        console.log(err);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: `${err}`,
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  useEffect(() => {
    if (meetingData.meetingAgendaItems && users && meetingTypes && departments && meetingTemps) {
      const matchedType = meetingTypes.find(
        (item) => item.parameterID === meetingData.type
      );
      setSelectedMeetingType({
        value: matchedType?.parameterID,
        label: matchedType?.parameterValue2,
      });
      const matchedUser = users.find(
        (item) => item.userID === meetingData.userID
      );
      setMeetingOwner({
        value: matchedUser?.userID,
        label: matchedUser?.userName,
      });

      // FIND AGENTAL ITEMS FOR USER
      const matchedAgentaItems = meetingData.meetingAgendaItems.map((item) => {
      const matchedUserSelect = users.find((user) => user.userID === item.userID);
      const matchedAgentaDepartmentID = departments.find((department)=> department.departmentID === item.departmentID)

        // Eğer userID null ise
        if (item.userID === null) {
          // Eğer departmentID de null ise
          if (item.departmentID === null) {
            return {
              description: item.description,
              agendaItemID: item.agendaItemID,
              agendaItemSelect: { value: "", label: "" }, // agendaItemSelect boş olacak
              userID: "",
              departmentID: "",
            };
          } else {
            return {
              description: item.description,
              agendaItemID: item.agendaItemID,
              agendaItemSelect: { value: 1, label: "departman" },
              userID: "",
              departmentID: {
                label: matchedAgentaDepartmentID?.name,
                value: matchedAgentaDepartmentID?.departmentID,
              },
            };
          }
        } else {
          return {
            description: item.description,
            agendaItemID: item.agendaItemID,
            agendaItemSelect: { value: 2, label: "kullanıcı" },
            userID: {
              label: matchedUserSelect?.userName,
              value: matchedUserSelect?.userID,
            },
            departmentID: {
              label: null,
              value: null,
            },
          };
        }
      });
      setMeetingAgendaItems(matchedAgentaItems);
      
    
      // FINDING ID FOR USER SELECT
      const matchedMeetUsers = meetingData?.meeting_Users.map((item) => {
        const matchedMeetUser = users.find(
          (user) => user.userID === item.userID
        );
        return {
          ...matchedMeetUser,
          meetingUserID: item.meetingUserID,
        };
      });
      setAssignedModules(matchedMeetUsers);
   
      const matchedDepartments = departments.find(
        (item) => item.departmentID === meetingData.departmentID
      );
      setSelectedDepartment({
        value: matchedDepartments?.departmentID,
        label: matchedDepartments?.name,
      });

      const matchedTempID = meetingTemps.find(
        (item) => item.meetingTypeID === meetingData.meetingTypeID
      );
      setSelectedMeetingTemp({
        value: matchedTempID?.meetingTypeID,
        label: matchedTempID?.name,
      })
    }
  }, [meetingData, users, meetingTypes, departments,meetingTemps]);


  return (
    <>
      {token ? (
        <>
          <Header />
        <div className="flex">
          <Sidebars />
        <div
            className="pt-12 mx-auto px-12"
            style={{
              width: "100%",
              maxHeight:"100vh",
              overflowY:"scroll"
            }}
          >
            <div className="mb-5">
              <h1 className="text-3xl font-semibold text-blue-700">
                Toplantı Güncelle
              </h1>
            </div>
            <form
              onSubmit={handleSubmit}
              className="grid grid-cols-3 gap-7 items-center w-full"
            >
              <div className="col-span-1">
                <label
                  htmlFor="title"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Başlık :
                </label>
                <input
                  type="text"
                  id="title"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Toplantı Adı :
                </label>
                <input
                  type="text"
                  id="name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="department"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Departman :
                </label>
                <Select
                  id="department"
                  maxMenuHeight={200}
                  options={departmentsOpt}
                  value={selectedDepartment} 
                  onChange={(e)=>setSelectedDepartment(e)}
                />
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="meetingOwner"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Toplantı Sahibi :
                </label>
                <Select
                  id="meetingOwner"
                  options={userOptions}
                  maxMenuHeight={200}
                  value={meetingOwner}
                  onChange={(e) => setMeetingOwner(e)}
                />
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="account"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Toplantı Süresi :
                </label>
                <Select
                  value={duration}
                  onChange={(e) => setDuration(e)}
                  options={durationOptions}
                  maxMenuHeight={200}
                />
              </div>

              <div style={{ width: "100%" }} className="col-span-1">
                <label
                  htmlFor="startDate"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Başlangıç Tarih :
                </label>
                <DatePicker
                  id="startDate"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  showTimeSelect
                  filterTime={filterPassedTime}
                  locale={tr}
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </div>
              <div>
                <label
                  htmlFor="telephone"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Lokasyon :
                </label>
                <Select value={selectedLocation} onChange={(e)=>setSelectedLocation(e)} options={locations} maxMenuHeight={200} />
              </div>


              <div className="col-span-1">
                <label
                  htmlFor="taskName"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Anahtar Kelimeler :
                </label>
                <CreatableSelect
                  value={keywords}
                  onChange={(e) => setKeywords(e)}
                  options={customerKey}
                  isMulti
                  maxMenuHeight={200}
                />
              </div>
              <div className="col-span-1">
                <label
                  htmlFor="description"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Açıklama :
                </label>
                <textarea
                  id="description"
                  type="text"
                  className="resize-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  required
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="col-span-3">
                  <label
                    htmlFor="meetingTemplate"
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    Toplantı Şablonu :
                  </label>
                  <Select
                    id="meetingTemplate"
                    maxMenuHeight={200}
                    options={meetingTempOpt}
                    value={selectedMeetingTemp}
                    onChange={(e)=>meetingTempChange(e)}
                  />
                </div>

              <div className="col-span-3">
                <label
                  htmlFor="project"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Toplantı Tipi :
                </label>
                <Select
                  value={selectedMeetingType}
                  onChange={(e) => setSelectedMeetingType(e)}
                  options={meetingType}
                  maxMenuHeight={200}
                />
              </div>
              {selectedMeetingType.value === 137 && (
                <div className="col-span-3 grid grid-cols-3 bg-slate-50 gap-5 p-5 rounded-2xl">
                  <div className="col-span-3">
                    <label
                      htmlFor="password"
                      className="block mb-2 text-sm font-medium text-gray-900"
                    >
                      Katılma Şifresi :
                    </label>
                    <input
                      type="text"
                      id="password"
                      className="bg-slate-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="Max. 5 karakter giriniz..."
                      value={zoomPassword}
                      onChange={(e) => handleChangePassword(e)}
                      disabled
                    />
                  </div>
                  <div className="flex items-center">
                    <label
                      htmlFor="waitingRoom"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Bekleme Odası :
                    </label>
                    <input
                      type="checkbox"
                      id="waitingRoom"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      checked={zoomWaitingRoom}
                      onChange={(e) => setZoomWaitingRoom(e.target.checked)}
                      disabled
                    />
                  </div>
                  <div className="flex items-center">
                    <label
                      htmlFor="muted"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Katılımcılar Sessiz :
                    </label>
                    <input
                      type="checkbox"
                      id="muted"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      checked={zoomMute}
                      onChange={(e) => setZoomMute(e.target.checked)}
                      disabled
                    />
                  </div>
                  <div className="flex items-center">
                    <label
                      htmlFor="otoRecord"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Otamatik Kayıt :
                    </label>
                    <input
                      type="checkbox"
                      id="otoRecord"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      checked={zoomRecord}
                      onChange={(e) => setZoomRecord(e.target.checked)}
                      disabled
                    />
                  </div>
                </div>
              )}

              <div className="flex gap-16 h-full mt-16 col-span-3">
                <div
                  style={{
                    overflowY: "scroll",
                    maxHeight: "500px",
                    minWidth: "300px",
                    minHeight: "300px",
                  }}
                  className=" origin-top-right right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none col-span-3"
                >
                  <h1 className="px-4 py-2 text-xl font-medium text-red-500">
                    Tüm Kullanıcılar
                  </h1>
                  <div
                    className="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    {users
                      .filter(
                        (item) =>
                          !preAssignedModules.some(
                            (assignedItem) =>
                              assignedItem.userID === item.userID
                          ) &&
                          !assignedModules.some(
                            (assignedItem) =>
                              assignedItem.userID === item.userID
                          )
                      )
                      .map((item, index) => (
                        <button
                          key={index}
                          className="block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                          role="menuitem"
                          type="button"
                          onClick={() => setSelectedItemData(item)}
                        >
                          {item.userName}
                        </button>
                      ))}
                  </div>
                </div>
                <div className="flex flex-col gap-7 justify-center">
                  <button
                    onClick={moveItemToAssigned}
                    type="button"
                    className="w-12"
                  >
                    <img src={right} alt="" />
                  </button>
                  <button
                    onClick={moveItemToAllModules}
                    type="button"
                    className="w-12"
                  >
                    <img src={left} alt="" />
                  </button>
                </div>
                <div
                  style={{
                    overflowY: "scroll",
                    maxHeight: "500px",
                    minWidth: "300px",
                  }}
                  className=" origin-top-right right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <h1 className="px-4 py-2 text-xl font-medium text-blue-500">
                    Atanacak Kullanıcılar
                  </h1>
                  <div
                    className="py-1"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                  >
                    {assignedModules.map((item) => (
                      <button
                        key={item.userID}
                        type="button"
                        className="block px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                        role="menuitem"
                        onClick={() => setSelectedItemData(item)}
                      >
                        {item.userName}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg  w-full max-h-80 overflow-y-auto">
                    <table className="w-full text-sm text-left text-gray-500">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            Misafir İsmi
                          </th>
                          <th scope="col" className="px-6 py-3">
                            E-mail
                          </th>
                          <th scope="col" className="px-6 py-3">İşlem</th>
                        </tr>
                      </thead>
                      <tbody>
                        {guestUsers.map((singleService, index) => (
                          <tr key={index} className="bg-white border-b">
                            <td className="px-6 py-4">
                              <input
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                required
                                value={singleService.name}
                                onChange={(e)=>handleServiceNameChange(e,index)}
                              />
                            </td>
                            <td className="px-6 py-4">
                              <input
                                type="email"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                required
                                value={singleService.email}
                                onChange={(e)=>handleServiceEmailChange(e,index)}
                              />
                            </td>
                            <td className="px-6 py-4">
                              <button
                                onClick={() => handleServiceGuestRemove(index)}
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full m-5"
                              >
                                <i className="fa-regular fa-trash-can"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <button
                        type="button"
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5 outline-none"
                        onClick={handleServiceGuestAdd}
                      >
                        Ekle
                        <i className="fa-solid fa-plus ml-2"></i>
                      </button>
                    </table>
                  </div>
              </div>
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg mb-5 w-full max-h-80 overflow-y-auto col-span-3 mt-6">
                  <table className="w-full text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3">
                          Toplantı Gündem Maddeleri
                        </th>
                        <th scope="col" className="px-6 py-3">
                         İşlem seç
                        </th>
                        <th scope="col" className="px-6 py-3">
                        (Kullanıcı/Departman)
                        </th>
                        <th scope="col" className="px-6 py-3"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {meetingAgendaItems.map((singleService, index) => (
                        <tr key={index} className="bg-white border-b">
                          <td className="px-6 py-4">
                            <input
                              name="taskTodo"
                              type="text"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                              required
                              onChange={(e) => handleServiceChange(e, index)}
                              value={singleService.description}
                            />
                          </td>
                          <td className="px-6 py-4">
                            <Select
                              value={singleService.agendaItemSelect}
                              onChange={(e) => handleAgendaItemChange(e, index)}
                              options={[
                                { value: 1, label: "Departman" },
                                { value: 2, label: "Kullanıcı" },
                              ]}
                              maxMenuHeight={200}
                            />
                          </td>
                          {singleService.agendaItemSelect.value === 1 && (
                            <td className="px-6 py-4">
                              <Select
                                value={singleService.departmentID}
                                onChange={(e) =>
                                  handleServiceDepSelectChange(e, index)
                                }
                                options={departmentsOpt}
                                maxMenuHeight={200}
                              />
                            </td>
                          )}
                          {singleService.agendaItemSelect.value === 2 && (
                            <td className="px-6 py-4">
                              <Select
                                value={singleService.userID}
                                onChange={(e) =>
                                  handleServiceSelectChange(e, index)
                                }
                                options={userOptions}
                                maxMenuHeight={200}
                              />
                            </td>
                          )}
                          <td className="px-6 py-4">
                            <button
                              onClick={() => handleServiceRemove(index)}
                              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full m-5"
                            >
                              <i className="fa-regular fa-trash-can"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <button
                      type="button"
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5 outline-none"
                      onClick={handleServiceAdd}
                    >
                      Ekle
                      <i className="fa-solid fa-plus ml-2"></i>
                    </button>
                  </table>
                </div>
               
          
              <div
                style={{ display: "flex", justifyContent: "end", gap: "20px" }}
                className="col-span-3"
              >
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full m-5"
                >
                  Kaydet
                  <i className="fa-regular fa-floppy-disk ml-2"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
        </>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default MeetingEdit;
