import React, { useEffect, useState } from "react";
import api from "../../api/api";
import MoonLoader from "react-spinners/MoonLoader";
import { Link } from "react-router-dom";
import { FaRegEye } from "react-icons/fa";
import { FaRegCirclePlay } from "react-icons/fa6";
import Swal from "sweetalert2";
import Switch from "react-switch";
import { BiTransfer } from "react-icons/bi";
import TaskTransferModal from "./TaskTransferModal";

const TasksByAssignedUser = () => {
  const [data, setData] = useState(null);
  const [allData, setAllData] = useState(null);
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [token, setToken] = useState(null);
  const [matchedData, setMatchedData] = useState(null);
  const [matchedAllData, setMatchedAllData] = useState(null);
  const [projects, setProjects] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [openMdule, setOpenModule] = useState(false);
  const [selectedTaskID, setSelectedTaskID] = useState(false);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
    const storedToken = window.localStorage.getItem("userData");
    const parsed = JSON.parse(storedToken);
    function parseClaimsObject(parsed) {
      const result = {};

      for (const key in parsed) {
        if (key.endsWith("/country")) {
          result.country = parsed[key];
        } else if (key.endsWith("/emailaddress")) {
          result.emailaddress = parsed[key];
        } else if (key.endsWith("/name")) {
          result.name = parsed[key];
        } else if (key.endsWith("/nameidentifier")) {
          result.nameidentifier = parsed[key];
        }
      }

      return result;
    }
    const parsedObject = parseClaimsObject(parsed);

    api
      .get(
        `/api/Task/GetTasksByAssignedUser?customerID=${parsedObject.country}&userID=${parsedObject.nameidentifier}`
      )
      .then((res) => setData(res.data))
      .catch((err) => console.log(err.data))
      .finally(() => setLoading(false));
    api
      .get(
        `/api/Task/GetAllTasksByAssignedUser?customerID=${parsedObject.country}&userID=${parsedObject.nameidentifier}`
      )
      .then((res) => setAllData(res.data))
      .catch((err) => console.log(err.data))
      .finally(() => setLoading(false));

    api
      .get(`/api/User/GetUsers?customerID=${parsedObject.country}`)
      .then((res) => setUsers(res.data))
      .catch((err) => console.log(err.data));
    api
      .get(`/api/Project/GetProjects?customerID=${parsedObject.country}`)
      .then((res) => setProjects(res.data))
      .catch((err) => console.log(err.data));
  }, []);

  const formatDate = (dateString) => {
    const tarihObjesi = new Date(dateString);
    const gun = tarihObjesi.getDate();
    const ay = tarihObjesi.getMonth() + 1;
    const yil = tarihObjesi.getFullYear();

    // Saate 3 ekleyerek hesaplama yapalım
    let saat = tarihObjesi.getHours();

    // Eğer saat 24'ten büyükse 24'e göre modunu alalım
    saat = saat % 24;

    // Saati string formatına çevirirken padding işlemi yapalım
    const saatString = saat.toString().padStart(2, "0");
    const dakika = tarihObjesi.getMinutes().toString().padStart(2, "0");

    return `${gun}/${ay}/${yil} - ${saatString}.${dakika} `;
  };

  useEffect(() => {
    if (data && projects && allData) {
      // Veri ve kullanıcılar yüklendiyse eşleşme işlemini yap
      const matchedUsers = data.map((item) => {
        const matchedProject = projects.find(
          (project) => project.projectID === item.projectID
        );
        if (matchedProject) {
          return {
            ...item,
            projectName: matchedProject.name,
          };
        }
        return item;
      });
      // Eşleşmiş kullanıcıları içeren veriyi set et
      setMatchedData(matchedUsers);

      ////////////////////// ALL DATA //////////////////////
      const matchedAllUsers = allData.map((item) => {
        const matchedProject = projects.find(
          (project) => project.projectID === item.projectID
        );
        if (matchedProject) {
          return {
            ...item,
            projectName: matchedProject.name,
          };
        }
        return item;
      });
      setMatchedAllData(matchedAllUsers);
    }
  }, [data, projects, allData]);

  const handleSubmit = async (id) => {
    setLoading(true);
    try {
      const storedToken = localStorage.getItem("token");

      if (!storedToken) {
        throw new Error("Token bulunamadı."); // Eğer token bulunamazsa hata fırlat
      }

      await api.put(`/api/Task/PutTaskStatus?taskID=${id}&status=71`, null, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Görev başlatıldı !",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Görev başlatılamadı!",
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setLoading(false);
    }

  };
  function getPriorityFlag(priorityLevel) {
    switch (priorityLevel) {
      case 5:
        return <i className="fa-solid fa-flag text-red-600 fa-xl"></i>; // Kırmızı Bayrak
      case 4:
        return <i className="fa-solid fa-flag text-orange-400 fa-xl"></i>; // Turuncu Bayrak
      case 3:
        return <i className="fa-solid fa-flag text-yellow-400 fa-xl"></i>; // Sarı Bayrak
      case 2:
        return <i className="fa-solid fa-flag text-green-600 fa-xl"></i>; // Yeşil Bayrak
      case 1:
        return <i className="fa-solid fa-flag text-blue-600 fa-xl"></i>; // Mavi Bayrak
      default:
        return <i className="fa-solid fa-flag text-gray-600 fa-xl"></i>; // Varsayılan renk
    }
  }

  function getPriorityFlagClass(highPriority) {
    if (highPriority) {
      return <i className="fa-solid fa-check fa-xl text-green-600"></i>; // Kırmızı Bayrak
    } else {
      return null;
    }
  }
  const getEndDateStatus = (endDate, status) => {
    const today = new Date();
    const endTaskDate = new Date(endDate);
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1); // Yarın

    if (status === 1522) {
      return <p className="text-green-600 font-medium">Tamamlandı</p>;
    } else if (status === 72) {
      return <p className="text-red-600 font-medium">İptal Edildi</p>;
    } else if (status === 73) {
      return <p className="text-yellow-600 font-medium">Onay Bekliyor</p>;
    } else if (endTaskDate.toDateString() === tomorrow.toDateString()) {
      return <p className="text-yellow-500 font-medium">Süre Dolmak Üzere</p>;
    } else if (endTaskDate > today) {
      return <p className="text-blue-600 font-medium">Devam Ediyor</p>;
    } else {
      return <p className="text-red-600 font-medium">Süre Doldu</p>;
    }
  };

  return (
    <>
      {loading ? (
        <div style={{ 
          display: "grid",
          placeItems: "center",
          height: "100vh",
          width:"100vw"
        }}>
          <MoonLoader
            color={"blue"}
            loading={loading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
            speedMultiplier={0.5}
          />
        </div>
      ) : (
        <div className="w-full mt-12">
          <div className="add-button">
            <div className="flex justify-between">
              <input
                className="w-96 block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 "
                type="text"
                placeholder="Görev ara.."
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
              />
              <div className="flex gap-5">
                <label
                  className="block text-base font-medium text-gray-600"
                  htmlFor="isChecked"
                >
                  Biten ve iptal edilen görevleri göster
                </label>
                <Switch
                  id="isChecked"
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e)}
                />
              </div>
            </div>
          </div>

          <div
            style={{ height: "85vh"}}
            className="relative overflow-x-auto shadow-md sm:rounded-lg"
          >
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-100 top-0 sticky">
                <tr>
                  <th scope="col" className="px-6 py-3"></th>
                  <th scope="col" className="px-6 py-3">
                    Görev Başlığı
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Görev Adı
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Yüksek Öncelikli
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Başlangıç Tarih
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Bitiş Tarih
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Atayan
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Proje
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Durum
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Tamamlanma Durumu
                  </th>
                  <th scope="col" className="px-6 py-3">
                    İşlemler
                  </th>
                </tr>
              </thead>
              <tbody>
                {isChecked
                  ? matchedAllData
                      ?.filter((item) =>
                        item.name
                          ?.toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      )
                      .map((item, index) => {
                        const rowClass =
                          item.status === 1522 ? "bg-gray-50" : "bg-white";
                        const rowText =
                          item.status === 1522 || item.status === 72
                            ? "line-through"
                            : "";

                        return (
                          <tr
                            key={index}
                            className={`border-b hover:bg-gray-50 ${rowClass}`}
                          >
                            <td className="px-6 py-4">
                              {getPriorityFlag(item.priorityLevel)}
                            </td>
                            <th
                              scope="row"
                              className={`px-6 py-4 font-medium text-gray-900 whitespace-nowrap ${rowText}`}
                            >
                              {item.name}
                            </th>
                            <td className={`px-6 py-4 ${rowText}`}>
                              {item.title}
                            </td>
                            <td className="px-6 py-4 text-center">
                              {getPriorityFlagClass(item.highPriority)}
                            </td>
                            <td className="px-6 py-4">
                              {formatDate(item.startDate)}
                            </td>
                            <td className="px-6 py-4">
                              {formatDate(item.endDate)}
                            </td>
                            <td className="px-6 py-4">
                              {item.creatorUser.userName}
                            </td>
                            <td className="px-6 py-4">{item.projectName}</td>
                            <td className="px-6 py-4">
                              {getEndDateStatus(item.endDate, item.status)}
                            </td>
                            <td className="px-6 py-4">
                              <div className="w-full bg-gray-200 rounded-full h-2.5 ">
                                <div
                                  className="bg-blue-600 h-2.5 rounded-full"
                                  style={{ width: `${item.intensityPercent}%` }}
                                ></div>
                              </div>
                            </td>
                            <td className="flex items-center px-6 py-4 space-x-4">
                              <Link
                                to={`/taskDetailByAssignedUser/${item.taskID}`}
                              >
                                <a className="font-medium text-blue-600 hover:underline">
                                  <FaRegEye size={20} />
                                </a>
                              </Link>
                              <button
                                onClick={() => handleSubmit(item.taskID)}
                                type="button"
                              >
                                <a className="font-medium text-green-600 hover:underline">
                                  <FaRegCirclePlay size={18} />
                                </a>
                              </button>
                            </td>
                          </tr>
                        );
                      })
                  : matchedData
                      ?.filter((item) =>
                        item.name
                          ?.toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      )
                      .map((item, index) => {
                        const rowClass =
                          item.status === 1522 ? "bg-gray-50" : "bg-white";
                        const rowText =
                          item.status === 1522 ? "line-through" : "";

                        return (
                          <tr
                            key={index}
                            className={`border-b hover:bg-gray-50 ${rowClass}`}
                          >
                            <td className="px-6 py-4">
                              {getPriorityFlag(item.priorityLevel)}
                            </td>
                            <th
                              scope="row"
                              className={`px-6 py-4 font-medium text-gray-900 whitespace-nowrap ${rowText}`}
                            >
                              {item.name}
                            </th>
                            <td className={`px-6 py-4 ${rowText}`}>
                              {item.title}
                            </td>
                            <td className="px-6 py-4 text-center">
                              {getPriorityFlagClass(item.highPriority)}
                            </td>
                            <td className="px-6 py-4">
                              {formatDate(item.startDate)}
                            </td>
                            <td className="px-6 py-4">
                              {formatDate(item.endDate)}
                            </td>
                            <td className="px-6 py-4">
                              {item.creatorUser.userName}
                            </td>
                            <td className="px-6 py-4">{item.projectName}</td>
                            <td className="px-6 py-4">
                              {getEndDateStatus(item.endDate, item.status)}
                            </td>
                            <td className="px-6 py-4">
                              <div className="w-full bg-gray-200 rounded-full h-2.5 ">
                                <div
                                  className="bg-blue-600 h-2.5 rounded-full"
                                  style={{ width: `${item.intensityPercent}%` }}
                                ></div>
                              </div>
                            </td>
                            <td className="flex items-center px-3 py-4 space-x-4">
                              <Link
                                to={`/taskDetailByAssignedUser/${item.taskID}`}
                              >
                                <a className="font-medium text-blue-600 hover:underline">
                                  <FaRegEye size={20} />
                                </a>
                              </Link>
                              <button
                                onClick={() => handleSubmit(item.taskID)}
                                type="button"
                              >
                                <a className="font-medium text-green-600 hover:underline">
                                  <FaRegCirclePlay size={18} />
                                </a>
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  setOpenModule(true);
                                  setSelectedTaskID(item.taskID);
                                }}
                              >
                                <a className="font-medium text-yellow-600 hover:underline">
                                  <BiTransfer size={23} />
                                </a>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {openMdule === true && (
        <TaskTransferModal
          onCancel={() => setOpenModule(false)}
          taskID={selectedTaskID}
        />
      )}
    </>
  );
};

export default TasksByAssignedUser;
