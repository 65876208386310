import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/home'
import Login from './pages/login'
import Demands from './pages/demands'
import { useDispatch } from 'react-redux'
import DemandDetail from './pages/demands/DemandDetail'
import DemandAdd from './pages/demands/DemandAdd'
import DemandEdit from './pages/demands/DemandEdit'
import Invoices from './pages/invoices'
import InvoiceDetail from './pages/invoices/invoiceDetail'
import InvoiceAdd from './pages/invoices/invoiceAdd'
import InvoiceEdit from './pages/invoices/invoiceEdit'
import Offers from './pages/offers'
import OfferEdit from './pages/offers/offerEdit'
import OfferAdd from './pages/offers/OfferAdd'
import OfferDetail from './pages/offers/offerDetail'
import StockCards from './pages/stockCards'
import StockCardsAdd from './pages/stockCards/StockCardsAdd'
import StockCardsEdit from './pages/stockCards/StockCardsEdit'
import Accounts from './pages/accounts'
import AccountAdd from './pages/accounts/AccountAdd'
import AccountEdit from './pages/accounts/AccountPut'
import Orders from './pages/orders'
import OrderAdd from './pages/orders/OrderAdd'
import OrderDetail from './pages/orders/OrderDetail'
import OrderEdit from './pages/orders/OrderEdit'
import Deneme from './pages/deneme'
import Users from './pages/users'
import UserAdd from './pages/users/UserAdd'
import UserEdit from './pages/users/UserEdit'
import Roles from './pages/roles'
import RoleAdd from './pages/roles/RoleAdd'
import RoleIdentify from './pages/roles/RoleIdentify'
import WorkFlow from './pages/workflow'
import Error from './pages/Error'
import { getUser } from './redux/userDataSlice'
import WorkFlowList from './pages/workflow/WorkFlowList'
import WorkFlowEdit from './pages/workflow/WorkFlowEdit'
import WorkFlowAddForm from './pages/workflow/WorkFlowAddForm'
import Forms from './pages/form'
import FormBuilder from './pages/form/FormBuilder'
import FormBuilderSchema from './pages/form/FormBuilderSchema'
import FormBuilderSchemaEdit from './pages/form/FormBuilderSchemaEdit'
import FormBuilderSchemaDetail from './pages/form/FormBuilderSchemaDetail'
import Notifications from './pages/notifications'
import Tasks from './pages/tasks'
import UserProducts from './pages/users/UserProducts'
import StockCardsProducts from './pages/stockCards/StockCardsProduct'
import AccountProducts from './pages/accounts/AccountProducts'
import TaskAdd from './pages/tasks/TaskAdd'
import TasksByAssignedUserDetail from './pages/tasks/TasksByAssignedUserDetail'
import TasksByCreatorUserEdit from './pages/tasks/TasksByCreatorUserEdit'
import Projects from './pages/projects'
import ProjectAdd from './pages/projects/ProjectAdd'
import ProjectEdit from './pages/projects/ProjectEdit'
import ProjectDetail from './pages/projects/ProjectDetail'
import Meetings from './pages/meetings'
import MeetingAdd from './pages/meetings/MeetingAdd'
import MeetingEdit from './pages/meetings/MeetingEdit'
import MeetingCheck from './pages/meetings/MeetingCheck'
import Departments from './pages/departments'
import DepartmentAdd from './pages/departments/DepartmentAdd'
import DepartmentEdit from './pages/departments/DepartmentEdit'
import DepartmentDetail from './pages/departments/DepartmentDetail'
import MyMeetings from './pages/my-meetings'
import MeetingLogin from './pages/my-meetings/MeetingLogin'
import FormView from './pages/formview'
import Calendars from './pages/calendars'
import Settings from './pages/settings'
import OrganazationSchema from './pages/settings/OrganazationSchema'
import StockCardBOM from './pages/stockCardBOM'
import Machines from './pages/machines'
import MachineAdd from './pages/machines/MachineAdd'
import MachineEdit from './pages/machines/MachineEdit'
import Operations from './pages/operations'
import OperationAdd from './pages/operations/OperationAdd'
import OperationEdit from './pages/operations/OperationEdit'
import Personels from './pages/personels'
import PersonelAdd from './pages/personels/PersonelAdd'
import PersonelEdit from './pages/personels/PersonelEdit'
import PersonelDetail from './pages/personels/PersonelDetail'
import PersonelWageAdd from './pages/personels/PersonelWageAdd'
import PersonelWages from './pages/personels/PersonelWages'
import PersonelWageEdit from './pages/personels/PersonelWageEdit'
import PersonelShifts from './pages/shifts'
import PersonelShiftAdd from './pages/shifts/PersonelShiftAdd'
import GeneralShiftAdd from './pages/shifts/GeneralShiftAdd'
import MachineGroups from './pages/machines/MachineGroups'
import MachineGroupAdd from './pages/machines/MachineGroupAdd'
import MachineGroupEdit from './pages/machines/MachineGroupEdit'
import OperationGroups from './pages/operations/OperationGroups'
import OperationGroupAdd from './pages/operations/OperationGroupAdd'
import OperationGroupEdit from './pages/operations/OperationGroupEdit'
import PersonelGroups from './pages/personels/PersonelGroups'
import PersonelGroupAdd from './pages/personels/PersonelGroupAdd'
import PersonelGroupEdit from './pages/personels/PersonelGroupEdit'
import PersonelGroupDetail from './pages/personels/PersonelGroupDetail'
import ShiftDetails from './pages/shifts/ShiftDetails'
import WorkOrders from './pages/workOrders'
import WorkOrderAdd from './pages/workOrders/WorkOrderAdd'



const App = () => {
  const dispatch = useDispatch();


  useEffect(() => {
 dispatch(getUser())
  }, [])

  return (
    <BrowserRouter>
    <Routes>
      <Route path='/dashboards' element={<Home/>}/>
      <Route path='/' element={<Login/>}/>
      <Route path='/demands' element={<Demands/>}/>
      <Route path='/demandDetail/:id' element={<DemandDetail/>}/>
      <Route path='/demandAdd' element={<DemandAdd/>}/>
      <Route path='/demandEdit/:id' element={<DemandEdit/>}/>
      <Route path='/invoices' element={<Invoices/>}/>
      <Route path='/invoiceDetail/:id' element={<InvoiceDetail/>}/>
      <Route path='/invoiceAdd' element={<InvoiceAdd/>}/>
      <Route path='/invoiceEdit/:id' element={<InvoiceEdit/>}/>
      <Route path='/offers' element={<Offers/>}/>
      <Route path='/offerEdit/:id' element={<OfferEdit/>}/>
      <Route path='/offerAdd' element={<OfferAdd/>}/>
      <Route path='/offerDetail/:id' element={<OfferDetail/>}/>
      <Route path='/stockCards' element={<StockCards/>}/>
      <Route path='/stockCardsAdd' element={<StockCardsAdd/>}/>
      <Route path='/stockCardEdit/:id' element={<StockCardsEdit/>}/>
      <Route path='/stockCardsProducts/:id' element={<StockCardsProducts/>}/>
      <Route path='/accounts' element={<Accounts/>}/>
      <Route path='/accountAdd' element={<AccountAdd/>}/>
      <Route path='/accountEdit/:id' element={<AccountEdit/>}/>
      <Route path='/accountProducts/:id' element={<AccountProducts/>}/>
      <Route path='/orders' element={<Orders/>}/>
      <Route path='/orderAdd' element={<OrderAdd/>}/>
      <Route path='/orderDetail/:id' element={<OrderDetail/>}/>
      <Route path='/orderEdit/:id' element={<OrderEdit/>}/>
      <Route path='/users' element={<Users/>}/>
      <Route path='/userAdd' element={<UserAdd/>}/>
      <Route path='/userEdit/:id' element={<UserEdit/>}/>
      <Route path='/userProducts/:id' element={<UserProducts/>}/>
      <Route path='/roles' element={<Roles/>}/>
      <Route path='/roleAdd' element={<RoleAdd/>}/>
      <Route path='/roleIdentify/:id' element={<RoleIdentify/>}/>
      <Route path='/workFlowList' element={<WorkFlowList/>}/>
      <Route path='/workFlow' element={<WorkFlow/>}/>
      <Route path='/workFlowEdit/:id' element={<WorkFlowEdit/>}/>
      <Route path='/workFlowAddForm' element={<WorkFlowAddForm/>}/>
      <Route path='/formList' element={<Forms/>}/>
      <Route path='/formBuilder' element={<FormBuilder/>}/>
      <Route path='/formBuilderSchema' element={<FormBuilderSchema/>}/>
      <Route path='/formBuilderSchemaEdit/:id' element={<FormBuilderSchemaEdit/>}/>
      <Route path='/formBuilderSchemaDetail/:id' element={<FormBuilderSchemaDetail/>}/>
      <Route path='/notifications' element={<Notifications/>}/>
      <Route path='/tasks' element={<Tasks/>}/>
      <Route path='/taskAdd' element={<TaskAdd/>}/>
      <Route path='/taskDetailByAssignedUser/:id' element={<TasksByAssignedUserDetail/>}/>
      <Route path='/tasksByCreatorUserEdit/:id' element={<TasksByCreatorUserEdit/>}/>
      <Route path='/projects' element={<Projects/>}/>
      <Route path='/projectAdd' element={<ProjectAdd/>}/>
      <Route path='/projectEdit/:id' element={<ProjectEdit/>}/>
      <Route path='/projectDetail/:id' element={<ProjectDetail/>}/>
      <Route path='/meetings' element={<Meetings/>}/>
      <Route path='/meetingAdd' element={<MeetingAdd/>}/>
      <Route path='/meetingEdit/:id' element={<MeetingEdit/>}/>
      <Route path='/meetingCheck/:id' element={<MeetingCheck/>}/>
      <Route path='/my-meetings' element={<MyMeetings/>}/>
      <Route path='/meetingLogin/:id' element={<MeetingLogin/>}/>
      <Route path='/departments' element={<Departments/>}/>
      <Route path='/departmentAdd' element={<DepartmentAdd/>}/>
      <Route path='/departmentEdit/:id' element={<DepartmentEdit/>}/>
      <Route path='/departmentDetail/:id' element={<DepartmentDetail/>}/>
      <Route path='/formview/:id' element={<FormView/>}/>
      <Route path='/calendar' element={<Calendars/>}/>
      <Route path='/settings' element={<Settings/>}/>
      <Route path='/organizationSchema' element={<OrganazationSchema/>}/>
      <Route path='/stockCardBOM' element={<StockCardBOM/>}/>
      <Route path='/machines' element={<Machines/>}/>
      <Route path='/machineAdd' element={<MachineAdd/>}/>
      <Route path='/machineEdit/:id' element={<MachineEdit/>}/>
      <Route path='/machineGroups' element={<MachineGroups/>}/>
      <Route path='/machineGroupAdd' element={<MachineGroupAdd/>}/>
      <Route path='/machineGroupEdit/:id' element={<MachineGroupEdit/>}/>
      <Route path='/operations' element={<Operations/>}/>
      <Route path='/operationAdd' element={<OperationAdd/>}/>
      <Route path='/operationEdit/:id' element={<OperationEdit/>}/>
      <Route path='/operationGroups' element={<OperationGroups/>}/>
      <Route path='/operationGroupAdd' element={<OperationGroupAdd/>}/>
      <Route path='/operationGroupEdit/:id' element={<OperationGroupEdit/>}/>
      <Route path='/personels' element={<Personels/>}/>
      <Route path='/personelAdd' element={<PersonelAdd/>}/>
      <Route path='/personelEdit/:id' element={<PersonelEdit/>}/>
      <Route path='/personelDetail/:id' element={<PersonelDetail/>}/>
      <Route path='/personelWageAdd/:id' element={<PersonelWageAdd/>}/>
      <Route path='/personelWageEdit/:id' element={<PersonelWageEdit/>}/>
      <Route path='/personelWages/:id' element={<PersonelWages/>}/>
      <Route path='/personelGroups' element={<PersonelGroups/>}/>
      <Route path='/personelGroupAdd' element={<PersonelGroupAdd/>}/>
      <Route path='/personelGroupEdit/:id' element={<PersonelGroupEdit/>}/>
      <Route path='/personelGroupDetail/:id' element={<PersonelGroupDetail/>}/>
      <Route path='/personelShifts' element={<PersonelShifts/>}/>
      <Route path='/personelShiftAdd' element={<PersonelShiftAdd/>}/>
      <Route path='/generalShiftAdd' element={<GeneralShiftAdd/>}/>
      <Route path='/shiftDetails/:id' element={<ShiftDetails/>}/>
      <Route path='/workOrders' element={<WorkOrders/>}/>
      <Route path='/workOrderAdd' element={<WorkOrderAdd/>}/>
      <Route path='/deneme' element={<Deneme/>}/>
      <Route path='*' element={<Error/>}/>
      
      
    </Routes>
    </BrowserRouter>
  )
}

export default App